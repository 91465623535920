<template>
   <div>
        <a-form-item label="Number" v-bind="filCommon">
            <a-input-number
                :disabled="!canEdit"
                style="width: 100%"
                :min="1"
                :max="2147483647"
                v-decorator="[
                    'number'
                ]"
                placeholder="SoW Number">
            </a-input-number>
        </a-form-item>
        <a-form-item label="Name" v-bind="filCommon">
            <a-input
                :disabled="!canEdit"
                v-decorator="[
                    'name',
                    {
                        rules: [{ required: true, message: 'SoW must have name' }]
                    }
                ]"
                placeholder="SoW Name">
            </a-input>
        </a-form-item>
        <a-form-item label="Description" v-bind="filCommon">
            <a-textarea
                :disabled="!canEdit"
                v-decorator="[
                    'explanation'
                ]"
                placeholder="Description">
            </a-textarea>
        </a-form-item>
        <a-form-item class="faux-label" v-bind="filCommon" label=" ">
            <a-button
                v-if="canEdit"
                type="primary"
                html-type="submit"
                v-on:click="$emit(submit, $event)"
                class="lateral-button">Save</a-button>
            <router-link v-bind:to="'/sow/index/'">
                <a-button type="primary" class="lateral-button">{{ canEdit ? 'Cancel' : 'Back to Index' }}</a-button>
            </router-link>
            <router-link v-if="isCreateForm!==true" v-bind:to="'/sow/log/'+id">
                <a-button type="primary" class="lateral-button">Audit Log</a-button>
            </router-link>
        </a-form-item>
   </div>
</template>

<script>
import _extends from 'babel-runtime/helpers/extends';
import { formLayout } from "@/components/common";
import { authService } from '@/services/auth';

export default {
    props: {
        id: {
            default: null
        },
        submit: {
            default: "handle-submit"
        },
        isCreateForm: {
            default: true
        },
        theForm: {
            default: null
        },
    },
    data() {
        return _extends({
        }, formLayout);
    },
    methods: {
    },
    computed: {
        canEdit () {
            return authService.canEdit();
        }
    }
};
</script>