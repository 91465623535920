var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { attrs: { id: "components-layout-demo-custom-trigger" } },
    [
      _c(
        "a-layout-sider",
        {
          attrs: { trigger: null, collapsible: "" },
          model: {
            value: _vm.collapsed,
            callback: function($$v) {
              _vm.collapsed = $$v
            },
            expression: "collapsed"
          }
        },
        [
          _c("div", { staticClass: "logo", attrs: { id: "nav-logo" } }),
          _c(
            "div",
            [
              _c("a-icon", {
                staticClass: "trigger",
                staticStyle: {
                  "font-size": "20px !important",
                  width: "100%",
                  "text-align": "center",
                  "vertical-align": "middle",
                  color: "#fff"
                },
                attrs: { type: _vm.collapsed ? "menu-unfold" : "menu-fold" },
                on: {
                  click: function() {
                    return (_vm.collapsed = !_vm.collapsed)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-menu",
            {
              attrs: {
                theme: "dark",
                mode: "inline",
                defaultSelectedKeys: ["1"]
              }
            },
            [
              _c(
                "a-menu-item",
                { key: "1" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      _c("a-icon", { attrs: { type: "home" } }),
                      _c("span", [_vm._v("Home")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-sub-menu",
                [
                  _c(
                    "span",
                    { attrs: { slot: "title" }, slot: "title" },
                    [
                      _c("a-icon", { attrs: { type: "database" } }),
                      _c("span", [_vm._v("Master Data")])
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "2" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/region/index" } },
                        [
                          _c("a-icon", { attrs: { type: "table" } }),
                          _c("span", [_vm._v("Region")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "4" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/site/index" } },
                        [
                          _c("a-icon", { attrs: { type: "table" } }),
                          _c("span", [_vm._v("Site")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "6" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/enm/index" } },
                        [
                          _c("a-icon", { attrs: { type: "table" } }),
                          _c("span", [_vm._v("ENM")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "7" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/sow/index" } },
                        [
                          _c("a-icon", { attrs: { type: "table" } }),
                          _c("span", [_vm._v("SoW Map")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "8" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/activity/index" } },
                        [
                          _c("a-icon", { attrs: { type: "table" } }),
                          _c("span", [_vm._v("Activity")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-sub-menu",
                [
                  _c(
                    "span",
                    { attrs: { slot: "title" }, slot: "title" },
                    [
                      _c("a-icon", { attrs: { type: "file-sync" } }),
                      _c("span", [_vm._v("Provisioning")])
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/provisioning/nodin" } },
                        [
                          _c("a-icon", { attrs: { type: "table" } }),
                          _c("span", [_vm._v("Pending Nodin")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/provisioning/crq" } },
                        [
                          _c("a-icon", { attrs: { type: "table" } }),
                          _c("span", [_vm._v("Pending CRQ")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/provisioning/enm" } },
                        [
                          _c("a-icon", { attrs: { type: "table" } }),
                          _c("span", [_vm._v("Pending ENM")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-sub-menu",
                [
                  _c(
                    "span",
                    { attrs: { slot: "title" }, slot: "title" },
                    [
                      _c("a-icon", { attrs: { type: "desktop" } }),
                      _c("span", [_vm._v("Dashboard")])
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/dashboard/inventory" } },
                        [
                          _c("a-icon", { attrs: { type: "line-chart" } }),
                          _c("span", [_vm._v("Inventory")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/dashboard/license" } },
                        [
                          _c("a-icon", { attrs: { type: "pie-chart" } }),
                          _c("span", [_vm._v("License")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/dashboard/power" } },
                        [
                          _c("a-icon", { attrs: { type: "thunderbolt" } }),
                          _c("span", [_vm._v("Power")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/dashboard/serial_number" } },
                        [
                          _c("a-icon", { attrs: { type: "table" } }),
                          _c("span", [_vm._v("Serial Number")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.authInfo && _vm.authInfo.role >= 5
                ? _c(
                    "a-menu-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/console/pgw" } },
                        [
                          _c("a-icon", { attrs: { type: "rocket" } }),
                          _c("span", [_vm._v("PGW Console")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.authInfo && _vm.authInfo.role === 15
                ? _c(
                    "a-sub-menu",
                    [
                      _c(
                        "span",
                        { attrs: { slot: "title" }, slot: "title" },
                        [
                          _c("a-icon", { attrs: { type: "lock" } }),
                          _c("span", [_vm._v("Administration")])
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/user/index" } },
                            [
                              _c("a-icon", { attrs: { type: "user" } }),
                              _c("span", [_vm._v("Users")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-layout",
        [
          _c(
            "a-layout-header",
            { staticStyle: { background: "#fff", padding: "0" } },
            [
              _c("a-col", { attrs: { span: 12 } }, [_c("breadcrumbs")], 1),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/logout" } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "logout",
                            id: "logout"
                          }
                        },
                        [
                          _c("span", { staticStyle: { "font-size": ".9em" } }, [
                            _vm._v("Logout")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        float: "right",
                        "margin-right": "10px",
                        "font-size": ".9em",
                        cursor: "help"
                      },
                      attrs: {
                        title:
                          "Role " +
                          (_vm.authInfo && _vm.authInfo.rolename
                            ? _vm.authInfo.rolename
                            : "")
                      }
                    },
                    [
                      _vm._v("\n                    Logged in as "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.authInfo && _vm.authInfo.fullname
                              ? _vm.authInfo.fullname
                              : ""
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-layout-content", [
            _c(
              "div",
              {
                style: {
                  margin: "24px 16px",
                  padding: "24px",
                  background: "#fff",
                  minHeight: "280px"
                }
              },
              [_c("router-view")],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }