<template>
    <div>
        <a-button type="primary" v-if="canCreate" class="lateral-button"
            @click="$router.push('/activity/Create')">Create</a-button>
        <a-button type="primary" class="lateral-button">
            <router-link v-bind:to="'/activity/deletion'">View Deletion Logs</router-link>
        </a-button>
        <div style="margin-bottom: 10px;"></div>
        <Index ref="index" v-bind="{ title, columns, actions }" @delete-record="deleteRecord"/>
    </div>
</template>

<script>

import Index from "@/components/Index";
import { authService } from '@/services/auth';

const columns = [
    {
        title: "Number",
        dataIndex: "number",
        sorter: true,
        width: "5%"
    },
    {
        title: "Name",
        dataIndex: "name",
        sorter: true,
        width: "25%"
    },
    {
        title: "Type",
        dataIndex: "jenis",
        sorter: true,
        width: "10%"
    },
    {
        title: "SoW",
        dataIndex: "sow_name",
        sorter: true,
        width: "20%"
    },
    {
        title: "Description",
        dataIndex: "keterangan",
        sorter: true,
        width: "25%"
    },
    {
        title: "Action",
        key: "action",
        width: "15%",
        scopedSlots: { customRender: "action" }
    }
];

const actions = [
    {
        text: "Open",
        route: "/activity/detail/", // we dont need route here, changing page will be done by edit method, which will be called by emit mechanism
        //this value is consistent with the value on template v-on: of Index component
        type: "default",
        size: "small",
        show: () => true
    },
    {
        text: "Delete",
        //route: "/enm/detail/"    we dont need route here, changing page will be done by edit method, which will be called by emit mechanism
        emit: "delete-record", //this value is consistent with the value on template v-on: of Index component
        type: "danger",
        size: "small",
        show: () => authService.canDelete()
    }
];

export default {
    components: {
        Index
    },
    computed: {
        canCreate () {
            return authService.canCreate();
        }
    },
    data() {
        return {
            apiPath: "data/activity",
            title: "Activity",
            columns,
            actions
        };
    },
    mounted() {
        this.$eventbus.$emit("index-fetch", this.apiPath);
        this.$store.subscribe(mutation => {
            // listen to delete, refresh table when the deletion is done
            if (mutation != null 
                && mutation.type == "alert/success" 
                && mutation.payload == "Data successfully deleted") {
                    this.$eventbus.$emit("index-fetch", "data/activity");
            }
        }); 
    },
    methods: {
        deleteRecord(record) {
            this.$confirm({
                iconType: 'warning',
                title: 'Warning',
                content: 'Are you sure to delete this activity?',
                onOk: () => this.doDelete(record.id),
                destroyOnClose: true
            });
        },
        doDelete(which) {
            this.$store.dispatch("data/delete", { path: this.apiPath, id: which })
                .then( () => {
                    this.$eventbus.$emit("index-fetch", this.apiPath);
                });
        }
    }
};
</script>