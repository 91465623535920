<template>
    <div>
        <a-form-item label="SoW Name" v-bind="filCommon">
            <a-select
                placeholder="SoW Name"
                showSearch
                :defaultActiveFirstOption="false"
                :optionFilterProp="'title'"
                :notFoundContent="null"
                :options="sowOpts"
                :disabled="!canEdit || ($route.params.sowid !== undefined && $route.params.sowid !== null)"
                v-decorator="[
                    'sow',
                    {
                        rules: [{ required: true, message: 'Activity must have name' }]
                    }
                ]">
            >
            </a-select>
        </a-form-item>
        <a-form-item label="Sequence No." v-bind="filCommon">
            <a-input-number
                :disabled="!canEdit"
                style="width: 100%"
                :min="1"
                :max="2147483647"
                v-decorator="[
                    'number'
                ]"
                placeholder="Activity Number">
            </a-input-number>
        </a-form-item>
        <a-form-item label="Name" v-bind="filCommon">
            <a-input
                :disabled="!canEdit"
                v-decorator="[
                    'name',
                    {
                        rules: [{ required: true, message: 'Activity must have name' }]
                    }
                ]"
                placeholder="Activity Name">
            </a-input>
        </a-form-item>
        <a-form-item label="Type" v-bind="filCommon"> 
            <a-select 
                :disabled="!canEdit"
                v-decorator="[
                    'jenis'
                ]"
                placeholder="Type">
                <a-select-option v-for="act in actList" :key="act.id">{{act.name}}</a-select-option>
            </a-select> 
        </a-form-item>
        <a-form-item label="Description" v-bind="filCommon">
            <a-textarea
                :disabled="!canEdit"
                v-decorator="[
                    'keterangan'
                ]"
                placeholder="Description">
            </a-textarea>
        </a-form-item>
        <a-form-item label="Enabled" v-bind="filCommon">
            <a-checkbox 
                :disabled="!canEdit"
                :checked="enabled" @change="toggleState" />
        </a-form-item>
        <a-form-item class="faux-label" v-bind="filCommon" label=" ">
            <a-button
                v-if="canEdit"
                type="primary"
                html-type="submit"
                v-on:click="$emit(submit, $event)"
                class="lateral-button">Save</a-button>
            <a-button type="primary" class="lateral-button" @click="onCancel">{{ canEdit ? 'Cancel' : 'Back to Index' }}</a-button>
            <router-link v-if="isCreateForm!==true" v-bind:to="'/sow/detail/'+this.sowId+''">
                <a-button :disabled="!sowId||sowId==='_all'" type="primary" class="lateral-button">View SoW</a-button>
            </router-link>
            <router-link v-if="isCreateForm!==true" 
                v-bind:to="($route.params.sowid ? '/sow/' + $route.params.sowid + '/activity/log/' : '/activity/log/') + id">
                <a-button type="primary" class="lateral-button">Audit Log</a-button>
            </router-link>
        </a-form-item>
    </div>
</template>

<script>
import axios from 'axios';
import { authHeader } from '../../services/index';
import { config } from '@/config';
import { routerPath } from "@/router";
import _extends from 'babel-runtime/helpers/extends';
import { formLayout } from "@/components/common";
import { authService } from '@/services/auth';

export default {
    props: {
        id: {
            default: null
        },
        lockSow: {
            default: "lockSow"
        },
        submit: {
            default: "handle-submit"
        },
        isCreateForm: {
            default: true
        },
        theForm: {
            default: null
        },
        gotoIndex: {
            default: "gotoIndex"
        },
    },
    computed: {
        sowOpts () {
            if (!this.sowList) {
                return [];
            }
            return this.sowList.map(ea => {
                return {
                    value: ea.id,
                    label: ea.name,
                    key: ea.id,
                    title: ea.name,
                    disabled: false,
                };
            });
        },
        canEdit () {
            return authService.canEdit();
        }
    },
    mounted() {
        axios.get(`${config.backendUrl}/data/sow?limit=100&search=`, {
                headers: authHeader()
            }).then(response => {
                this.sowList = response.data.data;
                this.sowList.unshift({ id: '_all', name: '(All SoW)' });
                this.$emit(this.lockSow);
            });
    },
    data() {
        return _extends({
            sowList:[],
            sowId: null,
            actList: [
                { id: "Script", name: "Script Upload" },
                { id: "Console", name: "Console" },
                { id: "Manual", name: "Manual" },
                { id: "Exec", name: "Command Execution" },
                { id: "Verify", name: "NE Verification" },
            ],
            enabled: false
        }, formLayout);
    },
    methods: {
        toggleState (e) {
            this.enabled = e.target.checked;
        },
        onCancel (e) {
            e.preventDefault();
            this.$emit(this.gotoIndex);
        }
    }
};
</script>