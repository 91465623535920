<template>
    <div>
        <a-row :gutter="8">
            <a-tabs defaultActiveKey="0" @change="tabCallback">
                <a-tab-pane v-for="enm in enmList" :key="enm.key" :tab="enm.title"></a-tab-pane>
            </a-tabs>
        </a-row>
        <a-row :gutter="8">
            <a-button
                type="primary"
                icon="download"
                @click="downloadData"
            >Download {{currentEnm.title}} Data</a-button>
        </a-row>
        <hr />
        <!-- {{this.nodeSource}} -->
        <a-row :gutter="8">
            <a-col :span="12">
                <h4>2G TRX</h4>
                <vue-c3 :handler="handler5" id="chart-container1"></vue-c3>
            </a-col>
            <a-col :span="12">
                <h4>3G Cell Carrier</h4>
                <vue-c3 :handler="handler6" id="chart-container2"></vue-c3>
            </a-col>
        </a-row>
        <hr />
        <a-row :gutter="8">
            <a-col :span="12">
                <h4>4G 5MHzSC</h4>
                <vue-c3 :handler="handler7" id="chart-container1"></vue-c3>
            </a-col>
        </a-row>
        <!--
        <a-row :gutter="8">
            <a-col :span="12">
                <h4>License Features</h4>
                <vue-c3 :handler="handler" id="chart-container1"></vue-c3>
            </a-col>
            <a-col :span="12">
                <h4>License States</h4>
                <vue-c3 :handler="handler2" id="chart-container2"></vue-c3>
            </a-col>
        </a-row>
        <a-row :gutter="8">
            <a-col :span="12">
                <h4>License Services</h4>
                <vue-c3 :handler="handler3" id="chart-container3"></vue-c3>
            </a-col>
            <a-col :span="12">
                <h4>Combinations</h4>
                <vue-c3 :handler="handler4" id="chart-container4"></vue-c3>
            </a-col>
        </a-row>
        <div id="app" class="container mt-5">
            <h2 class="border-bottom pb-2 mb-4">
                Pivot
                <small>(drag & drop UI + PivotTable)</small>
            </h2>

            <div class="mb-5">
                <pivot
                    :data="data"
                    :fields="fields"
                    :row-fields="rowFields"
                    :col-fields="colFields"
                    :reducer="reducer"
                    :default-show-settings="defaultShowSettings"
                >
                    <template slot="value" slot-scope="{ value }">{{ value.toLocaleString() }}</template>
                </pivot>
            </div>
        </div>
        -->
    </div>
</template>
 
<script>
import Vue from "vue";
import VueC3 from "vue-c3";
// import Pivot from "@marketconnect/vue-pivot-table";
import "../../assets/css/c3.min.css";
import "../../assets/css/bootstrap.min.css";
import axios from "axios";
import { config } from "@/config";
import { authHeader } from "@/services/index";
import contentDisposition from "content-disposition";
// import d3 from 'd3'

const data = [];
export default {
    name: "Dashboard",
    components: {
        VueC3
        //  Pivot
    },
    data() {
        return {
            enmList: [],
            currentEnm: {},
            handler: new Vue(),
            handler2: new Vue(),
            handler3: new Vue(),
            handler4: new Vue(),
            handler5: new Vue(),
            handler6: new Vue(),
            handler7: new Vue(),
            width: 100,
            nodeSource: []
            // data: data,
            // asyncData: [],
            // fields: [],
            // rowFields: [
            //     {
            //         getter: item => item.featCtrlCpc,
            //         label: "featCtrlCpc"
            //     },
            //     {
            //         getter: item => item.bsctPnTransStatus,
            //         label: "bsctPnTransStatus"
            //     },
            //     {
            //         getter: item => item.NodeId,
            //         label: "NodeId"
            //     }
            // ],
            // colFields: [
            //     {
            //         getter: item => item.administrativeState,
            //         label: "administrativeState"
            //     },
            //     {
            //         getter: item => item.airRateTypeSelector,
            //         label: "airRateTypeSelector"
            //     },
            //     {
            //         getter: item => item.cpcCapability,
            //         label: "cpcCapability"
            //     },
            //     {
            //         getter: item => item.hsCoreId,
            //         label: "hsCoreId"
            //     }
            // ],
            // reducer: (sum, item) => sum + item.id.length - (item.id.length - 1),
            // defaultShowSettings: true,
            // isDataLoading: false
        };
    },
    created: function() {
        this.isDataLoading = true;
        setTimeout(() => {
            this.asyncData = data;
            this.isDataLoading = false;
        }, 3000);
    },
    methods: {
        initChart(handler, data1, data2) {
            const options = {
                data: {
                    columns: [data1, data2],
                    type: "spline"
                },
                color: {
                    pattern: [
                        "#2f54eb",
                        "#13c2c2",
                        "#722ed1",
                        "#eb2f96",
                        "#fa541c",
                        "#f5222d"
                    ]
                }
            };
            handler.$emit("init", options);
            handler.$emit("dispatch", options =>
                options.resize({ height: 250 })
            );
        },
        initChartSummary(handler, data1, data2) {
            const options = {
                data: {
                    columns: [data1]
                },
                axis: {
                    x: {
                        type: "category",
                        categories: data2
                    }
                },
                color: {
                    pattern: [
                        "#2f54eb",
                        "#13c2c2",
                        "#722ed1",
                        "#eb2f96",
                        "#fa541c",
                        "#f5222d"
                    ]
                }
            };
            handler.$emit("init", options);
            handler.$emit("dispatch", options =>
                options.resize({ height: 250 })
            );
        },
        initChartMultiple(handler, datas) {
            const options = {
                data: {
                    columns: datas,
                    type: "spline"
                },
                color: {
                    pattern: [
                        "#2f54eb",
                        "#13c2c2",
                        "#722ed1",
                        "#eb2f96",
                        "#fa541c",
                        "#f5222d"
                    ]
                }
            };
            handler.$emit("init", options);
            handler.$emit("dispatch", options =>
                options.resize({ height: 250 })
            );
        },
        downloadData() {
            var params = new FormData();
            params.append("enm", this.currentEnm.name);
            axios
                .post(
                    `${config.backendUrl}/data/licensesummary/downloadsummary`,
                    params,
                    {
                        responseType: "arraybuffer",
                        headers: authHeader()
                    }
                )
                .then(response => {
                    var ctype = response.headers["content-type"];
                    if (ctype === "application/octet-stream") {
                        var cdisp = contentDisposition.parse(
                            response.headers["content-disposition"]
                        );
                        saveAs(
                            new Blob([response.data], {
                                type: response.headers["content-type"]
                            }),
                            cdisp.parameters["filename"]
                        );
                    } else {
                        this.$store.dispatch(
                            "alert/error",
                            response.data.message
                        );
                    }
                });
        },
        tabCallback(key) {
            this.fetchEnmData(this.enmList[key]);
        },
        fetchEnmData(enmDetail) {
            this.currentEnm = enmDetail;
            var enmName = enmDetail.name;

            //get 2G TRX
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=Radio_Network_Base_Package_numberOf_TRX`,
                    {
                        headers: authHeader()
                    }
                )
                .then(cellResponse => {
                    var usage = ["TRX"];
                    var usageRecorded = [];
                    for (var j = 0; j < cellResponse.data.data.length; j++) {
                        usage.push(cellResponse.data.data[j].usage);
                        usageRecorded.push(
                            cellResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(this.handler5, usage, usageRecorded);
                });

            //get 3G CC
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=Radio_Network_Base_Package_numberOf_Cell_Carriers`,
                    {
                        headers: authHeader()
                    }
                )
                .then(trxResponse => {
                    var usage = ["CellCarrier"];
                    var usageRecorded = [];
                    for (var j = 0; j < trxResponse.data.data.length; j++) {
                        usage.push(trxResponse.data.data[j].usage);
                        usageRecorded.push(
                            trxResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(this.handler6, usage, usageRecorded);
                });

            //get 5MHzSC / 4G
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=Radio_Network_Base_Package_numberOf_5MHzSC`,
                    {
                        headers: authHeader()
                    }
                )
                .then(mhzResponse => {
                    var usage = ["5MHzSC"];
                    var usageRecorded = [];
                    for (var j = 0; j < mhzResponse.data.data.length; j++) {
                        usage.push(mhzResponse.data.data[j].usage);
                        usageRecorded.push(
                            mhzResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(this.handler7, usage, usageRecorded);
                });

            /*
            //get activation and deactivation data. Activation first
            axios
                .get(
                    `${config.backendUrl}/data/license?enm=${enmName}&state=ACTIVATED&field=featureState`,
                    {
                        headers: authHeader()
                    }
                )
                .then(actResponse => {
                    var activated = ["ACTIVATED"];
                    for (var i = 0; i < actResponse.data.data.length; i++) {
                        activated.push(actResponse.data.data[i].count);
                    }
                    //get deactivation only when activation is done for a consistent behavior
                    axios
                        .get(
                            `${config.backendUrl}/data/license?enm=${enmName}&state=DEACTIVATED&field=featureState`,
                            {
                                headers: authHeader()
                            }
                        )
                        .then(deactResponse => {
                            var deactivated = ["DEACTIVATED"];
                            for (
                                var i = 0;
                                i < deactResponse.data.data.length;
                                i++
                            ) {
                                deactivated.push(
                                    deactResponse.data.data[i].count
                                );
                            }
                            this.initChart(
                                this.handler,
                                activated,
                                deactivated
                            );
                        });
                });
            //get ENABLED and DISABLED data. ENABLED first
            axios
                .get(
                    `${config.backendUrl}/data/license?enm=${enmName}&state=ENABLED&field=licenseState`,
                    {
                        headers: authHeader()
                    }
                )
                .then(enResponse => {
                    var enabled = ["ENABLED"];
                    for (var i = 0; i < enResponse.data.data.length; i++) {
                        enabled.push(enResponse.data.data[i].count);
                    }
                    //get DISABLED only when ENABLED is done for a consistent behavior
                    axios
                        .get(
                            `${config.backendUrl}/data/license?enm=${enmName}&state=DISABLED&field=licenseState`,
                            {
                                headers: authHeader()
                            }
                        )
                        .then(disResponse => {
                            var disabled = ["DISABLED"];
                            for (
                                var i = 0;
                                i < disResponse.data.data.length;
                                i++
                            ) {
                                disabled.push(disResponse.data.data[i].count);
                            }
                            this.initChart(this.handler2, enabled, disabled);
                        });
                });
            //get OPERABLE and INOPERABLE data. OPERABLE first
            axios
                .get(
                    `${config.backendUrl}/data/license?enm=${enmName}&state=OPERABLE&field=serviceState`,
                    {
                        headers: authHeader()
                    }
                )
                .then(opResponse => {
                    var operable = ["OPERABLE"];
                    for (var i = 0; i < opResponse.data.data.length; i++) {
                        operable.push(opResponse.data.data[i].count);
                    }
                    //get INOPERABLE only when OPERABLE is done for a consistent behavior
                    axios
                        .get(
                            `${config.backendUrl}/data/license?enm=${enmName}&state=INOPERABLE&field=serviceState`,
                            {
                                headers: authHeader()
                            }
                        )
                        .then(inopResponse => {
                            var inoperable = ["INOPERABLE"];
                            for (
                                var i = 0;
                                i < inopResponse.data.data.length;
                                i++
                            ) {
                                inoperable.push(
                                    inopResponse.data.data[i].count
                                );
                            }
                            this.initChart(this.handler3, operable, inoperable);
                        });
                });

            axios
                .get(`${config.backendUrl}/data/license/activeenable?enm=${enmName}`, {
                    headers: authHeader()
                })
                .then(actenResponse => {
                    var combinationData = actenResponse.data.data;
                    var datas = [];
                    for (var i = 0; i < combinationData.length; i++) {
                        var eachComb = combinationData[i];
                        var eachData = datas.find(x => {
                            return x.name == eachComb.combination;
                        });
                        if (eachData == undefined) {
                            eachData = { name: eachComb.combination, list: [] };
                            datas.push(eachData);
                        }
                        eachData.list.push(eachComb.count);
                    }

                    var series = [];
                    for (var j = 0; j < datas.length; j++) {
                        var eachList = [datas[j].name, ...datas[j].list];
                        series.push(eachList);
                    }

                    this.initChartMultiple(this.handler4, series);
                });
            */

            // axios
            //     .get(`${config.backendUrl}/data/inventory`, {
            //         headers: authHeader()
            //     })
            //     .then(response => {
            //         this.nodeSource = response.data.data;
            //         this.data = this.nodeSource;
            //     });
        }
    },
    mounted() {
        axios
            .get(`${config.backendUrl}/data/enm/datadirs`, {
                headers: authHeader()
            })
            .then(response => {
                for (var j = 0; j < response.data.data.length; j++) {
                    this.enmList.push({
                        key: `${j}`,
                        name: response.data.data[j].enm,
                        title: response.data.data[j].enm.toUpperCase()
                    });
                }
                if (this.enmList.length > 0) {
                    this.fetchEnmData(this.enmList[0]);
                }
            });
    }
};
</script>
