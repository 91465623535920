import { axiosBackend } from './';
import { store } from '@/store';
import { config } from '@/config';

export const authService = {
  login,
  logout,
  register,
  isLoggedIn,
  getAuthInfo,
  canCreate,
  canEdit,
  canDelete
};

function login(username, password) {
  const data = new FormData();
  data.append('username', username);
  data.append('password', password);
  return axiosBackend.post(`login`, data)
    .then(response => {
      var authInfo = response;
      if (authInfo.token) {
        //sessionStorage.setItem(config.authInfo, JSON.stringify(authInfo));
        loggingIn(authInfo);
        // localStorage.setItem(config.authInfo, JSON.stringify(authInfo));
        store.dispatch('alert/success', 'Login success');
      } else if (authInfo.message) {
        store.dispatch('alert/error', authInfo.message);
      } else {
        store.dispatch('alert/error', 'Login error');
      }
      return authInfo;
    });
}

function logout (notif) {
  //let info = localStorage.getItem(config.authInfo);
  if (!isLoggedIn()) {
    //don't call api again, authInfo already gone
    return Promise.resolve();
  }
  let sessionId = store.getters['auth/sessionId'];
  // localStorage.removeItem(config.authInfo);
  // localStorage.clear(authService.storageName);
  loggingOut();
  let url = 'logout';
  if (sessionId) {
    url += '?' + config.sessionIdKey + '=' + sessionId
  } else {
    //TODO: unspecified sessionId will cause backend to keep the session (while the token is gone)
    //      (because at this context, backend knows neither the sessionId nor the userId).
    //      consider adding Authorization header when calling logout (this is unusual practice though)
  }
  return axiosBackend.get(url)
    .then(() => {
      if (notif && notif.message && notif.type) {
        store.dispatch('alert/' + notif.type, notif.message, { root: true });
      }
    });
}

function register(data) {
  const options = {
    method: 'POST',
    headers: { 'Authorization': 'Bearer ' +  getAuthInfo().token },
    data: data,
    url: `register`,
  };
  return axiosBackend(options);
}

function loggingIn(authInfo) { 
    localStorage.setItem(config.authInfo, JSON.stringify(authInfo));
}

function loggingOut() {
    localStorage.removeItem(config.authInfo);
}

function isLoggedIn() {
    let info = localStorage.getItem(config.authInfo);
    return info != null;
}

function getAuthInfo() {
    try {
      // return JSON.parse(sessionStorage.getItem(config.authInfo));
      return JSON.parse(localStorage.getItem(config.authInfo));
    } catch (e) {
      return null;
    }
}

function canCreate() {
  let info = getAuthInfo();
  return info && info['user'] && info.user['role'] >= 7;
}

function canEdit() {
  let info = getAuthInfo();
  return info && info['user'] && info.user['role'] >= 7;
}

function canDelete() {
  let info = getAuthInfo();
  return info && info['user'] && info.user['role'] >= 7;
}
