<template>
    <div>
        <a-button type="primary" v-if="canCheck" @click="handleNeisaBulk">Check All NEISA</a-button>
        <div style="margin-bottom: 10px;"></div>
        <Index
            :title="title"
            :columns="columns"
            :actions="actions"
            @handle-process="handleProcess"
            @handle-neisa-single="handleNeisaSingle"
        />
        <a-modal :title="modalTitle" v-model="visible" @ok="handleOk">
            <a-form :form="form" @submit="handleOk">
                <a-form-item label="CRQ">
                    <a-input
                        v-decorator="[
                  'crq',
                  { rules: [{ required: true, message: 'Please input crq' }] }
                ]"
                    ></a-input>
                </a-form-item>
                <a-form-item label="ENM username">
                    <a-input
                        v-decorator="[
                  'username',
                  { rules: [{ required: true, message: 'Please input enm username' }] }
                ]"
                    ></a-input>
                </a-form-item>
                <a-form-item label="ENM password">
                    <a-input
                        v-decorator="[
                  'password'
                ]"
                        type="password"
                        placeholder="password"
                    ></a-input>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import axios from "axios";
import { config } from "@/config";
import { authHeader } from "@/services/index";
import Index from "@/components/Index";
import { authService } from '@/services/auth';

function canProcess() {
    //return authService.canEdit();
    let info = authService.getAuthInfo();
    return info && info['user'] && info.user['role'] >= 5;
}

function canCheck() {
    //return authService.canEdit();
    let info = authService.getAuthInfo();
    return info && info['user'] && info.user['role'] >= 5;
}

const columns = [
    {
        title: "SOW",
        dataIndex: "sowname",
        sorter: true,
        width: "15%"
    },
    {
        title: "NE ID",
        dataIndex: "ne",
        sorter: true,
        width: "15%"
    },
    {
        title: "Nodin",
        dataIndex: "nodin",
        sorter: true,
        width: "15%"
    }
];

if (canProcess() || canCheck()) {
    columns.push({
        title: "Action",
        key: "action",
        width: "15%",
        scopedSlots: { customRender: "action" }
    });
}

const actions = [
    {
        text: "Process CRQ",
        emit: "handle-process",
        type: "primary",
        size: "small",
        show: () => canProcess()
    },
    {
        text: "Check NEISA",
        emit: "handle-neisa-single",
        type: "default",
        size: "small",
        show: () => canCheck()
    }
];

export default {
    components: {
        Index
    },
    computed : {
        canCheck
    },
    data() {
        return {
            apiPath: "data/node",
            title: "Pending CRQ",
            filters: { state: 1 },
            columns,
            actions,
            recordId: "",
            form: this.$form.createForm(this),
            visible: false,
            modalTitle: "",
            apiNeisa: "remedy"
        };
    },
    mounted() {
        this.$eventbus.$emit("index-fetch", this.apiPath, this.filters);
    },
    methods: {
        handleProcess(record) {
            this.showModal(record);
        },
        showModal(record) {
            this.recordId = record.id;
            this.modalTitle = record.name;
            this.visible = true;
        },
        handleOk(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.visible = false;

                    const path = this.apiPath;
                    const id = this.recordId;

                    const data = new FormData();
                    data.append("state", 2);
                    data.append("crq", values.crq);
                    data.append("enm_username", values.username);
                    data.append("enm_password", values.password);

                    this.$store
                        .dispatch("data/update", { path, id, data })
                        .then(() => {
                            this.$store.dispatch(
                                "alert/success",
                                "Processing CRQ for " + id
                            );
                            this.$eventbus.$emit(
                                "index-fetch",
                                this.apiPath,
                                this.filters
                            );
                        });
                }
            });
        },
        handleNeisaSingle(record) {
            axios({
                baseURL: config.backendUrl,
                timeout: 30000,
                method: 'POST',
                headers: authHeader(),
                url: `${config.backendUrl}/${this.apiPath}/process/single/${this.apiNeisa}/${record.id}`
                })
                .then(response => {
                    if (response.data.success) {
                        this.$store.dispatch('alert/success', response.data.message);
                        // this.$info({
                        //     duration: config.notifDuration || 3,
                        //     title: 'Processing NEISA in background',
                        //     content: 'You will be notified when it is done'
                        // });
                    } else {
                        this.$store.dispatch('alert/error', response.data.message);
                        // this.$error({
                        //     duration: config.notifDuration || 3,
                        //     title: 'Cannot initiate NEISA processing',
                        //     content: response.data.message || 'Internal error'
                        // });
                    }
                });
        },
        handleNeisaBulk(e) {
            axios({
                baseURL: config.backendUrl,
                timeout: 30000,
                method: 'POST',
                headers: authHeader(),
                url: `${config.backendUrl}/${this.apiPath}/process/bulk/${this.apiNeisa}`
                })
                .then(response => {
                    if (response.data.success) {
                        //this.$store.dispatch('alert/success', response.data.message);
                        this.$info({
                            duration: config.notifDuration || 3,
                            title: 'Processing NEISA in background',
                            content: 'You will be notified when it is done'
                        });
                    } else {
                        //this.$store.dispatch('alert/error', response.data.message);
                        this.$error({
                            duration: config.notifDuration || 3,
                            title: 'Cannot initiate NEISA processing',
                            content: response.data.message || 'Internal error'
                        });
                    }
                });
        }
    }
};
</script>