var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: 8 } },
        [
          _c(
            "a-tabs",
            {
              attrs: { defaultActiveKey: "0" },
              on: { change: _vm.tabCallback }
            },
            _vm._l(_vm.enmList, function(enm) {
              return _c("a-tab-pane", {
                key: enm.key,
                attrs: { tab: enm.title }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 8 } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("h4", [_vm._v("2G MO + TRX")]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "download" },
                  on: {
                    click: function($event) {
                      return _vm.downloadData(_vm.licenses.lic2gMo)
                    }
                  }
                },
                [_vm._v("Download " + _vm._s(_vm.currentEnm.title) + " Data")]
              ),
              _c("p"),
              _c("vue-c3", {
                attrs: { handler: _vm.handler2gMo, id: "chart-container-2g-mo" }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("h4", [_vm._v("2G MPWR + Power")]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "download" },
                  on: {
                    click: function($event) {
                      return _vm.downloadData(_vm.licenses.lic2gMpwr)
                    }
                  }
                },
                [_vm._v("Download " + _vm._s(_vm.currentEnm.title) + " Data")]
              ),
              _c("p"),
              _c("vue-c3", {
                attrs: {
                  handler: _vm.handler2gMpwr,
                  id: "chart-container-2g-mpwr"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("hr"),
      _c(
        "a-row",
        { attrs: { gutter: 8 } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("h4", [_vm._v("3G Cell Carrier")]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "download" },
                  on: {
                    click: function($event) {
                      return _vm.downloadData(_vm.licenses.lic3gCc)
                    }
                  }
                },
                [_vm._v("Download " + _vm._s(_vm.currentEnm.title) + " Data")]
              ),
              _c("p"),
              _c("vue-c3", {
                attrs: { handler: _vm.handler3gCc, id: "chart-container-3g-cc" }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("h4", [_vm._v("3G Power (Watt)")]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "download" },
                  on: {
                    click: function($event) {
                      return _vm.downloadData(_vm.licenses.lic3gPwr)
                    }
                  }
                },
                [_vm._v("Download " + _vm._s(_vm.currentEnm.title) + " Data")]
              ),
              _c("p"),
              _c("vue-c3", {
                attrs: {
                  handler: _vm.handler3gPwr,
                  id: "chart-container-3g-pwr"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("hr"),
      _c(
        "a-row",
        { attrs: { gutter: 8 } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("h4", [_vm._v("4G FDD MO")]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "download" },
                  on: {
                    click: function($event) {
                      return _vm.downloadData(_vm.licenses.lic4gFddMo)
                    }
                  }
                },
                [_vm._v("Download " + _vm._s(_vm.currentEnm.title) + " Data")]
              ),
              _c("p"),
              _c("vue-c3", {
                attrs: {
                  handler: _vm.handler4gFddMo,
                  id: "chart-container-4g-fdd-mo"
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("h4", [_vm._v("4G TDD MO")]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "download" },
                  on: {
                    click: function($event) {
                      return _vm.downloadData(_vm.licenses.lic4gTddMo)
                    }
                  }
                },
                [_vm._v("Download " + _vm._s(_vm.currentEnm.title) + " Data")]
              ),
              _c("p"),
              _c("vue-c3", {
                attrs: {
                  handler: _vm.handler4gTddMo,
                  id: "chart-container-4g-tdd-mo"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("hr"),
      _c(
        "a-row",
        { attrs: { gutter: 8 } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("h4", [_vm._v("4G FDD BW (per 5Mhz)")]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "download" },
                  on: {
                    click: function($event) {
                      return _vm.downloadData(_vm.licenses.lic4gFddBw)
                    }
                  }
                },
                [_vm._v("Download " + _vm._s(_vm.currentEnm.title) + " Data")]
              ),
              _c("p"),
              _c("vue-c3", {
                attrs: {
                  handler: _vm.handler4gFddBw,
                  id: "chart-container-4g-fdd-bw"
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("h4", [_vm._v("4G TDD BW (per 5Mhz)")]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "download" },
                  on: {
                    click: function($event) {
                      return _vm.downloadData(_vm.licenses.lic4gTddBw)
                    }
                  }
                },
                [_vm._v("Download " + _vm._s(_vm.currentEnm.title) + " Data")]
              ),
              _c("p"),
              _c("vue-c3", {
                attrs: {
                  handler: _vm.handler4gTddBw,
                  id: "chart-container-4g-tdd-bw"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("hr"),
      _c(
        "a-row",
        { attrs: { gutter: 8 } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("h4", [_vm._v("4G FDD TDD Power (Watt)")]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "download" },
                  on: {
                    click: function($event) {
                      return _vm.downloadData(_vm.licenses.lic4gFddPwr)
                    }
                  }
                },
                [_vm._v("Download " + _vm._s(_vm.currentEnm.title) + " Data")]
              ),
              _c("p"),
              _c("vue-c3", {
                attrs: {
                  handler: _vm.handler4gFddPwr,
                  id: "chart-container-4g-fdd-pwr"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }