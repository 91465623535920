<template>
    <div></div>
</template>

<script>
export default {
    created() {
        this.$store.dispatch("auth/logout", {
            redirectToLogin: true,
            notification: {
                message: 'Logout success',
                type: 'success'
            }
        });
    }
}
</script>