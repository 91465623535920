var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "SoW Name" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "sow",
                    {
                      rules: [
                        { required: true, message: "Activity must have name" }
                      ]
                    }
                  ],
                  expression:
                    "[\n                'sow',\n                {\n                    rules: [{ required: true, message: 'Activity must have name' }]\n                }\n            ]"
                }
              ],
              attrs: {
                placeholder: "SoW Name",
                showSearch: "",
                defaultActiveFirstOption: false,
                optionFilterProp: "title",
                notFoundContent: null,
                options: _vm.sowOpts,
                disabled:
                  !_vm.canEdit ||
                  (_vm.$route.params.sowid !== undefined &&
                    _vm.$route.params.sowid !== null)
              }
            },
            [_vm._v("\n        >\n        ")]
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Sequence No." } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["number"],
                expression: "[\n                'number'\n            ]"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              disabled: !_vm.canEdit,
              min: 1,
              max: 2147483647,
              placeholder: "Activity Number"
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Name" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "name",
                  {
                    rules: [
                      { required: true, message: "Activity must have name" }
                    ]
                  }
                ],
                expression:
                  "[\n                'name',\n                {\n                    rules: [{ required: true, message: 'Activity must have name' }]\n                }\n            ]"
              }
            ],
            attrs: { disabled: !_vm.canEdit, placeholder: "Activity Name" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Type" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: ["jenis"],
                  expression: "[\n                'jenis'\n            ]"
                }
              ],
              attrs: { disabled: !_vm.canEdit, placeholder: "Type" }
            },
            _vm._l(_vm.actList, function(act) {
              return _c("a-select-option", { key: act.id }, [
                _vm._v(_vm._s(act.name))
              ])
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Description" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["keterangan"],
                expression: "[\n                'keterangan'\n            ]"
              }
            ],
            attrs: { disabled: !_vm.canEdit, placeholder: "Description" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Enabled" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-checkbox", {
            attrs: { disabled: !_vm.canEdit, checked: _vm.enabled },
            on: { change: _vm.toggleState }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { staticClass: "faux-label", attrs: { label: " " } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _vm.canEdit
            ? _c(
                "a-button",
                {
                  staticClass: "lateral-button",
                  attrs: { type: "primary", "html-type": "submit" },
                  on: {
                    click: function($event) {
                      return _vm.$emit(_vm.submit, $event)
                    }
                  }
                },
                [_vm._v("Save")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              staticClass: "lateral-button",
              attrs: { type: "primary" },
              on: { click: _vm.onCancel }
            },
            [_vm._v(_vm._s(_vm.canEdit ? "Cancel" : "Back to Index"))]
          ),
          _vm.isCreateForm !== true
            ? _c(
                "router-link",
                { attrs: { to: "/sow/detail/" + this.sowId + "" } },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "lateral-button",
                      attrs: {
                        disabled: !_vm.sowId || _vm.sowId === "_all",
                        type: "primary"
                      }
                    },
                    [_vm._v("View SoW")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isCreateForm !== true
            ? _c(
                "router-link",
                {
                  attrs: {
                    to:
                      (_vm.$route.params.sowid
                        ? "/sow/" + _vm.$route.params.sowid + "/activity/log/"
                        : "/activity/log/") + _vm.id
                  }
                },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "lateral-button",
                      attrs: { type: "primary" }
                    },
                    [_vm._v("Audit Log")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }