var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { attrs: { id: "login-page-layout" } },
    [
      _c(
        "a-layout-content",
        {
          style: {
            background: "#331f1f",
            background: "-webkit-linear-gradient(to top, #331f1f, #7a1f1f)",
            background: "linear-gradient(to top, #331f1f, #7a1f1f)"
          }
        },
        [
          _c(
            "a-row",
            {
              staticStyle: { height: "100vh" },
              attrs: { type: "flex", justify: "center", align: "middle" }
            },
            [
              _c(
                "a-col",
                { style: { minWidth: "320px" }, attrs: { span: 6 } },
                [
                  _c(
                    "a-card",
                    [
                      _c("img", {
                        attrs: {
                          slot: "cover",
                          src: require("../assets/logo.png"),
                          alt: "logo"
                        },
                        slot: "cover"
                      }),
                      _c(
                        "a-form",
                        {
                          staticClass: "login-form",
                          attrs: {
                            id: "components-form-demo-normal-login",
                            form: _vm.form
                          },
                          on: { submit: _vm.handleSubmit }
                        },
                        [
                          _c(
                            "a-form-item",
                            [
                              _c(
                                "a-input",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "username",
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                "Please input your username!"
                                            }
                                          ]
                                        }
                                      ],
                                      expression:
                                        "[\n                                'username',\n                                { rules: [{ required: true, message: 'Please input your username!' }] }]"
                                    }
                                  ],
                                  attrs: { placeholder: "Username" }
                                },
                                [
                                  _c("a-icon", {
                                    staticStyle: { color: "rgba(0,0,0,.25)" },
                                    attrs: { slot: "prefix", type: "user" },
                                    slot: "prefix"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            [
                              _c(
                                "a-input",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "password",
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                "Please input your Password!"
                                            }
                                          ]
                                        }
                                      ],
                                      expression:
                                        "[\n                                'password',\n                                { rules: [{ required: true, message: 'Please input your Password!' }] }]"
                                    }
                                  ],
                                  attrs: {
                                    type: "password",
                                    placeholder: "Password"
                                  }
                                },
                                [
                                  _c("a-icon", {
                                    staticStyle: { color: "rgba(0,0,0,.25)" },
                                    attrs: { slot: "prefix", type: "lock" },
                                    slot: "prefix"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            [
                              _c(
                                "a-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "remember",
                                        {
                                          valuePropName: "checked",
                                          initialValue: true
                                        }
                                      ],
                                      expression:
                                        "[\n                                'remember',\n                                {\n                                    valuePropName: 'checked',\n                                    initialValue: true,\n                                }]"
                                    }
                                  ]
                                },
                                [_vm._v("Remember me")]
                              ),
                              _c(
                                "a-button",
                                {
                                  staticClass: "login-form-button",
                                  attrs: {
                                    type: "primary",
                                    "html-type": "submit"
                                  }
                                },
                                [_vm._v("Log in")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }