<template>
    <div>
      {{this.chat}}
        <a-row>
            
                    <a-button
                        type="primary"
                        @click="handleSubmit()"
                        >Submit
                    </a-button>      
        </a-row>
    </div>
</template>

<script>
import axios from 'axios';
import { authHeader } from '../../services/index';
import { config } from '@/config';


function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}
export default {
    data() {
        return {
            hasErrors,
            form: this.$form.createForm(this),
            name: null,
            address: null,
            id: null,
            region_id:null,
            regionList:[],
            chat: "belum",
            valuewill:22,
            formItemLayout: {
                labelCol: {
                    xs: { span: 4 },
                    sm: { span: 4 },
                    md: { span: 4 }
                },
                wrapperCol: {
                    xs: { span: 12 },
                    sm: { span: 12 },
                    md: { span: 6 }
                }
            }
           
            
            // regionList2:[]          
        };
    },
    mounted() {
        this.$nextTick(() => {
            // To disabled submit button at the beginning.
            this.form.validateFields();
        });
        
        axios.get(`${config.backendUrl}/data/region?search=`, {
                        headers: authHeader()
                    })
                .then(response => {
                        this.regionList = response.data.data;
                    
                });
        this.form.setFieldsValue({port:this.valuewill})
  
    },
    methods: {
        onSelect(value) {
                this.region_id=value
                
            },
        // Only show error after a field is touched.
        nameError() {
            const { getFieldError, isFieldTouched } = this.form;
            return isFieldTouched("Name") && getFieldError("Name");
        },
        addressError() {
            const { getFieldError, isFieldTouched } = this.form;
            return isFieldTouched("Address") && getFieldError("Address");
        },
        handleSubmit() {
            axios.post(`${config.backendUrl}/data/ssh/download`, {
                        headers: authHeader()
                    })
                .then(response => {
                  this.chat = "udah"
                });
        }
    }
};
</script>