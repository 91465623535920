var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Index", {
    attrs: { title: _vm.title, columns: _vm.columns, actions: _vm.actions },
    on: { "process-enm": _vm.processEnm, "delete-record": _vm.deleteRecord }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }