var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: 8 } },
        [
          _c(
            "a-tabs",
            {
              attrs: { defaultActiveKey: "0" },
              on: { change: _vm.tabCallback }
            },
            _vm._l(_vm.enmList, function(enm) {
              return _c("a-tab-pane", {
                key: enm.key,
                attrs: { tab: enm.title }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", icon: "download" },
              on: { click: _vm.downloadData }
            },
            [_vm._v("Download " + _vm._s(_vm.currentEnm.title) + " Data")]
          )
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c(
            "Index",
            _vm._b(
              { ref: "index" },
              "Index",
              { title: _vm.title, columns: _vm.columns, actions: _vm.actions },
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }