import qs from 'qs';
import { axiosBackend, authHeader } from './';

export const dataService = {
    dataIndex,
    dataDetail,
    dataCreate,
    dataUpdate,
    dataDelete
};

function dataIndex(path, query) {
    const params = query ? '/?' + qs.stringify(query) : '';
    const options = {
        method: 'GET',
        headers: authHeader(),
        url: path + params,
    };
    return axiosBackend(options);
}

function dataDetail(path, id) {
    const options = {
        method: 'GET',
        headers: authHeader(),
        url: `${path}/id/${id}`,
    };
    return axiosBackend(options);
}

function dataCreate(path, data) {
    if(data instanceof FormData) {
        // Display the key/value pairs
        //for (var pair of data.entries()) {
        //    console.log(pair[0]+ ', ' + pair[1]); 
        //}
    }
    const options = {
        method: 'POST',
        headers: authHeader(),
        data: data,
        url: `${path}/create`,
    };
    return axiosBackend(options);
}

function dataUpdate(path, id, data) {
    const options = {
        method: 'PUT',
        headers: authHeader(),
        data: data,
        url: `${path}/id/${id}`,
    };
    return axiosBackend(options);
}

function dataDelete(path, id) {
    const options = {
        method: 'GET',
        headers: authHeader(),
        url: `${path}/delete/${id}`,
    };
    return axiosBackend(options);
}