var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading } },
    [
      _c(
        "a-card",
        { staticClass: "sow-card", attrs: { title: "Edit SOW" } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c("Form", {
                ref: "sowForm",
                attrs: {
                  id: _vm.id,
                  theForm: _vm.form,
                  isCreateForm: _vm.createForm
                },
                on: { "handle-submit": _vm.handleSubmit }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card",
        {
          staticClass: "sow-card",
          staticStyle: { "margin-top": "20px" },
          attrs: { title: "Activities" }
        },
        [
          _vm.canCreate
            ? _c(
                "a-button",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push(
                        "/sow/" + _vm.id + "/createactivity"
                      )
                    }
                  }
                },
                [_vm._v("Create")]
              )
            : _vm._e(),
          _c(
            "Index",
            _vm._b(
              { ref: "actTable", on: { "delete-record": _vm.deleteActivity } },
              "Index",
              {
                title: null,
                columns: _vm.actColumns,
                actions: _vm.actActions,
                searchable: false,
                queryParams: false
              },
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }