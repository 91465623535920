const axios = require('axios');
import { config } from '@/config';
import { store } from '@/store';
import { authService } from  './auth';

export const axiosBackend = axios.create({
  baseURL: config.backendUrl,
  timeout: 30000
});

export function authHeader() {
  const authInfo = authService.getAuthInfo();
  // const authInfo = JSON.parse(localStorage.getItem(config.authInfo));

  if (authInfo && authInfo.token) {
    return { 'Authorization': 'Bearer ' + authInfo.token };
  } else {
    return {};
  }
}

axiosBackend.interceptors.response.use(
  response => Promise.resolve(response.data),
  error => {
    const response = error.response;
    if (response) {
      if (response.status === 401) {
        let params = { 
          redirectToLogin: true,
          page: {
            path: location && location['pathname'] || '/',
            query: location && location['search'] || ''
          },
          notification: {
            message: 'Invalid or expired login', 
            type: 'error'
          }
        };
        store.dispatch('auth/logout', params);
      }

      const message = (response.data && response.data.message) || `${response.status} (${response.statusText})`;
      return Promise.reject(message);
    } else {
      return Promise.reject();
    }
  }
);