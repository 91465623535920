<template>
    <div>
        <a-button type="primary" v-if="canCreate" class="lateral-button"
            @click="$router.push('/sow/Create')">Create</a-button>
        <a-button type="primary" class="lateral-button">
            <router-link v-bind:to="'/sow/deletion'">View Deletion Logs</router-link>
        </a-button>
        <div style="margin-bottom: 10px;"></div>
        <Index :title="title" :columns="columns" :actions="actions" @delete-record="deleteRecord" @edit-record="edit"/>
    </div>
</template>

<script>
import Index from "@/components/Index";
import { config } from "@/config";
import { authHeader } from "../../services/index";
import axios from "axios";
import { authService } from '@/services/auth';

const columns = [
  {
    title: "Number",
    dataIndex: "number",
    sorter: true,
    width: "5%"
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
    width: "25%"
  },
  {
    title: "Keterangan",
    dataIndex: "explanation",
    sorter: true,
    width: "55%"
  },
  {
    title: "Action",
    key: "action",
    width: "15%",
    scopedSlots: { customRender: "action" }
  }
];

const actions = [
    {
        text: "Open",
        //route: "/sow/detail/",
        emit: "edit-record",
        type: "default",
        size: "small",
        show: () => true
    },
    {
        text: "Delete",
        emit: "delete-record",
        type: "danger",
        size: "small",
        show: () => authService.canDelete()
    }
];


export default {
    components: {
        Index
    },
    computed: {
        canCreate () {
            return authService.canCreate();
        }
    },
    data() {
        return {
            apiPath: "data/sow",
            title: "SoW Map",
            columns,
            actions,
            activityList : null,
            activitySource : null
        };
    },
    mounted() {
        this.$eventbus.$emit("index-fetch", "data/sow");
        
        this.$store.subscribe((mutation) => {
            // listen to delete, refresh table when the deletion is done
            if(mutation != null && mutation.type == "alert/success" && mutation.payload == "Data successfully deleted") {
            
            this.$eventbus.$emit("index-fetch", "data/sow");
        }
        });
    },
    methods:{
        edit(record) {
            this.$root.$data.dataSowRecord = record;
            this.$router.push( { name: 'sowDetail', params: { id: record.id }} );
        },
        deleteRecord(record) {
            this.$confirm({
                iconType: 'warning',
                title: 'Warning',
                content: 'Are you sure to delete this SoW and all of its activities?',
                onOk: () => this.doDelete(record.id),
                destroyOnClose: true
            });
        },
        doDelete(which) {
            this.$store.dispatch("data/delete", { path: this.apiPath, id: which })
                .then( () => {
                    this.$eventbus.$emit("index-fetch", this.apiPath);
                });
        }
    }
};
</script>