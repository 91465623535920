<template>
  <div>
    <Fullscreen id="console-container" ref="consoleContainer">
      <a-menu id="console-menu"
          mode="horizontal"
          :selectable="false"
          :getPopupContainer="getConsoleContainer"
          @click="handleConsoleMenu">
          <a-sub-menu>
            <span slot="title">Connection</span>
            <a-menu-item key="console-connect" :disabled="isConnected">Open</a-menu-item>
            <a-menu-item key="console-disconnect" :disabled="!isConnected">Close</a-menu-item>
          </a-sub-menu>
          <a-menu-item key="console-upload" :disabled="!isConnected">Upload</a-menu-item>
          <a-sub-menu :disabled="!isConnected">
            <span slot="title">Download</span>
            <a-menu-item key="console-dl-init">Initialize</a-menu-item>
            <a-menu-item key="console-dl-get" :disabled="!canDownload">Get the File</a-menu-item>
          </a-sub-menu>
          <a-menu-item key="console-fs">Toggle Fullscreen</a-menu-item>
          <a-menu-item key="console-help">Help</a-menu-item>
      </a-menu>
      <div class="term-box">
        <div id="term-header"></div>
        <div id="term-container" class="terminal"></div>
        <div id="term-bottomdiv">
          <!--
          <div class="term-dropup" id="term-menu">
            <i class="fas fa-bars fa-fw"></i> Menu
            <div id="term-dropup-content" class="term-dropup-content"></div>
          </div>
          -->
          <div id="term-footer"></div>
          <div id="term-status"></div>
        </div>
      </div>
      <a-modal v-model="ulModalShown" :getContainer="getConsoleContainer" title="Upload" @ok="initUpload" @cancel="cancelUpload">
        <div id="ul-form">
          <a-form :form="ulForm">
            <a-form-item v-bind="filNoLabelInModal">
              <a-upload
                v-decorator="[ 'upload' ]"
                :fileList="uploads" 
                :beforeUpload="handleConsoleUpload" 
                @change="consoleUploadChanged">
                <a-button>
                  <a-icon type="upload" />Select Files...
                </a-button>
              </a-upload>
            </a-form-item>
          </a-form>
        </div>
      </a-modal>
      <a-modal v-model="dlModalShown" :getContainer="getConsoleContainer" title="Download" @ok="initDownload" @cancel="cancelDownload">
        <div id="dl-form">
          <a-form :form="dlForm">
            <a-form-item label="Remote Path" v-bind="filNoLabelInModal">
              <a-input
                v-decorator="[
                  'path',
                  { rules: [{ required: true, message: 'Remote path is required' }] }
                ]"
              ></a-input>
            </a-form-item>
          </a-form>
        </div>
      </a-modal>
      <a-modal v-model="helpModalShown" :getContainer="getConsoleContainer" title="Help" @ok="closeHelp">
        <template slot="footer">
          <a-button @click="closeHelp">Thanks!</a-button>
        </template>
        <div>
          <h3>How to Download</h3>
          <ol>
            <li>Ensure terminal session is connected</li>
            <li>Open the dialog via menu Download → Initialize</li>
            <li>Put the full/absolute path of the file to download, into the "Remote Path" below. Be advised, the downloader <em>does not</em> support recursive (dir/folder) download</li>
            <li>If the full path is not yet known, determine it by locating the file in the terminal session, 
              then call<br><code>readlink -f _filename_or_its_relative_path_</code><br>and copy its output</li>
            <li>Click "OK", wait until the file is ready (there will be a notification)</li>
            <li>File can be downloaded via menu Download → Get the File</li>
          </ol>
        </div>
      </a-modal>
    </Fullscreen>
  </div>
</template>
<style scoped src="@/assets/css/pgw.css"></style>
<style scoped src="@/assets/css/webssh2.css"></style>
<script>

import 'xterm/dist/xterm.css'
import io from 'socket.io-client'
import Terminal from '@/utils/Xterm'
import Fullscreen from 'vue-fullscreen/src/component'

const axios = require('axios');
const NodeRSA = require('node-rsa');
import _extends from 'babel-runtime/helpers/extends';
import { backend, config } from '@/config'
import { router } from '@/router';
import { store } from '@/store';
import { authHeader } from '@/services'
import { isFunction } from '@/utils/Util'
import { formLayout } from "./common";

export default {
  name: 'Console',
  components: {
    Fullscreen
  },
  props: {
    apiBasePath: {
      type: String,
      default: ''
    },
    connectOnMount: {
      type: Boolean,
      default: true
    },
    host: {
      type: String,
      default: null
    },
    username: {
      type: String,
      default: null
    },
    activityId: {
      type: String,
      default: null
    },
    currentRemotingId: {
      type: String,
      default: null
    },
    onConnectSuccess: {
      type: Function,
      default: function () {}
    },
    onConnectFail: {
      type: Function,
      default: function (param) {}
    },
    onConnectSuccessOrFail: {
      type: Function,
      default: function () {}
    },
    onResize: {
      type: Function,
      default: function () {}
    },
    onFullscreenOn: {
      type: Function,
      default: function () {}
    },  //too lazzy to do these the proper way
    onFullscreenOff: {
      type: Function,
      default: function () {}
    },
    onDisconnect: {
      type: Function,
      default: function (param) {}
    },
    trfFlag: {
      //TODO: is this the proper way to define const?
      type: Object,
      default: () => {
        return {
          none: 0,
          inProgress: 1,
          success: 2,
          fail: 99
        }
      }
    }
  },
  data () {
    var theData = {
      altId: null,
      term: null,
      terminalSocket: null,
      previouslyConnected: false,
      currentKey: null,
      currentPassword: null,
      manualReset: false,
      allowreplay: false,
      allowreauth: false,
      sessionLogEnable: false,
      loggedData: false,
      sessionLog: null, 
      sessionFooter: null, 
      logDate: null, 
      currentDate: null, 
      myFile: null, 
      errorExists: false,
      sessionDisconnecting: false,
      sessionConnected: false,
      ulModalShown: false,
      ulForm: this.$form.createForm(this),
      uploads: [],
      ulStatus: this.$props.trfFlag.none,
      currentUlId: null,
      lastUlResult: null,
      dlModalShown: false,
      dlForm: this.$form.createForm(this),
      dlStatus: this.$props.trfFlag.none,
      currentDlId: null,
      lastDlResult: null,
      helpModalShown: false,
    };
    return _extends(theData, formLayout);
  },
  methods: {
    handleConsoleMenu ({ item, key, keyPath }) {
      if (key === 'console-connect') {
        this.initLiveShell();
      } else if (key === 'console-disconnect') {
        this.resetTerminal(true);
      } else if (key === 'console-upload') {
        this.showUploadModal();
      } else if (key === 'console-dl-init') {
        this.showDownloadModal();
      } else if (key === 'console-dl-get') {
        this.doDownload();
      } else if (key === 'console-fs') {
        let wasOn = this.$refs.consoleContainer.isFullscreen;
        this.$refs.consoleContainer.toggle();
        if (wasOn && isFunction(this.onFullscreenOff))
          this.onFullscreenOff();
        if (!wasOn && isFunction(this.onFullscreenOn))
          this.onFullscreenOn();
        if (this.isConnected)
          this.term.focus();
      } else if (key === 'console-help') {
        this.openHelp();
      }
    },
    moveStuff (where) {
        this.$notification.destroy();
        if (where) {
          this.$notification.config({
              getContainer: function () {
                  return document.querySelector(where) || document.body;
              }
          });
        } else {
          this.$notification.config({
              getContainer: function () {
                  return document.body;
              }
          });
        }
    },
    resetTerminal (manual, hard) {
      this.manualReset = manual;
      window.removeEventListener('resize', this.resizeScreen, false);
      if (this.terminalSocket && !this.sessionDisconnecting) {
        this.sessionDisconnecting = true;
        this.terminalSocket.emit('disconnect', 'Reset by User');
        this.terminalSocket.close();
      }
      this.sessionConnected = false;
      this.sessionDisconnecting = false;
      if (hard === true) {
        /*
        let container = document.getElementById('console-container');
        let oldBox = document.querySelector('#console-container .term-box');
        if (!container || !oldBox)
          return;
        // let draw = this.$createElement;
        // let vdom = draw('div', { class: { 'term-box': true } }, [
        //   draw('div', { attrs: { id: 'term-header' } }),
        //   draw('div', { attrs: { id: 'term-container' }, class: { 'terminal': true } }),
        //   draw('div', { attrs: { id: 'term-bottomdiv' } }, [
        //     draw('div', { attrs: { id: 'term-footer' } }),
        //     draw('div', { attrs: { id: 'term-status' } })
        //   ]),
        // ]);
        // console.debug(vdom);

        let newBox = document.createElement("div");
        newBox.classList.add('term-box');
        let newHeader = document.createElement("div");
        newHeader.id = 'term-header';
        newBox.appendChild(newHeader);
        let newTerminal = document.createElement("div");
        newTerminal.id = 'term-container';
        newTerminal.classList.add('terminal');
        newBox.appendChild(newTerminal);
        let newBottom = document.createElement("div");
        newBottom.id = 'term-bottomdiv';
        let newFooter = document.createElement("div");
        newFooter.id = 'term-footer';
        newBottom.appendChild(newFooter);
        let newStatus = document.createElement("div");
        newStatus.id = 'term-status';
        newBottom.appendChild(newStatus);
        newBox.appendChild(newBottom);

        container.replaceChild(newBox, oldBox);
        //TODO: how to proper destroy (and remount) component
        */
      }
    },
    initLiveShell (id) {
      this.altId = id;
      this.errorExists = false;
      let initHeaders = authHeader();
      initHeaders['Content-Type'] = 'multipart/form-data';
      const options = {
          baseURL: config.backendUrl,
          timeout: 30000,
          method: 'GET',
          headers: initHeaders,
          url: this.apiBasePath + '/initshell?rid=' + this.rid
      };
      let proxy = this;
      axios(options).then(
          data => {
            proxy.previouslyConnected = true;
            // proxy.resetTerminal(false);
            if (proxy.term) {
              proxy.term.dispose();
            }
            proxy.term = new Terminal();
            proxy.term.open(document.getElementById('term-container'));
            window.addEventListener('resize', proxy.resizeScreen, false);
            let rootPath = backend.rootPath.endsWith('/') ? 
              backend.rootPath.substring(0, backend.rootPath.length - 1) : backend.rootPath; 
            const sockOpts = {
              forceNew: true,
              timeout: 1000,
              path: rootPath + proxy.apiBasePath + '/socket',
              // transports: ['polling', 'websocket'],  //TODO: websocket doesn't work [properly] yet
              transports: [ "polling" ],
              // extraHeaders: {
              //   Authorization: initHeaders.Authorization
              // },
              timeout: 1000
            };
            if (proxy.previouslyConnected === true) {
              sockOpts.timeout = 100;
            }
            var url = data.config.baseURL 
              + '?rid=' + proxy.rid
              + "&shid=" + data.data
              + '&aid=' + proxy.aid;
            proxy.terminalSocket = io(url, sockOpts);
            proxy.initTermHandlers();
            proxy.sessionConnected = true;
            proxy.sessionDisconnecting = false;
            if (isFunction(proxy.onConnectSuccess)) {
              proxy.term.focus();
              proxy.onConnectSuccess();
            }
          },
          error => {
            console.log('init fail: ' + error);
            if (isFunction(proxy.onConnectFail)) {
              proxy.onConnectFail(error);
            }
            if (error.response && error.response.status === 401) {
              //since we don't use axiosBackend, must handle this ourselves
              store.dispatch('auth/logout', { 
                redirectToLogin: true,
                notification: {
                  message: 'Invalid or expired session', 
                  type: 'error'
                }
              });
            }
          }
        )
        .finally(() => {
          if (isFunction(proxy.onConnectSuccessOrFail)) {
            proxy.onConnectSuccessOrFail();
          }
        });
    },
    initTermHandlers () {
      if (!this.term || !this.terminalSocket)
        return;

      let proxy = this;
      this.term.on('data', function (data) {
        proxy.terminalSocket.emit('data', data);
      })

      this.terminalSocket.on('data', function (data) {
        if (!proxy || !proxy.term)
          return;
        proxy.term.write(data);
        if (proxy.sessionLogEnable) {
          proxy.sessionLog = proxy.sessionLog + data;
        }
      })

      this.terminalSocket.on('connect', function () {
        proxy.resizeScreen();
      })

      // this.terminalSocket.on('reconnect_attempt', () => {
      //   proxy.terminalSocket.io.opts.transports = ['polling', 'websocket'];
      // })

      this.terminalSocket.on('setTerminalOpts', function (data) {
        if (!proxy || !proxy.term)
          return;
        proxy.term.setOption('cursorBlink', data.cursorBlink);
        proxy.term.setOption('scrollback', data.scrollback);
        proxy.term.setOption('tabStopWidth', data.tabStopWidth);
        proxy.term.setOption('bellStyle', data.bellStyle);
      })

      this.terminalSocket.on('title', function (data) {
        // document.title = data;
      })

      this.terminalSocket.on('menu', function (data) {
        if (!proxy)
          return;
        // proxy.drawMenu(data);
      })

      this.terminalSocket.on('status', function (data) {
        document.getElementById('term-status').innerHTML = data;
      })

      this.terminalSocket.on('ssherror', function (data) {
        document.getElementById('term-status').innerHTML = data;
        document.getElementById('term-status').style.backgroundColor = 'red';
        document.getElementById('term-header').style.backgroundColor = 'red';
        if (!document.getElementById('term-header').innerHTML.endsWith(' (NOT CONNECTED)'))
          document.getElementById('term-header').innerHTML += ' (NOT CONNECTED)';
        if (proxy)
          proxy.errorExists = true;
      })

      this.terminalSocket.on('headerBackground', function (data) {
        document.getElementById('term-header').style.backgroundColor = data;
      })

      this.terminalSocket.on('header', function (data) {
        if (data) {
          document.getElementById('term-header').innerHTML = data;
          document.getElementById('term-header').style.display = 'block';
          if (proxy)
            proxy.resizeScreen();
        }
      })

      this.terminalSocket.on('statusBackground', function (data) {
        document.getElementById('term-status').style.backgroundColor = data;
      })

      this.terminalSocket.on('allowreplay', function (data) {
        if (!proxy)
          return;
        if (data === true) {
          proxy.allowreplay = true;
          // proxy.drawMenu(document.getElementById('term-dropup-content').innerHTML + '<a id="term-credentials-btn" class="term-btn"><i class="fas fa-key fa-fw"></i> Credentials</a>');
        } else {
          proxy.allowreplay = false;
        }
      })

      this.terminalSocket.on('allowreauth', function (data) {
        if (!proxy)
          return;
        if (data === true) {
          proxy.allowreauth = true;
          // proxy.drawMenu(document.getElementById('term-dropup-content').innerHTML + '<a id="term-reauth-btn" class="term-btn"><i class="fas fa-key fa-fw"></i> Switch User</a>');
        } else {
          proxy.allowreauth = false;
        }
      })

      this.terminalSocket.on('disconnect', function (err) {
        if (!proxy)
          return;
        if (!proxy.errorExists && document.getElementById('term-container')) {
          document.getElementById('term-status').style.backgroundColor = 'red';
          document.getElementById('term-status').innerHTML =
            'DISCONNECTED: ' + err;
          document.getElementById('term-header').style.backgroundColor = 'red';
          if (!document.getElementById('term-header').innerHTML.endsWith(' (DISCONNECTED)'))
            document.getElementById('term-header').innerHTML += ' (DISCONNECTED)';
        }
        if (proxy.term)
          proxy.term.blur();
        if (isFunction(proxy.onDisconnect)) {
          proxy.onDisconnect(err);
          proxy.sessionConnected = false;
        }
        if (proxy.terminalSocket)
          proxy.terminalSocket.io.reconnection(false);
      })

      this.terminalSocket.on('error', function (err) {
        if (!proxy)
          return;
        if (!proxy.errorExists) {
          document.getElementById('term-status').style.backgroundColor = 'red';
          document.getElementById('term-status').innerHTML = 'ERROR: ' + err;
          document.getElementById('term-header').style.backgroundColor = 'red';
          if (!document.getElementById('term-header').innerHTML.endsWith(' (NOT CONNECTED)'))
            document.getElementById('term-header').innerHTML += ' (NOT CONNECTED)';
        }
        if (proxy.term)
          proxy.term.blur();
        if (isFunction(proxy.onDisconnect)) {
          proxy.onDisconnect(err);
          proxy.sessionConnected = false;
        }
      })

      this.terminalSocket.on('reauth', function () {
        (proxy && proxy.allowreauth) && proxy.reauthSession();
      })

      this.term.on('title', function (title) {
        // document.title = title;
      })
    },
    showUploadModal () {
      this.uploads = [];
      this.ulModalShown = true;
    },
    handleConsoleUpload (file) {
      this.uploads = [...this.uploads, file];
      return false;
    },
    consoleUploadChanged (info) {
      if (this.uploads && this.uploads.length > 0) {
        let after = [];
        for (var idx in this.uploads) {
          if (this.uploads[idx] && this.uploads[idx]['status'] !== 'removed') {
            this.uploads[idx].status = 'done';
            after.push(this.uploads[idx]);
          }
        }
        this.uploads = after.slice();
      }
    },
    initUpload (e) {
      this.ulModalShown = false;
      if (this.sessionConnected !== true || this.sessionDisconnecting === true) {
        this.updateUlStatus(this.$props.trfFlag.none, 'Please connect the terminal session first');
        return;
      }
      if (this.ulStatus === this.$props.trfFlag.inProgress) {
        this.updateUlStatus(this.$props.trfFlag.inProgress, 'An upload is already in progress');
        return;
      }
      this.resetTerminal(true, false);
      let initHeaders = authHeader();
      initHeaders['Content-Type'] = 'multipart/form-data';
      const formData = new FormData();
      for (var idx = 0; idx < this.uploads.length; idx++) {
        formData.append('upload', this.uploads[idx]);
      }
      const options = {
        baseURL: config.backendUrl,
        timeout: 30000,
        method: 'POST',
        headers: initHeaders,
        data: formData,
        url: this.apiBasePath + '/initupload?rid=' + this.rid + '&aid=' + this.aid
      };
      let proxy = this;
      axios(options).then(
          data => {
            proxy.lastUlResult = null;
            proxy.currentUlId = data.data;
            proxy.$notification['info']({
                duration: config.notifDuration || 3,
                message: 'Upload in progress',
                description: 'You will be notified when it is done'
            });
          },
          error => {
            proxy.$notification['error']({
                duration: config.notifDuration || 3,
                message: 'Upload aborted',
                description: error
            });
          }
      );
    },
    cancelUpload (e) {
      this.ulModalShown = false;
    },
    showDownloadModal () {
      if (this.dlForm && this.dlForm.domFields && Object.keys(this.dlForm.domFields).length > 0) {
        this.dlForm.setFieldsValue({
            path: ""
        });
      }
      this.dlModalShown = true;
    },
    updateUlStatus (flag, msg, showNotif) {
      this.ulStatus = flag;
      let cfg = {};
      switch (flag) {
        case this.$props.trfFlag.none:
        case this.$props.trfFlag.inProgress:
          cfg.type = 'warn';
          cfg.message = 'Upload cannot start';
          if (msg && msg.length > 0)
            cfg.description = msg;
          break;
        case this.$props.trfFlag.success:
          //nothing
          break;
        case this.$props.trfFlag.fail:
          cfg.type = 'error';
          cfg.message = 'Upload error';
          if (msg && msg.length > 0)
            cfg.description = msg;
          break;
        default:
          break;
      }
      if (cfg.type && showNotif !== false) {
        this.$notification[cfg.type](cfg);
      }
    },
    updateDlStatus (flag, msg, showNotif) {
      this.dlStatus = flag;
      let cfg = {};
      switch (flag) {
        case this.$props.trfFlag.none:
        case this.$props.trfFlag.inProgress:
          cfg.type = 'warn';
          cfg.message = 'Download cannot start';
          if (msg && msg.length > 0)
            cfg.description = msg;
          break;
        case this.$props.trfFlag.success:
          //nothing
          break;
        case this.$props.trfFlag.fail:
          cfg.type = 'error';
          cfg.message = 'Download error';
          if (msg && msg.length > 0)
            cfg.description = msg;
          break;
        default:
          break;
      }
      if (cfg.type && showNotif !== false) {
        this.$notification[cfg.type](cfg);
      }
    },
    cancelDownload (e) {
      this.dlModalShown = false;
    },
    initDownload () {
      this.dlModalShown = false;
      if (this.sessionConnected !== true || this.sessionDisconnecting === true) {
        this.updateDlStatus(this.$props.trfFlag.none, 'Please connect the terminal session first');
        return;
      }
      if (this.dlStatus === this.dlStatus.inProgress) {
        this.updateDlStatus(this.$props.trfFlag.inProgress, 'A download is already in progress');
        return;
      }
      let proxy = this;
      this.dlForm.validateFields((err, values) => {
        if (!err) {
          let initHeaders = authHeader();
          const options = {
              baseURL: config.backendUrl,
              timeout: 30000,
              method: 'GET',
              headers: initHeaders,
              url: proxy.apiBasePath + '/initdownload?rid=' + proxy.rid + '&aid=' + proxy.aid + '&path=' + values.path
          };
          axios(options).then(
              data => {
                // proxy.lastDlResult = null;
                // console.debug('download id ' + data.data);
                proxy.currentDlId = data.data;
                proxy.$notification['info']({
                    duration: config.notifDuration || 3,
                    message: 'Download in progress',
                    description: 'You will be notified when it is done'
                });
              },
              error => {
                // console.debug('download err ' + error);
                proxy.updateDlStatus(proxy.$props.trfFlag.fail, error);
              }
            );
        }
      });
    },
    doDownload () {
      let initHeaders = authHeader();
      const options = {
          baseURL: config.backendUrl,
          // timeout: 30000,
          method: 'GET',
          headers: initHeaders,
          responseType: 'blob',
          url: this.apiBasePath + '/download?rid=' + this.rid + '&aid=' + this.aid + '&dlid=' + this.currentDlId
      };
      let proxy = this;
      axios(options).then(
          data => {
            const blob = new Blob([data.data]);
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(blob, proxy.lastDlResult);
            } else {
              var elem = window.document.createElement('a');
              elem.href = window.URL.createObjectURL(blob);
              elem.download = proxy.lastDlResult;
              document.body.appendChild(elem);
              elem.click();
              document.body.removeChild(elem);
            }
          },
          error => {
            // console.debug('download err ' + error);
            proxy.updateDlStatus(proxy.$props.trfFlag.fail, error);
          }
        );
    },
    openHelp () {
      this.helpModalShown = true;
    },
    closeHelp () {
      this.helpModalShown = false;
    },
    resizeScreen () {
      if (isFunction(this.onResize)) {
        this.onResize();
      }
      this.term.fit();
      this.terminalSocket.emit('resize', { cols: this.term.cols, rows: this.term.rows });
    },
    // draw/re-draw menu and reattach listeners
    // when dom is changed, listeners are abandonded
    // drawMenu (data) {
    //   document.getElementById('term-dropup-content').innerHTML = data;
    //   document.getElementById('term-log-btn').addEventListener('click', this.toggleLog);
    //   this.allowreauth && document.getElementById('term-reauth-btn').addEventListener('click', this.reauthSession);
    //   this.allowreplay && document.getElementById('term-credentials-btn').addEventListener('click', this.replayCredentials);
    //   this.loggedData && document.getElementById('term-dl-log-btn').addEventListener('click', this.downloadLog);
    // },
    // reauthenticate
    reauthSession () { // eslint-disable-line
      console.log('re-authenticating')
      window.location.href = '/reauth'
      return false
    },
    // replay password to server, requires
    replayCredentials () { // eslint-disable-line
      this.terminalSocket.emit('control', 'replayCredentials')
      console.log('replaying credentials')
      this.term.focus()
      return false
    },
    // Set variable to toggle log data from client/server to a varialble
    // for later download
    toggleLog () { // eslint-disable-line
      if (this.sessionLogEnable === true) {
        this.sessionLogEnable = false
        this.loggedData = true
        document.getElementById('term-log-btn').innerHTML = '<i class="fas fa-clipboard fa-fw"></i> Start Log'
        console.log('stopping log, ' + this.sessionLogEnable)
        this.currentDate = new Date()
        this.sessionLog = this.sessionLog + '\r\n\r\nLog End for ' + this.sessionFooter + ': ' +
          this.currentDate.getFullYear() + '/' + (this.currentDate.getMonth() + 1) + '/' +
          this.currentDate.getDate() + ' @ ' + this.currentDate.getHours() + ':' +
          this.currentDate.getMinutes() + ':' + this.currentDate.getSeconds() + '\r\n';
        this.logDate = this.currentDate
        this.term.focus()
        return false
      } else {
        this.sessionLogEnable = true
        this.loggedData = true
        document.getElementById('term-log-btn').innerHTML = '<i class="fas fa-cog fa-spin fa-fw"></i> Stop Log'
        document.getElementById('term-dl-log-btn').style.color = '#000'
        document.getElementById('term-dl-log-btn').addEventListener('click', this.downloadLog)
        console.log('starting log, ' + this.sessionLogEnable)
        this.currentDate = new Date()
        this.sessionLog = 'Log Start for ' + this.sessionFooter + ': ' +
          this.currentDate.getFullYear() + '/' + (this.currentDate.getMonth() + 1) + '/' +
          this.currentDate.getDate() + ' @ ' + this.currentDate.getHours() + ':' +
          this.currentDate.getMinutes() + ':' + this.currentDate.getSeconds() + '\r\n\r\n';
        this.logDate = this.currentDate
        this.term.focus()
        return false
      }
    },
    // cross browser method to "download" an element to the local system
    // used for our client-side logging feature
    downloadLog () { // eslint-disable-line
      if (this.loggedData === true) {
        this.myFile = 'WebSSH2-' + this.logDate.getFullYear() + (this.logDate.getMonth() + 1) +
          this.logDate.getDate() + '_' + this.logDate.getHours() + this.logDate.getMinutes() +
          this.logDate.getSeconds() + '.log'
        // regex should eliminate escape sequences from being logged.
        var blob = new Blob([this.sessionLog.replace(/[\u001b\u009b][[\]()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><;]/g, '')], { // eslint-disable-line no-control-regex
          type: 'text/plain'
        })
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, this.myFile)
        } else {
          var elem = window.document.createElement('a')
          elem.href = window.URL.createObjectURL(blob)
          elem.download = this.myFile
          document.body.appendChild(elem)
          elem.click()
          document.body.removeChild(elem)
        }
      }
      this.term.focus()
    },
  },
  computed: {
    getConsoleContainer() {
      return function () {
        return document.querySelector('#console-container') || document.body;
      };
    },
    notifKey() {
      return this.$store.state.pgwbus.notifKey;
    },
    dataKey() {
      return this.$store.state.pgwbus.dataKey;
    },
    isConnected() {
      return this.sessionConnected === true
        && this.term !== undefined && this.term !== null
        && this.terminalSocket !== undefined && this.terminalSocket !== null;
    },
    canDownload() {
      return this.dlStatus === this.$props.trfFlag.success;
    },
    rid() {
      return this.currentRemotingId || this.altId;
    },
    aid() {
      return this.activityId;
    }
  },
  mounted () {
    this.moveStuff('#console-container');
  },
  updated () {
  },
  watch: {
    notifKey(key) {
      if (store.state.pgwbus.dataModule === 'SCPUP') {
        this.initLiveShell();
      } else if (store.state.pgwbus.dataModule === 'SCPDN') {
        if (store.state.pgwbus.notifType === 'success') {
          this.updateDlStatus(this.$props.trfFlag.success, null, false);
        } else {
          this.updateDlStatus(this.$props.trfFlag.fail, null, false);
        }
      }
    },
    dataKey(key) {
      if (store.state.pgwbus.dataModule === 'SCPDN') {
        if (store.state.pgwbus.notifType === 'none') {
          this.lastDlResult = store.state.pgwbus.notifMessage;
        } else if (store.state.pgwbus.notifType === 'success') {
          this.updateDlStatus(this.$props.trfFlag.success, null);
        } else {
          this.updateDlStatus(this.$props.trfFlag.fail, null, false);
        }
      }
    }
  },
  beforeDestroy () {
    this.moveStuff(null);
    this.resetTerminal(false);
  }
}
</script>