var formLayout = {
    filCommon: {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 5 },
            lg: { span: 3 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 10 },
            lg: { span: 6 }
        }
    },
    filInModal: {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 8 },
            lg: { span: 6 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 16 },
            lg: { span: 18 }
        }
    },
    filNoLabel: {
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 15 },
            lg: { span: 9 }
        }
    },
    filNoLabelInModal: {
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 }
        }
    },
    formDynamicItemLayout: {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 20, offset: 0 }
        }
    },
};

export { formLayout };