<template>
    <div>
        <a-row :gutter="8">
            <template v-for="eachPo in dataMap">
                <a-col v-for="eachSow in eachPo.sows" :key="`${eachPo.po}-${eachSow.id}`" :span='6'>
                    <a-card :title="`[PO: ${eachPo.po}] [SOW ${eachSow.name}]`" style="margin-bottom:30px">
                        <apexchart  type="pie" :options="chartOptions" :series="series[`${eachPo.po}-${eachSow.id}`]" ></apexchart>
                    </a-card>
                </a-col>
            </template>
        </a-row>
    </div>
</template>


<script>
import axios from "axios";
import { config } from "@/config";
import { authHeader } from "@/services/index";
const columns = [{
    title: 'Pending',
    children: [{
        title: 'PO',
        dataIndex: 'po',
        key: 'po',
        width: 200,
    }, {
        title: 'Sow',
        children: [{
        title: 'Sow Name',
        dataIndex: 'sowname',
        key: 'sowname',
        width: 200,
        },
        //   title: 'Block',
        {
            title: 'Pending name',
            dataIndex: 'pendingname',
            key: 'pendingname',
            width: 150,
        },
        {
            title: 'total',
            dataIndex: 'total',
            key: 'total',
            width: 150,
        }], 
    }],
}];

export default {
    name: 'Home',
    computed: {
        userInfo() {
            return this.$store.state.auth.data.user;
        }
    },
    data() {
        return {
            title: "Dashboard",
            columns,
            dataTest : [],
            nodeSource : [],
            dataMap: [],
            chartOptions: {
                labels: ['Pending Nodin',  'Pending CRQ', 'Pending ENM'],
                dataLabels: {
                    formatter: function (val, opts) {
                        return opts.w.config.series[opts.seriesIndex]
                    },
                }
            },
            series: [],
        };
    },
    mounted() {
        const router = this.$router;
        axios.get(`${config.backendUrl}/data/nodedashboard?search`,{
            headers : authHeader()
        })
        .then(response =>{
            
            this.nodeSource = response.data.data;
            var poHandled = [];
            var poSowHandled = [];
            this.nodeSource.forEach(element => { 
                //inserting placeholder
                if(poSowHandled.indexOf(`${element.po}-${element.sowname}`) == -1) {
                    //inser placehoder for PO
                    if(poHandled.indexOf(element.po) == -1) {
                        this.dataTest.push({ 
                            po:element.po, 
                            poId : element.po, 
                            sowname:'', 
                            sowId : element.sow_id, 
                            pendingname:'', 
                            total: '',
                            span: 3
                        });
                        poHandled.push(element.po);
                    }
                    //insert placeholder remaining
                    {
                        //inser placeholder sow
                        this.dataTest.push({ 
                            po:'', 
                            poId : element.po, 
                            sowname: element.sowname, 
                            sowId : element.sow_id, 
                            pendingname:'', 
                            total: '',
                            span: 2
                        });
                        //inser placeholder pending nodin
                        this.dataTest.push({ 
                            po:'', 
                            poId : element.po, 
                            sowname:'', 
                            sowId : element.sow_id, 
                            pendingname:'Pending Nodin', 
                            total: '0',
                            span: 0
                        });
                        //inser placeholder pending crq
                        this.dataTest.push({ 
                            po:'', 
                            poId : element.po, 
                            sowname:'', 
                            sowId : element.sow_id, 
                            pendingname:'Pending CRQ', 
                            total: '0',
                            span: 0
                        });
                        //inser placeholder pending enm
                        this.dataTest.push({ 
                            po:'', 
                            poId : element.po, 
                            sowname:'', 
                            sowId : element.sow_id, 
                            pendingname:'Pending ENM', 
                            total: '0',
                            span: 0
                        });
                        poSowHandled.push(`${element.po}-${element.sowname}`);
                    }
                }

                var inData = this.dataTest.find(function(x) {
                    return x.poId == element.po && x.sowId == element.sow_id && x.pendingname == element.status;
                });
                inData.total = element.count;
            })

            var dataMap = [];
            var series = [];
            this.nodeSource.forEach(elmt => {
                if(elmt.po == null || elmt.po == "null" || elmt.po == undefined)
                    elmt.po = "N/A";
                var data = dataMap.find(x => {
                    return x.po == elmt.po;
                });
                if(data == undefined) {
                    data = {po: elmt.po, sows : []};
                    dataMap.push(data);
                }
                var sows = data.sows;
                var sow = sows.find(x => {
                    return x.id == elmt.sow_id;
                });
                if(sow == undefined) {
                    sow = {id : elmt.sow_id, name: elmt.sowname, pendings: [] };
                    sow.pendings = [0 , 0 , 0];
                    sows.push(sow);
                }

                if(elmt.status == 'Pending Nodin')
                    sow.pendings[0] = elmt.count;
                else if(elmt.status == 'Pending CRQ')
                    sow.pendings[1] = elmt.count;
                else if(elmt.status == 'Pending ENM')
                    sow.pendings[2] = elmt.count;

                series[`${elmt.po}-${elmt.sow_id}`] = sow.pendings;

            });

            this.series = series;
            this.dataMap = dataMap;
            
        }).catch(function(error){
            if(error.response && error.response.status === 401) {
                router.push("/login");
            }
        });
        
    },
    
};
</script>
