<template>
    <a-layout id="login-page-layout">
        <a-layout-content
            :style="{
              background: '#331f1f',
              background: '-webkit-linear-gradient(to top, #331f1f, #7a1f1f)',
              background: 'linear-gradient(to top, #331f1f, #7a1f1f)'
              }"
        >
            <a-row type="flex" justify="center" align="middle" style="height: 100vh;">
                <a-col :span="6" :style="{ minWidth: '320px' }">
                    <a-card>
                        <img src="../assets/logo.png" alt="logo" slot="cover">
                        <a-form
                            id="components-form-demo-normal-login"
                            :form="form"
                            class="login-form"
                            @submit="handleSubmit"
                        >
                            <a-form-item>
                                <a-input
                                    v-decorator="[
                                    'username',
                                    { rules: [{ required: true, message: 'Please input your username!' }] }]"
                                    placeholder="Username"
                                >
                                    <a-icon
                                        slot="prefix"
                                        type="user"
                                        style="color: rgba(0,0,0,.25)"
                                    />
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input
                                    v-decorator="[
                                    'password',
                                    { rules: [{ required: true, message: 'Please input your Password!' }] }]"
                                    type="password"
                                    placeholder="Password"
                                >
                                    <a-icon
                                        slot="prefix"
                                        type="lock"
                                        style="color: rgba(0,0,0,.25)"
                                    />
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-checkbox
                                    v-decorator="[
                                    'remember',
                                    {
                                        valuePropName: 'checked',
                                        initialValue: true,
                                    }]"
                                >Remember me</a-checkbox>
                                <a-button
                                    type="primary"
                                    html-type="submit"
                                    class="login-form-button"
                                >Log in</a-button>
                            </a-form-item>
                        </a-form>
                    </a-card>
                </a-col>
            </a-row>
        </a-layout-content>
    </a-layout>
</template>

<script>
export default {
    data() {
        return {
            submitted: false
        };
    },
    computed: {
        loggingIn() {
            return this.$store.state.auth.status.loggingIn;
        }
    },
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    created() {
        // reset login status
        this.$store.dispatch("auth/logout", {
            redirectToLogin: false, //avoid NavigationDuplicated error
            notification: {}
        });
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.submitted = true;
                    values['target'] = this.$route && this.$route['query']['p'] || '/';
                    const { username, password, target } = values;
                    this.$store.dispatch("auth/login", { username, password, target });
                }
            });
        }
    }
};
</script>
<style>
#components-form-demo-normal-login .login-form {
    max-width: 300px;
}
#components-form-demo-normal-login .login-form-button {
    width: 100%;
}
</style>