<template>
    <a-spin :spinning="loading">
        <a-form :form="form">
            <Form 
                :theForm="form" 
                :isCreateForm="createForm" 
                @handle-submit="handleSubmit" 
                ref="sowForm"/>
        </a-form>
    </a-spin>
</template>

<script>
import Form from "./SowForm";
import { routerPath } from "@/router";

export default {
    components: {
        Form
    },
    computed: {
        indexPath: () => routerPath.sowIndex,
        createState() {
            return this.$store.state.data.success;
        },
    },
    watch: {
        createState(key) {
            if (key !== null && this.submitting === true) {
                this.loading = false;
                if (this.$store.state.data.success === true) {
                    this.$router.push(this.indexPath);
                }
            }
        },
    },
    data() {
        return {
            apiPath: "data/sow",
            loading: false,
            createForm: true,
            submitting: false
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    methods: {
        handleSubmit(e) {
            this.loading = true;
            e.preventDefault();
            const path = this.apiPath
            this.form.validateFields((err, values) => {
                if (!err) {
                    const data = new FormData();
                    data.append('number', this.form.getFieldValue('number'));
                    data.append('name', this.form.getFieldValue('name'));
                    data.append('explanation', this.form.getFieldValue('explanation'));
                    this.submitting = true;
                    this.$store.dispatch("data/create", { path: path, data: data });
                } else {
                    this.loading = false;
                }
            });
        },
    }
};

</script>
