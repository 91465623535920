var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "AuditLog",
        _vm._b(
          { ref: "log" },
          "AuditLog",
          {
            title: _vm.title,
            table: _vm.table,
            columns: _vm.columns,
            entityId: _vm.entityId,
            nameColumn: _vm.nameColumn
          },
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }