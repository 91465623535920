<template>
  <div>
    <Console
      ref="console"
      :onConnectSuccess="onInitSuccess"
      :onConnectFail="onInitFail"
      :onConnectSuccessOrFail="onInitSuccessOrFail"
      :onResize="onResize"
      :onFullscreenOn="onFullscreenOn"
      :onFullscreenOff="onFullscreenOff"
      :onDisconnect="onDisconnect"
      :currentRemotingId="remotingId"
      :apiBasePath="apiBasePath"
    />
  </div>
</template>
<style scoped src="@/assets/css/pgw.css"></style>
<style scoped src="@/assets/css/webssh2.css"></style>
<script>
import _extends from 'babel-runtime/helpers/extends';
import Console from "@/components/Console";
import { backend, config } from '@/config'
import { authHeader } from '@/services'
const axios = require('axios');
import { formLayout } from "@/components/common";

export default {
  components: {
    Console
  },
  name: 'ConsolePgw',
  props: {
  },
  data () {
    var theData = {
      connected: false,
      remotingId: null,
      apiBasePath: '/remoting/pgw',
      fullscreen: null
    };
    return _extends(theData, formLayout);
  },
  methods: {
    initRemoting () {
      let proxy = this;
      let initHeaders = authHeader();
      initHeaders['Content-Type'] = 'multipart/form-data';
      let url = this.apiBasePath + '/init';
      const options = {
        baseURL: config.backendUrl,
        timeout: 30000,
        method: 'POST',
        headers: initHeaders,
        url: url
      };
      axios(options).then(
        data => {
          proxy.remotingId = data.data.rid;
          proxy.$refs.console.initLiveShell(data.data.rid);
        },
        error => {
          //console.debug('pre-init fail: ' + error);
          if (error.response && error.response.status === 401) {
          //since we don't use axiosBackend, must handle this ourselves
          store.dispatch('auth/logout', { 
            redirectToLogin: true,
            notification: {
              message: 'Invalid or expired session', 
              type: 'error'
            }
          });
          }
        });
    },
    onInitSuccess () {
      this.connected = true;
      this.$refs.console.term.focus();
    },
    onInitFail (param) {
      // console.log(param);
    },
    onInitSuccessOrFail () {
    },
    onResize () {
      let theBox = document.querySelector('.term-box');
      let bottom = this.fullscreen === true ? 15 : 40;
      if (theBox)
        theBox.style.height = (window.innerHeight - theBox.offsetTop - bottom) + 'px';
      this.fullscreen = null;
    },
    onFullscreenOn () {
        this.fullscreen = true;
    },
    onFullscreenOff () {
        this.fullscreen = false;
    },
    onDisconnect (param) {
      if (this.isConnected())
        this.$refs.console.resetTerminal(false);
      this.connected = false;
    },
    isConnected () {
      return this.$refs.console && this.$refs.console.isConnected === true;
    },
  },
  mounted () {
      this.initRemoting();
  },
  beforeDestroy () {
    // if (this.isConnected())
      this.$refs.console.resetTerminal(false/*, true*/);
  }
}
</script>