var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.canCreate
        ? _c(
            "a-button",
            {
              staticClass: "lateral-button",
              attrs: { type: "primary" },
              on: { click: _vm.create }
            },
            [_vm._v("Create")]
          )
        : _vm._e(),
      _c(
        "a-button",
        { staticClass: "lateral-button", attrs: { type: "primary" } },
        [
          _c("router-link", { attrs: { to: "/enm/deletion" } }, [
            _vm._v("View Deletion Logs")
          ])
        ],
        1
      ),
      _c("div", { staticStyle: { "margin-bottom": "10px" } }),
      _c(
        "Index",
        _vm._b(
          {
            ref: "index",
            on: { "edit-record": _vm.edit, "delete-record": _vm.deleteRecord }
          },
          "Index",
          { title: _vm.title, columns: _vm.columns, actions: _vm.actions },
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }