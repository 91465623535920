var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-table",
    {
      attrs: {
        columns: _vm.columns,
        rowKey: function(record) {
          return record.event_id
        },
        dataSource: _vm.data,
        pagination: _vm.pagination,
        loading: _vm.loading,
        expandRowByClick: true,
        bordered: ""
      },
      on: { change: _vm.handleTableChange },
      scopedSlots: _vm._u([
        {
          key: "expandedRowRender",
          fn: function(detail) {
            return _c(
              "div",
              {},
              [
                _c("a-table", {
                  attrs: {
                    columns: _vm.detailColumns,
                    rowKey: function(detail) {
                      return detail.key
                    },
                    dataSource: _vm.getDetailData(detail.event_id),
                    pagination: false,
                    bordered: ""
                  }
                })
              ],
              1
            )
          }
        },
        {
          key: "timeRender",
          fn: function(detail) {
            return _c("span", {}, [
              _vm._v(
                _vm._s(
                  _vm._f("moment")(
                    detail.action_tstamp_tx,
                    "YYYY-MM-DD HH:mm:ss.SSS"
                  )
                )
              )
            ])
          }
        }
      ])
    },
    [
      _vm.title && _vm.title.length
        ? _c(
            "template",
            { slot: "title" },
            [
              _vm._v("\n        " + _vm._s(_vm.title) + "\n        "),
              _vm.searchable
                ? _c("a-input-search", {
                    attrs: {
                      placeholder: "input search text",
                      id: "log-search"
                    },
                    on: { pressEnter: _vm.onSearchEnter },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }