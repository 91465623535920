var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Region" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "region",
                    {
                      rules: [
                        { required: true, message: "ENM must have region" }
                      ]
                    }
                  ],
                  expression:
                    "[\n                'region',\n                {rules: [{ required: true, message: 'ENM must have region' }]}\n            ]"
                }
              ],
              attrs: {
                disabled: !_vm.canEdit,
                showSearch: "",
                defaultActiveFirstOption: false,
                filterOption: false,
                notFoundContent: null
              },
              on: { change: _vm.onRegionSelect }
            },
            _vm._l(_vm.regionList, function(region) {
              return _c("a-select-option", { key: region.id }, [
                _vm._v(_vm._s(region.name))
              ])
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Name" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "name",
                  { rules: [{ required: true, message: "ENM must have name" }] }
                ],
                expression:
                  "[\n            'name',\n            { rules: [{ required: true, message: 'ENM must have name' }]}\n        ]"
              }
            ],
            attrs: { disabled: !_vm.canEdit, placeholder: "ENM Name" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "IP Address" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "address",
                  {
                    rules: [
                      { required: true, message: "ENM must have address" },
                      { validator: _vm.validateAddress }
                    ]
                  }
                ],
                expression:
                  "[\n            'address',\n            {rules: [\n                { required: true, message: 'ENM must have address' },\n                { validator: validateAddress }\n            ]}\n        ]"
              }
            ],
            attrs: { disabled: !_vm.canEdit, placeholder: "ENM Address" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Port" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "port",
                  {
                    rules: [
                      { required: true, message: "ENM must have port number" }
                    ],
                    initialValue: 22
                  }
                ],
                expression:
                  "[\n                'port',\n                {\n                    rules: [{ required: true, message: 'ENM must have port number' }],\n                    initialValue: 22\n                }\n            ]"
              }
            ],
            attrs: {
              disabled: !_vm.canEdit,
              min: 1,
              max: 65535,
              placeholder: "port"
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Base Directory" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "base_dir",
                  {
                    rules: [
                      {
                        required: true,
                        message: "ENM must have base directory"
                      }
                    ],
                    initialValue: "/home/shared/"
                  }
                ],
                expression:
                  "[\n                'base_dir',\n                {\n                    rules: [{ required: true, message: 'ENM must have base directory' }],\n                    initialValue: '/home/shared/'\n                }\n            ]"
              }
            ],
            attrs: { disabled: !_vm.canEdit, placeholder: "base_dir" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Data Directory" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["data_dir"],
                expression: "[\n                'data_dir'\n            ]"
              }
            ],
            attrs: { placeholder: "data_dir" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Manual Url Text" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["manual_url"],
                expression: "[\n                'manual_url'\n            ]"
              }
            ],
            attrs: { disabled: !_vm.canEdit, placeholder: "manual_url" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Manual Context Text" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["manual_context"],
                expression: "[\n                'manual_context'\n            ]"
              }
            ],
            attrs: { disabled: !_vm.canEdit, placeholder: "manual_context" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { staticClass: "faux-label", attrs: { label: " " } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _vm.canEdit
            ? _c(
                "a-button",
                {
                  staticClass: "lateral-button",
                  attrs: { type: "primary", "html-type": "submit" },
                  on: {
                    click: function($event) {
                      return _vm.$emit(_vm.submit, $event)
                    }
                  }
                },
                [_vm._v("Save")]
              )
            : _vm._e(),
          _c(
            "router-link",
            { attrs: { to: "/enm/index/" } },
            [
              _c(
                "a-button",
                { staticClass: "lateral-button", attrs: { type: "primary" } },
                [_vm._v(_vm._s(_vm.canEdit ? "Cancel" : "Back to Index"))]
              )
            ],
            1
          ),
          _vm.isCreateForm !== true
            ? _c(
                "router-link",
                { attrs: { to: "/enm/log/" + _vm.id } },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "lateral-button",
                      attrs: { type: "primary" }
                    },
                    [_vm._v("Audit Log")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }