<template>
    <a-spin :spinning="loading">
        <a-card title="Edit SOW" class="sow-card">
            <a-form :form="form">
                <Form 
                    :id="id"
                    :theForm="form" 
                    :isCreateForm="createForm" 
                    @handle-submit="handleSubmit" 
                    ref="sowForm"/>
            </a-form>
        </a-card>
        <a-card title="Activities" style="margin-top: 20px" class="sow-card">
            <a-button type="primary" style="margin-bottom: 20px" 
                v-if="canCreate" 
                @click="$router.push('/sow/'+id+'/createactivity')">Create</a-button>
            <Index ref="actTable"  @delete-record="deleteActivity"
                v-bind="{ title: null, columns: actColumns, actions: actActions, searchable: false, queryParams: false }"/>
        </a-card>
    </a-spin>
</template>

<script>
import Form from "./SowForm";
import Index from "@/components/Index";
import { routerPath } from "@/router";
import { config } from "@/config";
import { authService } from '@/services/auth';

export default {
    components: {
        Form, Index
    },
    computed: {
        dataState() {
            return this.$store.state.data;
        },
        id() {
            return this.$route.params.id;
        },
        indexPath: () => routerPath.sowIndex,
        updateState() {
            return this.$store.state.data.success;
        },
        loading () {
            return this.loadingSow || this.loadingAct;
        },
        actPath () {
            return `${config.backendUrl}/data/activity/bysow/${this.id}`;
        },
        canCreate () {
            return authService.canCreate();
        }
    },
    mounted() {
        this.$root.$data.breadcrumbIds = [ this.id ];
        this.fetch(this.apiPath);
    },
    data() {
        return {
            apiPath: "data/sow",
            loadingSow: false,
            loadingAct: false,
            createForm: false,
            submitting: false,
            deleting: false,
            actColumns: [
                {
                    title: "Number",
                    dataIndex: "number",
                    sorter: true,
                    width: "5%"
                },
                {
                    title: "Name",
                    dataIndex: "name",
                    sorter: true,
                    width: "30%"
                },
                {
                    title: "Type",
                    dataIndex: "jenis",
                    sorter: true,
                    width: "10%"
                },
                {
                    title: "Description",
                    dataIndex: "keterangan",
                    sorter: true,
                    width: "35%"
                },
                {
                    title: "Action",
                    key: "action",
                    width: "20%",
                    scopedSlots: { customRender: "action" }
                }
            ],
            actActions: [
                {
                    text: "Open",
                    route: '/sow/' + this.$route.params.id + '/activity/', 
                    type: "default",
                    size: "small",
                    show: () => true
                },
                {
                    text: "Delete",
                    emit: "delete-record",
                    type: "danger",
                    size: "small",
                    show: () => authService.canDelete()
                }
            ]
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    watch: {
        dataState: {
            handler(v) {
                if (v.success && v.data) {
                    if (this.loadingSow) {
                        this.initDetail(v.data);
                    }
                } else if (this.loadingSow) {
                    this.loadingSow = false;
                } else if (this.loadingAct) {
                    this.loadingAct = false;
                }
            },
            deep: true
        },
        updateState(key) {
            if (key !== null && this.submitting === true) {
                this.loadingSow = false;
                if (this.$store.state.data.success === true) {
                    this.$router.push(this.indexPath);
                }
            }
        }
    },
    methods: {
        initDetail(data) {
            this.form.setFieldsValue({
                number: data.number,
                name: data.name,
                explanation: data.explanation,
            });
            this.loadingSow = false;
            this.$eventbus.$emit("index-fetch", this.actPath);
        },
        handleSubmit(e) {
            this.loadingSow = true;
            e.preventDefault();
            const path = this.apiPath
            this.form.validateFields((err, values) => {
                if (!err) {
                    const data = new FormData();
                    data.append('number', this.form.getFieldValue('number'));
                    data.append('name', this.form.getFieldValue('name'));
                    data.append('explanation', this.form.getFieldValue('explanation'));
                    this.submitting = true;
                    this.$store.dispatch("data/update", { path: path, id: this.id, data: data });
                } else {
                    this.loadingSow = false;
                }
            });
        },
        fetch(path) {
            this.loadingSow = true;
            const id = this.id;
            this.$store.dispatch("data/detail", { path, id });
        },
        deleteActivity(record) {
            this.$confirm({
                iconType: 'warning',
                title: 'Warning',
                content: 'Are you sure to delete this activity?',
                onOk: () => this.doDelete(record.id),
                destroyOnClose: true
            });
        },
        doDelete(which) {
            this.loadingAct = true;
            this.$store.dispatch("data/delete", { path: 'data/activity', id: which })
                .then( () => {
                    this.$eventbus.$emit("index-fetch", this.actPath);
                });
        }
    }
};
</script>
