<template>
    <a-spin :spinning="loading">
        <a-form :form="form" @submit="handleSubmit">
            <a-form-item label="PO" v-bind="filCommon">
                <a-input 
                    :disabled="!canEdit"
                    v-decorator="['po']">
                </a-input>
            </a-form-item>
            <a-form-item label="NE" v-bind="filCommon">
                <a-input
                    :disabled="!canEdit"
                    v-decorator="[
                        'ne',
                        { rules: [{ required: true, message: 'node must have ne' }]}
                    ]">
                </a-input>
            </a-form-item>
            <a-form-item label="SoW Name" v-bind="filCommon">
                <a-select
                    :disabled="!canEdit"
                    placeholder="SoW Name"
                    showSearch
                    :defaultActiveFirstOption="false"
                    :optionFilterProp="'title'"
                    :notFoundContent="null"
                    :options="sowOpts"
                    v-decorator="[
                        'sow'
                    ]">
                >
                </a-select>
            </a-form-item>
            <a-form-item class="faux-label" v-bind="filCommon" label=" ">
                <a-button
                    v-if="canEdit"
                    type="primary"
                    html-type="submit"
                    @click="handleSubmit"
                    class="lateral-button">Save</a-button>
                <router-link :to="indexPath">
                    <a-button type="primary" class="lateral-button">{{ canEdit ? 'Cancel' : 'Back to Index' }}</a-button>
                </router-link>
                <router-link v-bind:to="'/node/log/' + id">
                    <a-button type="primary" class="lateral-button">Audit Log</a-button>
                </router-link>
            </a-form-item>
         </a-form>
    </a-spin>
</template>

<script>
import { routerPath } from "@/router";
import axios from 'axios';
import { authHeader } from '../../services/index';
import { config } from '@/config';
import _extends from 'babel-runtime/helpers/extends';
import { formLayout } from "@/components/common";
import debounce from 'lodash/debounce';
import { authService } from '@/services/auth';

export default {
    computed: {
        dataState() {
            return this.$store.state.data;
        },
        id() {
            return this.$route.params.id;
        },
        indexPath: () => routerPath.provNodinIndex,
        updateState() {
            return this.$store.state.data.success;
        },
        sowOpts () {
            if (!this.sowList) {
                return [];
            }
            return this.sowList.map(ea => {
                return {
                    value: ea.id,
                    label: ea.name,
                    key: ea.id,
                    title: ea.name,
                    disabled: false,
                };
            });
        },
        canEdit () {
            //return authService.canEdit();
            let info = authService.getAuthInfo();
            return info && info['user'] && info.user['role'] >= 5;
       }
    },
    mounted() {
        this.$root.$data.breadcrumbIds = [ this.id ];
        axios.get(`${config.backendUrl}/data/sow?limit=100&sort=name&search=`, {
            headers: authHeader()
        }).then(
            resp => {
                this.sowList = resp['data']['data'] || [];
                this.fetch("data/node");
            },
            error => {
                if (error.response && error.response.status === 401) {
                    //since we don't use axiosBackend, must handle this ourselves
                    store.dispatch('auth/logout', { 
                        redirectToLogin: true,
                        notification: {
                            message: 'Invalid or expired session', 
                            type: 'error'
                        }
                    });
                }
            }
        );
    },
    data() {
        //this.filterSow = debounce(this.filterSow, 500);
        return _extends({
            loading: false,
            sowList: [],
            apiPath: "data/node",
            form: this.$form.createForm(this), 
            name: null,
            number: null,
            submitting: false
        }, formLayout);
    },
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    watch: {
        dataState: {
            handler(v) {
                if (v.success && v.data) {
                    this.initDetail(v.data);
                } else {
                    this.loading = false;
                }
            },
            deep: true
        },
        updateState(key) {
            if (key !== null && this.submitting === true) {
                this.loading = false;
                if (this.$store.state.data.success === true) {
                    this.$router.push(this.indexPath);
                }
            }
        }
    },
    methods: {
        // filterSow (value) {
        //     axios.get(`${config.backendUrl}/data/sow?limit=100&sort=name&search=` + value, {
        //         headers: authHeader()
        //     }).then(response => {
        //         this.sowList = response['data']['data'] || [];
        //     });
        // },
        handleSubmit(e) {
            this.loading = true;
            e.preventDefault();
            this.form.validateFields(err => {
                if (!err) {
                    const path = this.apiPath;
                    const id = this.id;
                    const data = new FormData();
                    data.append("sow_id", this.form.getFieldValue('sow'));
                    data.append('po', this.form.getFieldValue('po'));
                    data.append('ne', this.form.getFieldValue('ne'));
                    this.submitting = true;
                    this.$store.dispatch("data/update", { path: path, id: id , data: data })
                } else {
                    this.loading = false;
                }
            });
        },
        initDetail(data) {
            this.form.setFieldsValue({
                po: data.po,
                ne: data.ne,
            });
            if (this.sowList && this.sowList.find(x => x.id = data.sow_id)) {
                this.form.setFieldsValue({
                    sow: data.sow_id
                });
            }
            this.loading = false;
        },
        fetch(path) {
            this.loading = true;
            const id = this.id;
            this.$store.dispatch("data/detail", { path, id });
        }
    }
};

</script>