var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Console", {
        ref: "console",
        attrs: {
          onConnectSuccess: _vm.onInitSuccess,
          onConnectFail: _vm.onInitFail,
          onConnectSuccessOrFail: _vm.onInitSuccessOrFail,
          onResize: _vm.onResize,
          onFullscreenOn: _vm.onFullscreenOn,
          onFullscreenOff: _vm.onFullscreenOff,
          onDisconnect: _vm.onDisconnect,
          currentRemotingId: _vm.remotingId,
          apiBasePath: _vm.apiBasePath
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }