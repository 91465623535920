<template>
    <div>
        <a-form-item label="Username" v-bind="filCommon">
            <a-input
                v-decorator="[
                  'username',
                  { rules: [{ required: true, message: 'Please input user username' }] }
                ]"
            ></a-input>
        </a-form-item>
        <div v-if="isCreateForm">
            <a-form-item label="Password" v-bind="filCommon">
                <a-input
                    v-decorator="[
                  'password',
                  { rules: [{ required: true, message: 'Please input user password' }] }
                ]"
                type="password"
                placeholder="password"
                ></a-input>
            </a-form-item>
        </div>
        <div v-else>
            <a-form-item label="New Password" v-bind="filCommon">
                <a-input v-decorator="[
                  'newPassword',
                  { rules: [{ validator: comparePasswords }] }
                ]"
                type="password"
                placeholder="password"
                ></a-input>
            </a-form-item>
            <a-form-item label="Password confirmation" v-bind="filCommon">
                <a-input v-decorator="[
                  'newPasswordConfirm',
                  { rules: [{ validator: comparePasswords }] }
                ]"
                type="password"
                placeholder="retype password"
                ></a-input>
            </a-form-item>
        </div>
        <a-form-item label="Fullname" v-bind="filCommon">
            <a-input
                v-decorator="[
                  'fullname',
                  { rules: [{ required: true, message: 'Please input user fullname' }] }
                ]"
            ></a-input>
        </a-form-item>
        <a-form-item label="Email" v-bind="filCommon">
            <a-input
                v-decorator="[
                  'email',
                  {
                    rules: [{ type: 'email', required: true, message: 'Please input valid email address' }]
                  }
                ]"
            ></a-input>
        </a-form-item>
        <a-form-item label="Role" v-bind="filCommon">
            <a-select
                v-decorator="[
                  'role',
                  { initialValue : 1 }
                ]"
            >
                <a-select-option v-for="role in roles" :key="role.value">{{role.label}}</a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item label="LDAP" v-bind="filCommon">
            <a-checkbox v-decorator="['ldap', {valuePropName: 'checked'}]" disabled/>
        </a-form-item>
        <a-form-item class="faux-label" v-bind="filCommon" label=" ">
            <a-button
                class="lateral-button"
                type="primary"
                html-type="button"
                v-on:click="$emit(submit, $event)"
                value="0"
            >Save</a-button>
            <router-link :to="indexPath">
                <a-button type="primary" class="lateral-button">Cancel</a-button>
            </router-link>
        </a-form-item>
    </div>
</template>

<script>
import { routerPath } from "@/router";
import _extends from 'babel-runtime/helpers/extends';
import { formLayout } from "@/components/common";

export default {
    props: {
        submit: {
            default: "handle-submit"
        },
        isCreateForm: {
            default: true
        },
        theForm: null
    },
    data() {
        return _extends({
            roles: [
                { value: 1, label: "Viewer" },
                /*{ value: 3, label: "Submitter" },*/
                { value: 5, label: "Executor" },
                { value: 7, label: "Admin" },
                { value: 15, label: "Superadmin" }
            ],
        }, formLayout);
    },
    methods: {
        comparePasswords(rule, value, callback) {
            if (rule && rule['field']) {
                let other = rule.field === 'newPassword' ?
                    'newPasswordConfirm' : 'newPassword';
                let otherVal = this.theForm.getFieldValue(other);
                if (value !== otherVal) {
                    callback('Inconsistent password');
                } else {
                    if (!(this.theForm.isFieldValidating('newPassword') 
                        && this.theForm.isFieldValidating('newPasswordConfirm'))) {
                        console.debug('recurse');
                        this.theForm.validateFields([other], { force: true }, callback);
                    }
                    callback();
                }
            } else {
                callback();
            }
        }
    },
    computed: {
        indexPath: () => routerPath.userIndex
    },
    watch: {}
};
</script>