<template>
    <a-spin :spinning="loading">
        <a-form :form="form">
            <Form 
                :theForm="form" 
                :isCreateForm="createForm" 
                @handle-submit="handleSubmit" 
                ref="enmForm"/>
        </a-form>
    </a-spin>
</template>

<script>
import Form from "./EnmForm";
import { routerPath } from "@/router";

export default {
    components: {
        Form
    },
    computed: {
        indexPath: () => routerPath.enmIndex,
        createState() {
            return this.$store.state.data.success;
        },
    },
    watch: {
        createState(key) {
            if (key !== null && this.submitting === true) {
                this.loading = false;
                if (this.$store.state.data.success === true) {
                    this.$router.push(this.indexPath);
                }
            }
        },
    },
    data() {
        return {
            apiPath: "data/enm",
            loading: false,
            createForm: true,
            submitting: false
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    methods: {
        handleSubmit(e) {
            this.loading = true;
            e.preventDefault();
            const enmId = this.id;
            const path = this.apiPath
            this.form.validateFields((err, values) => {
                if (!err) {
                    const data = new FormData();
                    data.append("id", enmId);
                    data.append("region_id", values.region);
                    data.append("name", values.name || '');
                    data.append("address", values.address || '');
                    data.append("port", values.port || '22');
                    data.append("base_dir", values.base_dir || '');
                    data.append("data_dir", values.data_dir || '');
                    data.append("manual_url", values.manual_url || '');
                    data.append("manual_context", values.manual_context || '');
                    this.submitting = true;
                    this.$store.dispatch("data/create", { path: path, data: data });
                } else {
                    this.loading = false;
                }
            });
        },
    }
};

</script>
