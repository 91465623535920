import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './modules/alert';
import { auth } from './modules/auth';
import { data } from './modules/data';
import { pgwbus } from './modules/pgwbus';
import VueApexCharts from 'vue-apexcharts'

Vue.use(Vuex);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)

export const store = new Vuex.Store({
  modules: {
    alert,
    auth,
    data,
    pgwbus
  }
});