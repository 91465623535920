import { render, staticRenderFns } from "./ActivityDetailLog.vue?vue&type=template&id=2c90635e&"
import script from "./ActivityDetailLog.vue?vue&type=script&lang=js&"
export * from "./ActivityDetailLog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/pgw/pgw/ericsson-provisioning/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2c90635e')) {
      api.createRecord('2c90635e', component.options)
    } else {
      api.reload('2c90635e', component.options)
    }
    module.hot.accept("./ActivityDetailLog.vue?vue&type=template&id=2c90635e&", function () {
      api.rerender('2c90635e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/activity/ActivityDetailLog.vue"
export default component.exports