<template>
    <div>
        <a-row :gutter="8">
            <a-tabs defaultActiveKey="0" @change="tabCallback">
                <a-tab-pane v-for="enm in enmList" :key="enm.key" :tab="enm.title"></a-tab-pane>
            </a-tabs>
        </a-row>
        <a-row>
            <a-button
                type="primary"
                icon="download"
                @click="downloadData"
            >Download {{currentEnm.title}} Data</a-button>
        </a-row>
        <a-row>
            <Index ref="index" v-bind="{ title, columns, actions }" />
        </a-row>
    </div>
</template>

<script>
import Index from "@/components/Index";
import axios from "axios";
import { config } from "@/config";
import { authHeader } from "@/services/index";
import contentDisposition from "content-disposition";

const columns = [
    {
        title: "Node Id",
        dataIndex: "nodeid",
        sorter: true,
        width: "7%"
    },
    {
        title: "Equipment Id",
        dataIndex: "equipmentid",
        sorter: true,
        width: "10%"
    },
    {
        title: "Field Replaceable Unit Id",
        dataIndex: "fieldreplaceableunitid",
        sorter: true,
        width: "10%"
    },
    {
        title: "Production Date",
        dataIndex: "productiondate",
        sorter: true,
        width: "7%"
    },
    {
        title: "Serial Number",
        dataIndex: "serialnumber",
        sorter: true,
        width: "10%"
    },
    {
        title: "Product Number",
        dataIndex: "productnumber",
        sorter: true,
        width: "10%"
    },
    {
        title: "Product Name",
        dataIndex: "productname",
        sorter: true,
        width: "10%"
    },
    {
        title: "Product Revision",
        dataIndex: "productrevision",
        sorter: true,
        width: "10%"
    }
    // {
    //     title: "Action",
    //     key: "action",
    //     width: "30%",
    //     scopedSlots: { customRender: "action" }
    // }
];

const actions = [
    // {
    //     text: "Open",
    //     route: "/activity/detail/", // we dont need route here, changing page will be done by edit method, which will be called by emit mechanism
    //     //this value is consistent with the value on template v-on: of Index component
    //     type: "default",
    //     size: "small",
    //     show: () => true
    // }
];

export default {
    name: "Dashboard",
    components: { Index },
    data() {
        return {
            enmList: [],
            currentEnm: {},
            apiPath: "data/sernumber",
            title: "Serial Number",
            columns,
            actions,
        };
    },
    methods: {
        downloadData() {
            var params = new FormData();
            params.append("enm", this.currentEnm.name);
            axios
                .post(
                    `${config.backendUrl}/data/sernumber/download`,
                    params,
                    {
                        responseType: "arraybuffer",
                        headers: authHeader()
                    }
                )
                .then(response => {
                    var ctype = response.headers["content-type"];
                    if (ctype === "application/octet-stream") {
                        var cdisp = contentDisposition.parse(
                            response.headers["content-disposition"]
                        );
                        saveAs(
                            new Blob([response.data], {
                                type: response.headers["content-type"]
                            }),
                            cdisp.parameters["filename"]
                        );
                    } else {
                        this.$store.dispatch(
                            "alert/error",
                            response.data.message
                        );
                    }
                });
        },
        tabCallback(key) {
            this.fetchEnmData(this.enmList[key]);
        },
        fetchEnmData(enmDetail) {
            var enmName = enmDetail.name;
            this.currentEnm = enmDetail;
            this.$eventbus.$emit("index-fetch", this.apiPath, { enm: enmName });
            this.$store.subscribe(mutation => {
                // listen to delete, refresh table when the deletion is done
                if (
                    mutation != null &&
                    mutation.type == "alert/success" &&
                    mutation.payload == "Data successfully deleted"
                ) {
                    this.$eventbus.$emit("index-fetch", this.apiPath, {
                        enm: enmName
                    });
                }
            });
        }
    },
    mounted() {
        axios
            .get(`${config.backendUrl}/data/enm/datadirs`, {
                headers: authHeader()
            })
            .then(response => {
                for (var j = 0; j < response.data.data.length; j++) {
                    this.enmList.push({
                        key: `${j}`,
                        name: response.data.data[j].enm,
                        title: response.data.data[j].enm.toUpperCase()
                    });
                }
                if (this.enmList.length > 0) {
                    this.fetchEnmData(this.enmList[0]);
                }
            });
    }
};
</script>
