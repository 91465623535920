var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Number" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["number"],
                expression: "[\n                 'number'\n             ]"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              disabled: !_vm.canEdit,
              min: 1,
              max: 2147483647,
              placeholder: "SoW Number"
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Name" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "name",
                  {
                    rules: [{ required: true, message: "SoW must have name" }]
                  }
                ],
                expression:
                  "[\n                 'name',\n                 {\n                     rules: [{ required: true, message: 'SoW must have name' }]\n                 }\n             ]"
              }
            ],
            attrs: { disabled: !_vm.canEdit, placeholder: "SoW Name" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: "Description" } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["explanation"],
                expression: "[\n                 'explanation'\n             ]"
              }
            ],
            attrs: { disabled: !_vm.canEdit, placeholder: "Description" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { staticClass: "faux-label", attrs: { label: " " } },
          "a-form-item",
          _vm.filCommon,
          false
        ),
        [
          _vm.canEdit
            ? _c(
                "a-button",
                {
                  staticClass: "lateral-button",
                  attrs: { type: "primary", "html-type": "submit" },
                  on: {
                    click: function($event) {
                      return _vm.$emit(_vm.submit, $event)
                    }
                  }
                },
                [_vm._v("Save")]
              )
            : _vm._e(),
          _c(
            "router-link",
            { attrs: { to: "/sow/index/" } },
            [
              _c(
                "a-button",
                { staticClass: "lateral-button", attrs: { type: "primary" } },
                [_vm._v(_vm._s(_vm.canEdit ? "Cancel" : "Back to Index"))]
              )
            ],
            1
          ),
          _vm.isCreateForm !== true
            ? _c(
                "router-link",
                { attrs: { to: "/sow/log/" + _vm.id } },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "lateral-button",
                      attrs: { type: "primary" }
                    },
                    [_vm._v("Audit Log")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }