<template>
    <a-layout id="components-layout-demo-custom-trigger">
        <a-layout-sider :trigger="null" collapsible v-model="collapsed">
            <div class="logo" id="nav-logo"></div>
            <div>
                <a-icon
                    style="font-size: 20px !important; width: 100%; text-align: center; vertical-align: middle; color: #fff"
                    class="trigger"
                    :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                    @click="()=> collapsed = !collapsed"
                />
            </div>
            <a-menu theme="dark" mode="inline" :defaultSelectedKeys="['1']">
                <a-menu-item key="1">
                    <router-link to="/">
                        <a-icon type="home"/>
                        <span>Home</span>
                    </router-link>
                </a-menu-item>
                <a-sub-menu>
                    <span slot="title">
                        <a-icon type="database"/>
                        <span>Master Data</span>
                    </span>
                    <a-menu-item key="2">
                        <router-link to="/region/index">
                            <a-icon type="table"/>
                            <span>Region</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="4">
                        <router-link to="/site/index">
                            <a-icon type="table"/>
                            <span>Site</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="6">
                        <router-link to="/enm/index">
                            <a-icon type="table"/>
                            <span>ENM</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="7">
                        <router-link to="/sow/index">
                            <a-icon type="table"/>
                            <span>SoW Map</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="8">
                        <router-link to="/activity/index">
                            <a-icon type="table"/>
                            <span>Activity</span>
                        </router-link>
                    </a-menu-item>
                </a-sub-menu>
                <a-sub-menu>
                    <span slot="title">
                        <a-icon type="file-sync"/>
                        <span>Provisioning</span>
                    </span>
                    <a-menu-item>
                        <router-link to="/provisioning/nodin">
                            <a-icon type="table"/>
                            <span>Pending Nodin</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item>
                        <router-link to="/provisioning/crq">
                            <a-icon type="table"/>
                            <span>Pending CRQ</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item>
                        <router-link to="/provisioning/enm">
                            <a-icon type="table"/>
                            <span>Pending ENM</span>
                        </router-link>
                    </a-menu-item>
                </a-sub-menu>
                <a-sub-menu>
                    <span slot="title">
                        <a-icon type="desktop" />
                        <span>Dashboard</span>
                    </span>
                    <a-menu-item>
                        <router-link to="/dashboard/inventory">
                            <a-icon type="line-chart" />
                            <span>Inventory</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item>
                        <router-link to="/dashboard/license">
                            <a-icon type="pie-chart" />
                            <span>License</span>
                        </router-link>
                    </a-menu-item> 
                    <a-menu-item>
                        <router-link to="/dashboard/power">
                            <a-icon type="thunderbolt" />
                            <span>Power</span>
                        </router-link>
                    </a-menu-item> 
                    <a-menu-item>
                        <router-link to="/dashboard/serial_number">
                            <a-icon type="table" />
                            <span>Serial Number</span>
                        </router-link>
                    </a-menu-item> 
                </a-sub-menu>
                <a-menu-item v-if="authInfo && authInfo.role >= 5">
                    <router-link to="/console/pgw">
                        <a-icon type="rocket"/>
                        <span>PGW Console</span>
                    </router-link>
                </a-menu-item>
                <a-sub-menu v-if="authInfo && authInfo.role === 15">
                    <span slot="title">
                        <a-icon type="lock"/>
                        <span>Administration</span>
                    </span>
                    <a-menu-item>
                        <router-link to="/user/index">
                            <a-icon type="user"/>
                            <span>Users</span>
                        </router-link>
                    </a-menu-item>
                </a-sub-menu>
            </a-menu>
        </a-layout-sider>
        <a-layout>
            <a-layout-header style="background: #fff; padding: 0">
                <a-col :span="12">
                    <breadcrumbs />
                </a-col>
                <a-col :span="12">
                    <router-link to="/logout">
                        <a-button type="primary" icon="logout" id="logout"><span style="font-size: .9em">Logout</span></a-button>
                    </router-link>
                    <span style="float: right; margin-right: 10px; font-size: .9em; cursor: help" :title="'Role ' + (authInfo && authInfo.rolename ? authInfo.rolename : '')">
                        Logged in as <strong>{{(authInfo && authInfo.fullname ? authInfo.fullname : '')}}</strong>
                    </span>
                </a-col>
            </a-layout-header>

            <a-layout-content>
                <div
                    :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }"
                >
                    <router-view></router-view>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<style>
#logout {
    float: right;
    margin-top: 15px;
    margin-right: 16px;
}
</style>


<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
    name: "main-layout",
    components: {
        Breadcrumbs
    },
    computed: {
        authInfo() {
            const authInfo = this.$store.getters["auth/info"];
            return authInfo ? authInfo.user : null;
        }
    },
    data() {
        return {
            collapsed: false,
            routes: []
        };
    },
    methods: {
        toggleCollapsed() {
            this.collapsed = !this.collapsed;
        }
    }
};
</script>
