<template>
    <a-spin :spinning="loading" :tip="spinTip">
        <div class="steps-action">
            <h4 v-if="running">Currently executing NE "{{ currentNe }}" in ENM "{{ currentEnm }}" from Nodin# "{{ currentNodin }}"</h4>
            <a-button 
                v-if="currentStep>0" 
                :disabled="currentStep===1" 
                @click="prev" 
                style="margin: 0 8px 8px 0">
                Prev
            </a-button>
            <a-button
                v-if="currentStep > 0 && currentStep < steps.length - 1"
                type="primary"
                @click="next"
                style="margin: 0 8px 8px 0">
                Next
            </a-button>
            <a-button
                v-if="currentStep === steps.length - 1"
                type="primary"
                @click="$store.dispatch('alert/success', 'Processing complete!')"
            >Done</a-button>
        </div>
        <a-steps :current="currentStep" :stepType="stepType" size="small">
            <a-step 
                v-for="step in steps" 
                :key="step.index" 
                :data-key="step.index" 
                @click="jump(step.index, true)">
                <template slot="title">
                    <a-tooltip placement="bottom">
                        <template slot="title">{{ step.desc }}</template>
                        {{ step.title }}
                    </a-tooltip>
                </template>
            </a-step>
        </a-steps>
        <div class="steps-content">
            <div v-if="currentStep===0">
                <a-form :form="stepZero.form">
                    <a-form-item label="Area" v-bind="filCommon">
                        <a-select
                            :disabled="stepZero.regionDisabled"
                            showSearch
                            optionFilterProp="area"
                            :value="stepZero.currentRegion"
                            :defaultActiveFirstOption="false"
                            :filterOption="false"
                            @change="handleRegionChange"
                            :notFoundContent="null">
                            <a-select-option v-for="region in stepZero.regions" :key="region.id">{{region.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="ENM" v-bind="filCommon">
                        <a-select
                            :disabled="stepZero.enmDisabled"
                            showSearch
                            :defaultActiveFirstOption="false"
                            :filterOption="false"
                            @search="handleEnmSearch"
                            :notFoundContent="null"
                            v-decorator="[
                                'currentEnm',
                                { rules: [{ required: true, message: 'ENM is required' }] }
                            ]"
                        >
                            <a-select-option v-for="ea in stepZero.enmFiltered" :key="ea.id">{{ea.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="Username" v-bind="filCommon">
                        <a-input
                            :disabled="stepZero.userDisabled"
                            v-decorator="[
                            'remoteUser',
                            { rules: [{ required: true, message: 'Username is required' }] }
                            ]"
                        ></a-input>
                        </a-form-item>
                    <a-form-item label="Password" v-bind="filCommon">
                        <a-input
                            :disabled="stepZero.passDisabled"
                            :placeholder="stepZero.passPlaceholder"
                             @pressEnter="validateRemoting"
                            type="password"
                            v-decorator="[
                            'remotePass',
                            { rules: [{ required: !stepZero.passDisabled, message: 'Password is required' }] }
                            ]"
                        ></a-input>
                    </a-form-item>
                    <a-form-item :colon="false" label=" " v-bind="filCommon">
                        <a-button type="primary" @click="validateRemoting">{{ stepZero.startLabel }}</a-button>
                    </a-form-item>
                </a-form>
            </div>
            <div v-else-if="currentStep===steps.length-1">
                <div style="margin: 10px">
                    <a-radio-group v-model="summaryUseViewer" buttonStyle="solid" style="float: right">
                        <a-radio-button :value="false">Raw</a-radio-button>
                        <a-radio-button :value="true">Viewer</a-radio-button>
                    </a-radio-group>
                    <h2>Summary</h2>
                    <a-collapse v-model="summaryKey">
                        <a-collapse-panel v-for="content in summaryContent" 
                            :key="('' + content.key)" :header="((1 + content.key) + '. ' + content.name)">
                            <span v-if="content.key===0">
                                <a-form id="enm-summary">
                                    <a-form-item v-for="row in content.content" :key="row.key" 
                                        v-bind="filCommon" :label="row.name">
                                        {{ row.value }}
                                    </a-form-item>
                                </a-form>
                            </span>
                            <span v-else-if="content.type==='Manual'">
                                <strong>Files:</strong>
                                <a-table
                                    class="manual-upload-list"
                                    size="small"
                                    :columns="stepManualBase.columns"
                                    :locale="stepManualBase.locale"
                                    :showHeader="false"
                                    :rowKey="row => row.uid"
                                    :dataSource="content.contentData"
                                    :pagination="false"
                                    :loading="stepManualBase.manualLoading"
                                    :key="stepManualBase.listKey"
                                    >
                                        <template slot="action" slot-scope="record">
                                            <a-row type="flex" justify="start" :gutter="8">
                                                <a-col>
                                                    <a-button size="small" type="default" title="Preview" icon="eye" @click="onManualFileClick(record)" />
                                                </a-col>
                                                <a-col>
                                                    <a-button size="small" type="default" title="Download" icon="download" @click="onManualFileDownload(record)" />
                                                </a-col>
                                            </a-row>
                                        </template>
                                </a-table>
                                <a-modal :visible="stepManualBase.preview.on" :footer="null" @cancel="onManualFileCancel" width="80%">
                                    <img style="width: 100%" 
                                        :alt="stepManualBase.preview.alt" 
                                        :download="stepManualBase.preview.name" 
                                        :src="stepManualBase.preview.src" />
                                </a-modal>
                                <br>
                                <strong>Logs:</strong>
                                <json-viewer v-if="summaryUseViewer===true"
                                    :value="content.content"
                                    :expand-depth=0
                                    >
                                </json-viewer>
                                <div v-else v-html="content.contentHtml"></div>
                            </span>
                            <json-viewer v-else-if="summaryUseViewer===true"
                                :value="content.content"
                                :expand-depth=2
                                >
                            </json-viewer>
                            <div v-else v-html="content.contentHtml"></div>
                        </a-collapse-panel>
                    </a-collapse>
                </div>
                <a-back-top />
            </div>
            <div v-else>
                <div v-if="stepType==='Script' || stepType==='Exec' || stepType==='Verify'">
                    <Fullscreen id="script-container" ref="scriptFs">
                        <a-form :form="stepScripts[currentStep].form">
                            <a-menu id="script-menu" ref="scriptMenu"
                                v-if="stepType==='Script' || stepType==='Exec'"
                                mode="horizontal"
                                :selectable="false"
                                :forceSubMenuRender="true"
                                :getPopupContainer="getScriptContainer"
                                @click="handleScriptMenu">
                                <a-sub-menu v-if="stepType==='Script'">
                                    <span slot="title">File</span>
                                    <a-menu-item key="script-load">
                                        Load from file
                                    </a-menu-item>
                                    <a-menu-item key="script-save">
                                        Save to file
                                    </a-menu-item>
                                    <a-divider />
                                    <a-menu-item key="script-cache" :disabled="!stepScripts[currentStep].modified">
                                        Save to cache
                                    </a-menu-item>
                                    <a-menu-item key="script-revert">
                                        Revert to cached contents
                                    </a-menu-item>
                                    <a-menu-item key="script-reset">
                                        Reset to default
                                    </a-menu-item>
                                </a-sub-menu>
                                <a-sub-menu v-if="stepType==='Script'">
                                    <span slot="title">View</span>
                                    <a-sub-menu>
                                        <span slot="title">Language</span>
                                        <a-menu-item v-for="ea in stepScriptCommon.availableLangs" :key="'script-lang-' + ea.key">
                                            <a-icon type="check" :id="'script-lang-sel-' + ea.key"
                                                class="script-lang-sel"
                                                :style="langMark(ea.key)" />
                                            <span>{{ ea.text }}</span>
                                        </a-menu-item>
                                    </a-sub-menu>
                                    <a-sub-menu>
                                        <span slot="title">Theme</span>
                                        <a-menu-item v-for="ea in stepScriptCommon.availableThemes" :key="'script-theme-' + ea.key">
                                            <a-icon type="check" :id="'script-theme-sel-' + ea.key"
                                                class="script-theme-sel"
                                                :style="themeMark(ea.key)" />
                                            <span>{{ ea.text }}</span>
                                        </a-menu-item>
                                    </a-sub-menu>
                                </a-sub-menu>
                                <a-sub-menu>
                                    <span slot="title">Command</span>
                                    <a-menu-item key="script-placeholder-in" v-if="stepType==='Script'">
                                        Add input placeholder
                                    </a-menu-item>
                                    <a-menu-item key="script-placeholder-out-file">
                                        Add output placeholder (single file)
                                    </a-menu-item>
                                    <a-menu-item key="script-placeholder-out-dir">
                                        Add output placeholder (folder)
                                    </a-menu-item>
                                </a-sub-menu>
                                <a-sub-menu>
                                    <span slot="title">Action</span>
                                    <a-menu-item key="script-upload" v-if="stepType==='Script'">
                                        Upload only
                                    </a-menu-item>
                                    <a-menu-item key="script-exec" v-if="stepType==='Script'">
                                        Upload &amp; Execute
                                    </a-menu-item>
                                    <a-menu-item key="script-cmd" v-if="stepType==='Exec'">
                                        Execute Command
                                    </a-menu-item>
                                    <a-menu-item key="script-download">
                                        Download output file
                                    </a-menu-item>
                                </a-sub-menu>
                                <a-menu-item key="script-fs" v-if="stepType==='Script'">Toggle Fullscreen</a-menu-item>
                                <a-menu-item key="script-help">Help</a-menu-item>
                            </a-menu>
                            <a-form-item label="" 
                                v-if="stepType==='Script' || stepType==='Exec'"
                                v-bind="filNoLabelInModal" style="margin: 6px;">
                                <a-tooltip :title="stepScripts[currentStep].ulTooltip" v-if="stepType==='Script'">
                                    <a-icon class="script-status-icon" type="minus-square"
                                        v-if="stepScripts[currentStep].uploadStatus===null" style="color: transparent" />
                                    <a-icon class="script-status-icon" type="sync" spin
                                        v-else-if="stepScripts[currentStep].uploadStatus===0" />
                                    <a-icon class="script-status-icon" type="file-add"
                                        v-else-if="stepScripts[currentStep].uploadStatus===1" 
                                        theme="twoTone" twoToneColor="#52c41a" />
                                    <a-icon class="script-status-icon" type="close-circle"
                                        v-else-if="stepScripts[currentStep].uploadStatus===-1" 
                                        theme="twoTone" twoToneColor="#eb2f96" />
                                </a-tooltip>
                                <a-tooltip :title="stepScripts[currentStep].execTooltip">
                                    <a-icon class="script-status-icon" type="minus-square"
                                        v-if="stepScripts[currentStep].execStatus===null" style="color: transparent" />
                                    <a-icon class="script-status-icon" type="sync" spin
                                        v-else-if="stepScripts[currentStep].execStatus===0" />
                                    <a-icon class="script-status-icon" type="check-circle"
                                        v-else-if="stepScripts[currentStep].execStatus===1" 
                                        theme="twoTone" twoToneColor="#52c41a" />
                                    <a-icon class="script-status-icon" type="close-circle"
                                        v-else-if="stepScripts[currentStep].execStatus===-1" 
                                        theme="twoTone" twoToneColor="#eb2f96" />
                                </a-tooltip>
                                <label style="margin-left: 2px;">{{scriptStatus}}</label>
                            </a-form-item>
                            <a-form-item v-if="stepType==='Script' || stepType==='Exec'" 
                                label="" v-bind="filNoLabelInModal" style="margin: 6px;">
                                <a-input size="large"
                                    class="mono"
                                    placeholder="Enter command string to execute"
                                    v-decorator="[ 'script-command' ]">
                                </a-input>
                            </a-form-item>
                            <a-form-item v-if="stepType==='Verify'" 
                                label="" v-bind="filNoLabelInModal" style="margin: 6px;">
                                <div>Will execute command: </div>
                                <span class="mono">mobatch </span>
                                <a-input size="large"
                                    class="mono"
                                    style="width: 50%"
                                    v-decorator="[ 
                                        'script-verify',
                                        { rules: [{ required: true, message: 'Cannot be blank' }] }
                                    ]">
                                </a-input>
                                <span class="mono"> &lt;moscript&gt; ${OUTDIR}</span>
                            </a-form-item>
                            <a-form-item v-if="stepType==='Verify'" 
                                label="" v-bind="filNoLabelInModal" style="margin: 6px;">
                                <a-button 
                                    type="primary" 
                                    @click="executeVerify"
                                    :disabled="stepVerify.disabled"
                                    :loading="stepVerify.onProgress">
                                    {{ stepVerify.label }}
                                </a-button>
                            </a-form-item>
                            <prism-editor
                                v-if="stepType==='Script'"
                                :key="stepScripts[currentStep].tracker"
                                @change="handleScriptChange"
                                v-model="stepScripts[currentStep].currentScript"
                                :language="stepScripts[currentStep].lang"
                                lineNumbers
                            />
                            <a-upload style="display: none"
                                :multiple="false" 
                                :beforeUpload="handleScriptLoader">
                                <a-button id="script-loader">.</a-button>
                            </a-upload>
                            <a-modal v-if="stepType!=='Verify'" 
                                v-model="stepScripts[currentStep].modalShown" 
                                :getContainer="getScriptContainer" 
                                :title="stepScripts[currentStep].modalTitle" 
                                @ok="initUlScript" 
                                @cancel="cancelUlScript">
                                <div id="ul-script-form">
                                    <a-form-item label="Remote filename" v-bind="filInModal">
                                        <a-input placeholder="Leave blank to let system auto-generate the filename on remote host" 
                                            v-decorator="[ 'script-filename' ]">
                                        </a-input>
                                    </a-form-item>
                                </div>
                            </a-modal>
                            <a-modal v-else 
                                v-model="stepScripts[currentStep].modalShown" 
                                :getContainer="getScriptContainer" 
                                :title="stepScripts[currentStep].modalTitle">
                                <template slot="footer">
                                    <a-button v-if="stepVerify.needJustification===true" type="primary" @click="handleVerifyFail">Submit</a-button>
                                    <a-button v-else type="primary" @click="handleVerifySuccess">OK</a-button>
                                </template>
                                <div id="verify-script-form" v-if="stepVerify.needJustification===true">
                                    <a-form-item label="NE ID Actual" v-bind="filInModal">
                                        <a-input placeholder="NE ID being executed" 
                                            v-decorator="[ 'script-ne-actual' ]">
                                        </a-input>
                                    </a-form-item>
                                    <a-form-item label="Reason" v-bind="filInModal">
                                        <a-textarea placeholder="Explanation for NE ID discrepancy between CRQ/Nodin and execution" 
                                            style="margin-top: 5px"
                                            v-decorator="[ 
                                                'script-ne-reason',
                                                { rules: [{ required: true, message: 'Reason must be stated' }] }
                                            ]">
                                        </a-textarea>
                                    </a-form-item>
                                </div>
                                <div id="verify-script-result" v-else>
                                    {{ stepVerify.message }}
                                </div>
                            </a-modal>
                            <a-modal v-model="stepScripts[currentStep].helpModalShown" :getContainer="getScriptContainer" title="Help" @ok="closeScriptHelp">
                                <template slot="footer">
                                    <a-button @click="closeScriptHelp">Got it</a-button>
                                </template>
                                <div>
                                    <h3>How to SUEx</h3>
                                    <ol>
                                        <li>Ensure an ENM has been selected in previous step</li>
                                        <li>Type (or copy-paste, sure) the script in the editor. You may also load it from your local filesystem via menu File → Load from File</li>
                                    </ol>
                                </div>
                            </a-modal>
                        </a-form>
                    </Fullscreen>
                </div>
                <div v-else-if="stepType=='Console'">
                    <Console
                        ref="console"
                        :onResize="consoleOnResize"
                        :onConnectSuccess="consoleOnConnect"
                        :onFullscreenOn="consoleFullscreenOn"
                        :onFullscreenOff="consoleFullscreenOff"
                        :apiBasePath="stepZero.apiBasePath"
                        :currentRemotingId="stepZero.remotingId"
                        :activityId="stepConsoles[currentStep]['activityId']"
                    />
                </div>
                <!-- <div v-else-if="stepType=='Manual'">Manual</div> -->
                <div v-else-if="stepType=='Manual'" :id="'manual' + currentStep" style="margin: 6px">
                    <h3>Manual Execution</h3>
                    <ol>
                        <li>Create <b>{{manualContext}}</b> in ENM, please go to <a target="_new" :href="manualUrl">{{manualUrl}}</a></li>
                        <li>Upload capture file <b>{{manualContext}}</b></li>
                    </ol>
                    <a-spin :spinning="stepManuals[currentStep].manualLoading">
                        <a-form :form="stepManuals[currentStep].form">
                            <a-form-item v-bind="filNoLabelInModal">
                                <a-upload
                                    v-decorator="[ 'manual-upload' ]"
                                    :showUploadList="false"
                                    :headers="getAuthHeader"
                                    :action="manualUploadPath"
                                    @change="onManualUploadChanged"
                                    :accept="stepManuals[currentStep].accept"
                                    :defaultFileList="stepManuals[currentStep].uploads"
                                    >
                                    <a-button>
                                        <a-icon type="upload" />
                                        Select Files...
                                    </a-button>
                                </a-upload>
                            </a-form-item>
                        </a-form>
                    </a-spin>
                    <a-table
                        class="manual-upload-list"
                        size="small"
                        :columns="stepManuals[currentStep].columns"
                        :locale="stepManualBase.locale"
                        :showHeader="false"
                        :rowKey="row => row.uid"
                        :dataSource="stepManuals[currentStep].uploads"
                        :pagination="false"
                        :loading="stepManuals[currentStep].manualLoading"
                        :key="stepManuals[currentStep].listKey"
                        >
                            <template slot="action" slot-scope="record">
                                <a-row type="flex" justify="start" :gutter="8">
                                    <a-col>
                                        <a-button size="small" type="default" title="Preview" icon="eye" @click="onManualFileClick(record)" />
                                    </a-col>
                                    <a-col>
                                        <a-button size="small" type="default" title="Download" icon="download" @click="onManualFileDownload(record)" />
                                    </a-col>
                                    <a-col>
                                        <a-button size="small" type="danger" title="Remove" icon="delete" @click="onManualFileRemoval(record)" />
                                    </a-col>
                                </a-row>
                            </template>
                    </a-table>
                    <a-modal :visible="stepManuals[currentStep].preview.on" :footer="null" @cancel="onManualFileCancel" width="80%">
                        <img style="width: 100%" 
                            :alt="stepManuals[currentStep].preview.alt" 
                            :download="stepManuals[currentStep].preview.name" 
                            :src="stepManuals[currentStep].preview.src" />
                    </a-modal>
                </div>
                <div v-else></div>
            </div>
        </div>
    </a-spin>
</template>
<script>
import "prismjs";
import "prismjs/components/prism-bash";
import "vue-prism-editor/dist/VuePrismEditor.css";
// import "prismjs/themes/prism.css";
// import "prismjs/themes/prism-tomorrow.css";  //racist. or colorist, whatever. let em pick on their own!
import PrismEditor from "vue-prism-editor";
import axios from "axios";
import { authHeader } from "@/services/index";
import { config } from "@/config";
import { store } from '@/store';
import Console from "@/components/Console";
import Fullscreen from 'vue-fullscreen/src/component'
import _extends from 'babel-runtime/helpers/extends';
import { formLayout } from "@/components/common";
import "@/assets/css/pgw.css";
const NodeRSA = require('node-rsa');

var prettyHtml = require('json-pretty-html').default;
import JsonViewer from 'vue-json-viewer'

export default {
    components: {
        PrismEditor,
        Console,
        Fullscreen,
        JsonViewer
    },
    data() {
        var theData = {
            loading: false,
            spinTip: null,
            stepZero: {
                enms: [],
                enmFiltered: [],
                regions: [],
                currentRegion: null,
                encryptPassword: false,
                form: this.$form.createForm(this),
                lastEnm: null,
                lastUser: null,
                apiBasePath: '/remoting/enm',
                remotingKey: null,
                remotingId: null,
                regionDisabled: false,
                enmDisabled: false,
                userDisabled: false,
                passDisabled: false,
                passPlaceholder: '',
                startLabel: 'Start',
                resumeAt: 0,
                maxAt: 0
            },
            stepScripts: [],
            stepScriptBase: {
                form: null,
                loadFrom: null,
                modified: null,
                tracker: 0,
                modalShown: false,
                modalTitle: '',
                uploadOnly: true,
                helpModalShown: false,
                uploadStatus: null,
                ulTooltip: 'Upload status',
                execStatus: null,
                execTooltip: 'Execution status',
                dlId: null,
                dlResult: null,
                defaultScript: null,
                cachedScript: null,
                currentScript: null,
                suexStatus: null,
                currentStatus: '',
                lang: null,
                theme: null,
                activityId: null
            },
            stepScriptCommon: {
                availableLangs: [
                    { key: 'shell', text: 'Shell' },
                    { key: 'xml', text: 'XML' }
                ],
                availableThemes: [
                    { key: 'coy', text: 'Light' },
                    { key: 'solarizedlight', text: 'Solarized' },
                    { key: 'tomorrow', text: 'Dark' }
                ],
            },
            stepConsoles: [],
            stepConsoleBase: {
                fullscreen: null,
                activityId: null
            },
            stepVerify: {
                disabled: false,
                onProgress: false,
                label: 'Execute',
                needJustification: false,
                message: null
            },
            stepManuals: [],
            stepManualBase: {
                form: null,
                uploads: [],
                activityId: null,
                manualLoading: false,
                listKey: 0,
                accept: 'image/*',
                preview: {
                    on: false,
                    alt: '',
                    name: '',
                    src: ''
                },
                columns: [
                    {
                        key: "_action",
                        width: "125px",
                        scopedSlots: { customRender: "action" }
                    },
                    {
                        dataIndex: 'name',
                        sorter: false,
                    }
                ],
                locale: {
                    emptyText: 'No files'
                }
            },
            currentStep: 0,
            stepType: "",
            steps: [],
            sowList: [],
            sowSource: [],
            sowid: null,
            summaryKey: [],
            summaryData: [],
            summaryUseViewer: true,
            running: false,
            currentNe: null,
            currentEnm: null,
            currentNodin: null,
        };
        return _extends(theData, formLayout);
    },
    beforeCreate() {
    },
    mounted() {
        this.handleEnmSearch("");
        //this.preInit(this.$route.params.id);
        this.steps[0] = { index: 0, title: "Choose ENM", type: "Start", desc: "Set Credentials" };
        let proxy = this;
        axios
            .get(
                `${config.backendUrl}/data/activity/bynode/${
                    this.$route.params.id
                }`,
                {
                    headers: authHeader()
                }
            )
            .then(response => {
                proxy.sowList = response.data.data;
                proxy.sowSource = proxy.sowList.map(x => x.id);
                var data = response.data.data;
                proxy.stepConsoles.push({});
                proxy.stepScripts.push({});
                proxy.stepManuals.push({});
                for (var i = 0; i < data.length; i++) {
                    let theStep = {
                        id: data[i].id,
                        //index: data[i].number,
                        index: 1 + i,
                        title: data[i].name,
                        desc: data[i].keterangan,
                        type: data[i].jenis
                    };
                    proxy.steps.push(theStep);
                    let consoleStep = {};
                    let scriptStep = {};
                    let manualStep = {};
                    if (theStep.type === 'Console') {
                        consoleStep = _extends({}, proxy.stepConsoleBase)
                    } else if (theStep.type === 'Script' || theStep.type === 'Exec' || theStep.type === 'Verify') {
                        scriptStep = _extends({}, proxy.stepScriptBase)
                        scriptStep.form = proxy.$form.createForm(proxy);
                        if (theStep.type === 'Script') {
                            scriptStep.defaultScript = data[i]['default_script'] || '';  //TODO
                            scriptStep.cachedScript = data[i]['default_script'] || '';  //TODO
                            scriptStep.currentScript = data[i]['default_script'] || '';  //TODO
                        }
                        if (theStep.type !== 'Verify') {
                            scriptStep.suexStatus = '-';
                        }
                    } else if (theStep.type === 'Manual') {
                        manualStep = _extends({}, proxy.stepManualBase)
                        manualStep.form = proxy.$form.createForm(proxy);
                    }
                    consoleStep.activityId = data[i].id;
                    proxy.stepConsoles.push(consoleStep);
                    scriptStep.activityId = data[i].id;
                    proxy.stepScripts.push(scriptStep);
                    manualStep.activityId = data[i].id;
                    proxy.stepManuals.push(manualStep);
                }
                proxy.steps.push({
                    index: proxy.steps.length,
                    title: "Done",
                    desc: "View Summary",
                    type: "End"
                });
            });
        this.stepType = this.steps[this.currentStep].type;

        axios.get(`${config.backendUrl}/data/region?search=`, {
                headers: authHeader()
            })
            .then(response => {
                proxy.stepZero.regions = response.data.data;
            });
    },
    methods: {
        preInit (nodeId) {
            if (!nodeId || (this.stepZero.remotingKey && this.stepZero.remotingId))
                return;
            const options = {
                baseURL: config.backendUrl,
                timeout: 30000,
                method: 'GET',
                headers: authHeader(),
                url: this.stepZero.apiBasePath + '/preinit?node=' + nodeId
            };
            let proxy = this;
            axios(options).then(
                data => {
                    // if (data.status === 206) {
                    //     //backend is configured not to (or isn't capable to) encrypt password in-flight
                    // }
                    proxy.stepZero.encryptPassword = data.status === 200;
                    proxy.stepZero.remotingKey = data.data['key'];
                    proxy.stepZero.remotingId = data.data.rid;
                    proxy.stepZero.regionDisabled = data.data.region && true;
                    if (proxy.stepZero.regionDisabled === true) {
                        proxy.stepZero.currentRegion = data.data.region;
                        proxy.stepZero.enmFiltered = proxy.stepZero.enms.filter(x => x.region_id ===  proxy.stepZero.currentRegion);
                    }
                    proxy.stepZero.enmDisabled = data.data.enm && true;
                    if (proxy.stepZero.enmDisabled === true) {
                        let theEnm = proxy.stepZero.enms.find(x => x.id === data.data.enm);
                        proxy.stepZero.form.setFieldsValue({
                            'currentEnm': theEnm ? theEnm.id : null
                        });
                    }
                    proxy.stepZero.userDisabled = data.data.username && true;
                    if (proxy.stepZero.userDisabled === true) {
                        proxy.stepZero.form.setFieldsValue({
                            'remoteUser': data.data.username
                        });
                    }
                    proxy.stepZero.passDisabled = false;
                    // proxy.stepZero.passDisabled = data.data.password === true;
                    // if (proxy.stepZero.passDisabled === true) {
                    //     proxy.stepZero.passPlaceholder = '<Already provided>';
                    // }
                    if (data.data.currentStep && data.data.currentStep > 0) {
                        proxy.stepZero.startLabel = 'Resume';
                        proxy.stepZero.resumeAt = data.data.currentStep;
                        proxy.stepZero.maxAt = data.data.maxStep;
                    }
                    proxy.currentNe = data.data['ne'] || '?';
                    proxy.currentEnm = (proxy.stepZero.enms.find(x => x.id === proxy.stepZero.form.getFieldValue('currentEnm')) || {})['name'] || '??';
                    proxy.currentNodin = data.data['nodin'] || '???';
                },
                error => {
                    proxy.stepZero.encryptPassword = false;
                    //console.debug('pre-init fail: ' + error);
                    if (error.response) {
                        switch (error.response.status) {
                            case 400:
                            case 403:
                                proxy.$error({
                                    title: "Error occurred",
                                    content: error.response.data || 'Invalid parameter'
                                });
                                break;
                            case 401:
                                //since we don't use axiosBackend, must handle this ourselves
                                store.dispatch('auth/logout', { 
                                    redirectToLogin: true,
                                    notification: {
                                        message: 'Invalid or expired session', 
                                        type: 'error'
                                    }
                                });
                                break;
                            default:
                                proxy.$error({
                                    title: "Error occurred",
                                    content: error.response.data || 'Failed to initiate session'
                                });
                                break;
                        }
                    } else {
                        proxy.$error({
                            title: "Error occurred",
                            content: 'Failed to initiate session'
                        });
                    }
                });
        },
        validateRemoting (e) {
            let proxy = this;
            this.stepZero.form.validateFields((err, values) => {
                if (!err) {
                    proxy.initRemoting();
                }
            });
        },
        initRemoting () {
            let proxy = this;
            let initHeaders = authHeader();
            initHeaders['Content-Type'] = 'multipart/form-data';
            const formData = new FormData();
            formData.append('username', this.stepZero.form.getFieldValue('remoteUser') || '');
            formData.append('password', this.passwordToSend);
            formData.append('remoteId', this.stepZero.form.getFieldValue('currentEnm') || '');
            let url = this.stepZero.apiBasePath + '/init?rid=' + this.stepZero.remotingId;
            const options = {
                baseURL: config.backendUrl,
                timeout: 30000,
                method: 'POST',
                headers: initHeaders,
                data: formData,
                url: url
            };
            axios(options).then(
                data => {
                    proxy.stepZero.remotingId = data.data.rid;
                    proxy.stepZero.lastEnm = proxy.stepZero.form.getFieldValue('currentEnm');
                    proxy.stepZero.lastUser = proxy.stepZero.form.getFieldValue('remoteUser');
                    proxy.running = true;
                    proxy.updateJumpable();
                    if (proxy.stepZero.resumeAt && proxy.stepZero.resumeAt > 0)
                        proxy.jump(proxy.stepZero.resumeAt, false);
                    else
                        proxy.next();
                },
                error => {
                    //console.debug('pre-init fail: ' + error);
                    if (error.response) {
                        switch (error.response.status) {
                            case 401:
                                //since we don't use axiosBackend, must handle this ourselves
                                store.dispatch('auth/logout', { 
                                    redirectToLogin: true,
                                    notification: {
                                        message: 'Invalid or expired session', 
                                        type: 'error'
                                    }
                                });
                                break;
                            case 406:
                                proxy.$error({
                                    title: "Error occurred",
                                    content: error.response.data || 'Failed to establish session'
                                });
                                break;
                        }
                    } else {
                        proxy.$error({
                            title: "Error occurred",
                            content: 'Failed to establish session'
                        });
                    }
                });
        },
        jump(to, report) {
            if (!to || to < 1 || to > this.steps.length - 1) {
                return;
            }
            if (report === true && (this.currentStep === 0 || to < 1 || to > this.stepZero.maxAt)) {
                return;
            }
            this.currentStep = to;
            // if (this.stepType === 'Console')
            //     this.$refs.console.resetTerminal(false, true);
            if (this.stepType === 'Console' && this.steps[this.currentStep].type === 'Console') {
                this.stepType = 'None';
                let proxy = this;
                setTimeout(() => {
                    proxy.stepType = proxy.steps[proxy.currentStep].type;
                    proxy.moveStuff('#console-container');
                }, 100);
                return;
            }
            this.stepType = this.steps[this.currentStep].type;
            if (this.stepType === 'Console')
                this.moveStuff('#console-container'); 
            else if (this.stepType === 'Script')
                this.moveStuff('#script-container');
            else if (this.stepType === 'Manual')
                this.loadManualFiles();

            this.updateJumpable();
            if (report === true) {
                const options = {
                    baseURL: config.backendUrl,
                    timeout: 30000,
                    method: 'PATCH',
                    headers: authHeader(),
                    url: this.stepZero.apiBasePath + '/step?to=' + to 
                        + '&rid=' + this.stepZero.remotingId 
                };
                let proxy = this;
                axios(options).then(
                    data => {
                        //do nothing
                    },
                    error => {
                        //do nothing
                    });
            }
        },
        next() {
            this.currentStep++;
            this.updateJumpable();
            if (this.stepZero.maxAt < this.currentStep) {
                this.stepZero.maxAt = this.currentStep;
            }
            if (this.currentStep === this.steps.length - 1) {
                this.stepManualBase.columns[0].width = '84px';
                this.getSummary(); 
                this.running = false;
                return;
            }
            this.reportStepChange(true);
            // if (this.stepType === 'Console')
            //     this.$refs.console.resetTerminal(false, true);
            if (this.stepType === 'Console' && this.steps[this.currentStep].type === 'Console') {
                this.stepType = 'None';
                let proxy = this;
                setTimeout(() => {
                    proxy.stepType = proxy.steps[proxy.currentStep].type;
                    proxy.moveStuff('#console-container');
                }, 100);
                return;
            }
            this.stepType = this.steps[this.currentStep].type;
            if (this.stepType === 'Console')
                this.moveStuff('#console-container'); 
            else if (this.stepType === 'Script')
                this.moveStuff('#script-container');
            else if (this.stepType === 'Manual') {
                this.loadManualFiles();
            }
        },
        prev() {
            this.currentStep--;
            this.updateJumpable();
            this.reportStepChange(false);
            // if (this.stepType === 'Console')
            //     this.$refs.console.resetTerminal(false, true);
            if (this.stepType === 'Console' && this.steps[this.currentStep].type === 'Console') {
                this.stepType = 'None';
                let proxy = this;
                setTimeout(() => {
                    proxy.stepType = proxy.steps[proxy.currentStep].type;
                    proxy.moveStuff('#console-container');
                }, 100);
                return;
            }
            this.stepType = this.steps[this.currentStep].type;
            if (this.stepType === 'Console')
                this.moveStuff('#console-container');
            else if (this.stepType === 'Script')
                this.moveStuff('#script-container');
            else if (this.stepType === 'Manual') {
                this.loadManualFiles();
            } else if (this.currentStep === 0) {
                let proxy = this;
                let interval = setInterval(function () {
                    if (proxy.stepZero.form && proxy.stepZero.form.domFields 
                        && proxy.stepZero.form.domFields['remoteUser']) {
                        proxy.stepZero.form.setFieldsValue({
                            'currentEnm': proxy.stepZero.lastEnm,
                            'remoteUser': proxy.stepZero.lastUser,
                            //don't restore password for a bit of added security
                            //besides, it's been stored as encrypted anyway
                        });
                        clearInterval(interval);
                    }
                }, 100);
            }
        },
        reportStepChange (up) {
            const options = {
                baseURL: config.backendUrl,
                timeout: 30000,
                method: 'PATCH',
                headers: authHeader(),
                url: this.stepZero.apiBasePath + '/step' + (up ? 'up' : 'dn') 
                    + '?rid=' + this.stepZero.remotingId 
            };
            let proxy = this;
            axios(options).then(
                data => {
                    //do nothing
                },
                error => {
                    //do nothing
                });
        },
        updateJumpable () {
            let proxy = this;
            setTimeout(() => {
                document.querySelectorAll('.ant-steps-item').forEach(function(elem) {
                    if (Number.isInteger(parseInt(elem.getAttribute('data-key')))) {
                        let key = parseInt(elem.getAttribute('data-key'));
                        if (key > 0 && key <= proxy.stepZero.maxAt) {
                            elem.classList.add('jumpable');
                        }
                    }
                });
            }, 200);
        },
        handleScriptMenu ({ item, key, keyPath }) {
            let theScript = null;
            switch (key) {
                case 'script-load':
                    this.handleScriptLoad();
                    break;
                case 'script-save':
                    this.handleScriptSave();
                    break;
                case 'script-cache':
                    theScript = this.stepScripts[this.currentStep].currentScript || '';
                    this.stepScripts[this.currentStep].cachedScript = theScript;
                    this.stepScripts[this.currentStep].tracker++;
                    this.stepScripts[this.currentStep].modified = false;
                    break;
                case 'script-revert':
                    theScript = this.stepScripts[this.currentStep].cachedScript || '';
                    this.stepScripts[this.currentStep].currentScript = theScript;
                    this.stepScripts[this.currentStep].tracker++;
                    break;
                case 'script-reset':
                    theScript = this.stepScripts[this.currentStep].defaultScript || '';
                    this.stepScripts[this.currentStep].currentScript = theScript;
                    this.stepScripts[this.currentStep].tracker++;
                    break;
                case 'script-placeholder-in':
                    this.placeholderInput();
                    break;
                case 'script-placeholder-out-file':
                    this.placeholderOutFile();
                    break;
                case 'script-placeholder-out-dir':
                    this.placeholderOutDir();
                    break;
                case 'script-upload':
                    this.handleScriptUpload();
                    break;
                case 'script-cmd':
                    this.handleScriptCmd();
                    break;
                case 'script-exec':
                    this.handleScriptExec();
                    break;
                case 'script-download':
                    this.downloadScriptResult();
                    break;
                case 'script-fs':
                    this.toggleScriptFullscreen();
                    break;
                case 'script-help':
                    this.stepScripts[this.currentStep].helpModalShown = true;
                    break;
                default:
                    if (key.startsWith('script-lang-')) {
                        this.stepScripts[this.currentStep].lang = key.replace('script-lang-', '');
                    } else if (key.startsWith('script-theme-')) {
                        this.stepScripts[this.currentStep].theme = key.replace('script-theme-', '');
                        //got taught by the homepage of prismjs itself 
                        let css = document.querySelector('link[href*="css/prism"]');
                        if (css && this.stepScripts[this.currentStep].theme) {
                            css.href='/css/prism-' + this.stepScripts[this.currentStep].theme + '.css';
                            //unhandled by css
                            let ln = document.querySelector('#script-container div.prism-editor__line-numbers');
                            let themeBg = document.querySelector('#script-container pre[class*=language]');
                            if (ln && themeBg) {
                                setTimeout(function () {    //gonna let em re-render first
                                    ln.style.backgroundColor = getComputedStyle(themeBg).backgroundColor;                            
                                }, 500);
                            }
                        }
                    }
                    break;
            }
        },
        moveStuff (where) {
            this.$notification.destroy();
            if (where) {
                this.$notification.config({
                    getContainer: function () {
                        return document.querySelector(where) || document.body;
                    }
                });
                if (where === '#script-container') {
                    if (!this.stepScripts[this.currentStep].lang) 
                        this.stepScripts[this.currentStep].lang = 'shell';
                    if (!this.stepScripts[this.currentStep].theme) 
                        this.stepScripts[this.currentStep].theme = 'tomorrow';
                    //reset some script-related inputs
                    /*
                    this.stepScripts[this.currentStep].loadFrom = null;
                    this.stepScripts[this.currentStep].form.setFieldsValue({
                        'script-command': null,
                        'script-filename': null,
                    });
                    */
                }
            } else {
                this.$notification.config({
                    getContainer: function () {
                        return document.body;
                    }
                });
            }
        },
        placeholderInput () {
            let cmd = this.stepScripts[this.currentStep].form.getFieldValue('script-command') || '';
            this.stepScripts[this.currentStep].form.setFieldsValue({'script-command': cmd + '${INFILE}'});
        },
        placeholderOutFile () {
            let cmd = this.stepScripts[this.currentStep].form.getFieldValue('script-command') || '';
            this.stepScripts[this.currentStep].form.setFieldsValue({'script-command': cmd + '${OUTFILE}'});
        },
        placeholderOutDir () {
            let cmd = this.stepScripts[this.currentStep].form.getFieldValue('script-command') || '';
            this.stepScripts[this.currentStep].form.setFieldsValue({'script-command': cmd + '${OUTDIR}'});
        },
        toggleScriptFullscreen () {
            this.$refs.scriptFs.toggle();
            //adjust submenus vs fullscreen
            let container = document.getElementById('script-container');
            let submenus = document.querySelectorAll('div.ant-menu-submenu')
            if (container && submenus && submenus.length) {
                for (var idx = 0; idx < submenus.length; idx++) {
                    let elemToMove = submenus[idx];
                    let elem = submenus[idx];
                    let up = 0;
                    while (elem && elem !== document.body && up <= 3) {
                        elemToMove = elem;
                        elem = elem.parentElement;
                        up++;
                    }
                    if (elemToMove && elemToMove.parentElement === document.body) {
                        document.body.removeChild(elemToMove);
                        container.appendChild(elemToMove);
                    }
                }
            }
        },
        handleScriptChange (code) {
            this.stepScripts[this.currentStep].modified = code !== this.stepScripts[this.currentStep].cachedScript;
        },
        handleScriptLoad () {
            if (this.stepScripts[this.currentStep].modified) {
                this.$confirm({
                    iconType: 'warning',
                    title: 'Warning',
                    content: 'Will overwrite existing contents. Proceed?',
                    onOk: this.beginLoad,
                    destroyOnClose: true
                });
            } else {
                this.beginLoad();
            }
        },
        beginLoad () {
            let loader = document.getElementById('script-loader');
            if (loader)
                loader.click();
        },
        handleScriptLoader (file) {
            if (file) {
                var reader = new FileReader();
                let proxy = this;
                reader.onerror = function (evt) {
                    proxy.$message.error('cannot read file');
                };
                reader.onloadend = function (evt) {
                    proxy.doLoadScript(evt.target.result);
                };
                this.stepScripts[this.currentStep].loadFrom = file.name;
                reader.readAsText(file);
            }
            return false;
        },
        doLoadScript (contents) {
            this.stepScripts[this.currentStep].currentScript = contents;
            this.stepScripts[this.currentStep].tracker++;
            this.stepScripts[this.currentStep].modified = true;
        },
        handleScriptSave () {
            if (this.stepScripts[this.currentStep].currentScript) {
                let blob = new Blob([this.stepScripts[this.currentStep].currentScript], { type: 'text/plain' });
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, "script.txt")
                } else {
                    var elem = window.document.createElement('a')
                    elem.href = window.URL.createObjectURL(blob)
                    elem.download = "script.txt"
                    document.body.appendChild(elem)
                    elem.click()
                    document.body.removeChild(elem)
                }
            }
        },
        validateAction (uploadOnly) {
            let err = [];
            let draw = this.$createElement;
            let toSend = this.stepScripts[this.currentStep].currentScript;
            if (this.stepType === 'Script' && (!toSend || toSend.trim().length === 0)) {
                err.push(draw('div', 'Empty script'));
            }
            let cmd = this.stepScripts[this.currentStep].form.getFieldValue('script-command') || '';
            if (uploadOnly === false && cmd.trim().length === 0) {
                if (this.stepType === 'Script') {
                    err.push(draw('div', 'Command string is unset. Did you mean to do "Upload only"?'));
                } else {
                    err.push(draw('div', 'Command string is empty'));
                }
            }
            if (err.length > 0) {
                this.$error({
                    title: 'Cannot execute',
                    content: this.$createElement('div', {}, err),
                    destroyOnClose: true
                });
                return false;
            }
            return true;
        },
        cancelUlScript () {
            this.stepScripts[this.currentStep].modalShown = false;
        },
        updateScriptFilename () {
            if (this.stepScripts[this.currentStep].form && this.stepScripts[this.currentStep].form.domFields 
                && this.stepScripts[this.currentStep].form.domFields['script-filename'] === true) {
                this.stepScripts[this.currentStep].form.setFieldsValue({
                    'script-filename': this.stepScripts[this.currentStep].loadFrom
                });
            } else {
                let proxy = this;
                let interval = setInterval(function () {
                    if (proxy.stepScripts[proxy.currentStep].form && proxy.stepScripts[proxy.currentStep].form.domFields 
                        && proxy.stepScripts[proxy.currentStep].form.domFields['script-filename'] === true) {
                        proxy.stepScripts[proxy.currentStep].form.setFieldsValue({
                            'script-filename': proxy.stepScripts[proxy.currentStep].loadFrom
                        });
                        clearInterval(interval);
                    }
                }, 100);
            }
        },
        handleScriptUpload () {
            if (!this.validateAction(true))
                return;
            // this.$confirm({
            //     title: 'Confirm Execution',
            //     content: 'Script will be sent to backend. Proceed?',
            //     onOk: this.initUlScript(false)
            // });
            this.stepScripts[this.currentStep].modalTitle = 'Upload';
            this.stepScripts[this.currentStep].uploadOnly = true;
            this.updateScriptFilename();
            this.stepScripts[this.currentStep].modalShown = true;
        },
        handleScriptExec () {
            if (!this.validateAction(false))
                return;
            // this.$confirm({
            //     title: 'Confirm Execution',
            //     content: 'Script will be sent to backend and executed. Proceed?',
            //     onOk: this.initUlScript('true')
            // });
            this.stepScripts[this.currentStep].modalTitle = 'Upload & Execute';
            this.stepScripts[this.currentStep].uploadOnly = false;
            this.updateScriptFilename();
            this.stepScripts[this.currentStep].modalShown = true;
        },
        handleScriptCmd () {
            if (!this.validateAction(false))
                return;
            this.stepScripts[this.currentStep].uploadOnly = false;
            this.initUlScript();
        },
        downloadScriptResult () {
            let initHeaders = authHeader();
            const options = {
                baseURL: config.backendUrl,
                // timeout: 30000,
                method: 'GET',
                headers: initHeaders,
                responseType: 'blob',
                url: this.stepZero.apiBasePath + '/download?rid=' + this.stepZero.remotingId 
                    + '&dlid=' + this.stepScripts[this.currentStep].dlId
            };
            let proxy = this;
            axios(options).then(
                data => {
                    const blob = new Blob([data.data]);
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, proxy.stepScripts[proxy.currentStep].dlResult);
                    } else {
                        var elem = window.document.createElement('a');
                        elem.href = window.URL.createObjectURL(blob);
                        elem.download = proxy.stepScripts[proxy.currentStep].dlResult;
                        document.body.appendChild(elem);
                        elem.click();
                        document.body.removeChild(elem);
                    }
                },
                error => {
                });
        },
        initUlScript () {
            this.stepScripts[this.currentStep].modalShown = false;
            this.stepScripts[this.currentStep].dlId = null;
            this.stepScripts[this.currentStep].dlResult = null;
            let initHeaders = authHeader();
            // initHeaders['Content-Type'] = 'application/x-www-form-urlencoded';
            initHeaders['Content-Type'] = 'multipart/form-data';
            let cmd = this.stepScripts[this.currentStep].form.getFieldValue('script-command') || '';
            const formData = new FormData();
            formData.append('script', this.stepScripts[this.currentStep].currentScript);
            formData.append('cmd', cmd);
            if (this.stepType === 'Script') {
                let filename = this.stepScripts[this.currentStep].form.getFieldValue('script-filename') || '';
                formData.append('filename', filename);
            }
            const options = {
                baseURL: config.backendUrl,
                timeout: 30000,
                method: 'POST',
                headers: initHeaders,
                data: formData,
                url: this.stepZero.apiBasePath + '/uploadscript?rid=' + this.stepZero.remotingId 
                    + '&noexec=' + this.stepScripts[this.currentStep].uploadOnly
                    + '&aid=' + this.stepScripts[this.currentStep].activityId
            };
            if (this.stepType === 'Script') {
                this.updateSuexStatus('Uploading...', 0, null);
            }
            let proxy = this;
            axios(options).then(
                data => {
                    proxy.$notification['info']({
                        duration: config.notifDuration || 3,
                        message: 'Execution in progress',
                        description: 'You will be notified when it is done'
                    });
                },
                error => {
                    proxy.$notification['error']({
                        duration: config.notifDuration || 3,
                        message: 'Execution aborted',
                        description: error
                    });
                }
            );
        },
        handleExecResult () {
            // let title = this.$store.state.pgwbus.notifMessage || 'Script execution finished';
            let result = this.$store.state.pgwbus.notifMessage;
            let payload = null;
            try {
                payload = JSON.parse(this.$store.state.pgwbus.notifDescription);
            } catch (x) {
                console.debug(x);
                this.$notification['error']({
                    duration: config.notifDuration || 3,
                    message: 'Abnormal Execution Result',
                    description: x
                });
                return;
            }
            let draw = this.$createElement,
                items = [],
                success = false,
                showModal = true,
                status = '',
                ulStatus = null,
                exStatus = null,
                title = '',
                msg = '';
            switch (result) {
                case 'UPLOAD_DONE':
                    success = true;
                    title = 'Script uploaded';
                    status = 'Script uploaded';
                    ulStatus = 1;
                    if (payload['path'])
                        status += ' to ' + payload.path;
                    if (payload['exec'] === true) {
                        status += '. Executing...';
                        exStatus = 0;
                    }
                    showModal = payload['exec'] !== true;
                    break;
                case 'UPLOAD_FAIL':
                    title = 'Upload failed';
                    msg = payload['errmsg'] || '';
                    if (msg.length > 0)
                        msg = ': ' + msg;
                    status = 'Upload failed' + msg;
                    ulStatus = -1;
                    break;
                case 'EXEC_DONE':
                    success = true;
                    title = 'Execution succeeded';
                    status = 'Execution succeeded';
                    this.stepScripts[this.currentStep].dlId = payload['dlId'];
                    this.stepScripts[this.currentStep].dlResult = payload['dlResult'];
                    ulStatus = 1;
                    exStatus = 1;
                    break;
                case 'EXEC_FAIL':
                    title = 'Execution failed';
                    msg = payload['errmsg'] || '';
                    if (msg.length > 0)
                        msg = ': ' + msg;
                    status = 'Execution failed' + msg;
                    ulStatus = 1;
                    exStatus = -1;
                    break;
                case 'VERIFY_FAIL':
                    title = 'Verification failed';
                    msg = payload['errmsg'] || '';
                    if (msg.length > 0)
                        msg = ': ' + msg;
                    status = 'Execution failed' + msg;
                    ulStatus = 1;
                    exStatus = -1;
                    break;
                default: 
                    if (!payload['code']) {
                        //some cmd may emit some to stdin but no exit status (except queried via `$?`)
                        //in which case we assumed it's a successful execution
                        success = true;
                        ulStatus = 1;
                        exStatus = 1;
                    }
                    break;
            }

            if (payload['code']) {
                success = payload.code === 0;
                status = success ? 'Execution succeeded' : 'Execution finished'; 
                title = success ? 'Execution succeeded' : 'Execution finished'; 
                ulStatus = 1;
                exStatus = success ? 1 : -1;
                items.push(draw('div', 'Exit status: ' + payload.code));
            }

            let cmd = this.stepScripts[this.currentStep].form.getFieldValue('script-command') || '';
            let modalW = null, modalH = null;
            if (payload['path']) {
                items.push(draw('div', 'Remote file is at ' + payload.path));
            } else {
                modalW = window.innerWidth - 100;
                modalH = window.innerHeight - 100;

                let divData = { style: { 'margin-top': '20px' } };
                let txtData = { class: { 'mono': true }, style: { 'width': '100%' } };
                if (result === 'EXEC_DONE' || result === 'EXEC_FAIL') {
                    let disp = payload['command'] || (cmd.length > 0 && cmd) || '';
                    items.push(draw('div', divData, [
                        draw('h4', 'Command was:'),
                        draw('code', { class: { 'inline-code': true }, style: { 'font-size': '100%' } }, disp)
                    ]));
                }
                if (payload['errmsg'] && payload['errmsg'].length > 0) {
                    items.push(draw('div', divData, [
                        draw('h4', 'Error exit message:'),
                        draw('textarea', txtData, payload.errmsg)
                    ]));
                }
                if (payload['stdin'] && payload['stdin'].length > 0) {
                    items.push(draw('div', divData, [
                        draw('h4', 'stdin:'),
                        draw('textarea', txtData, payload.stdin)
                    ]));
                }
                if (payload['stderr'] && payload['stderr'].length > 0) {
                    items.push(draw('div', divData, [
                        draw('h4', 'stderr:'),
                        draw('textarea', txtData, payload.stderr)
                    ]));
                }
            }

            if (this.stepType === 'Verify') {
                this.stepVerify.disabled = false;
                this.stepVerify.onProgress = false;
                this.stepVerify.label = 'Execute';
                //let abnormal execution result be handled by suex modal,
                //this block only cares for these 2 status
                if (result === 'EXEC_DONE' || result === 'VERIFY_FAIL') {
                    if (success) {
                        //this.next();
                        this.stepVerify.needJustification = false;
                        this.stepScripts[this.currentStep].modalTitle = 'NE ID Execution Matches';
                        this.stepVerify.message = (payload['neid'] || 'The NE ID') + ' found in ' 
                            + (payload['location'] || 'verification script result');
                        this.stepScripts[this.currentStep].modalShown = true;
                    } else {
                        this.stepVerify.needJustification = true;
                        this.stepScripts[this.currentStep].form.clearField('script-ne-reason');
                        this.stepScripts[this.currentStep].modalTitle = 'NE ID Mismatch, Please Justify';
                        this.stepScripts[this.currentStep].modalShown = true;
                    }
                }
                return;
            }
            
            let cfg = {
                getContainer: this.getScriptContainer,
                title: title,
                content: draw('div', {}, items),
                destroyOnClose: true,
            };
            if (modalW)
                cfg.width = modalW;
            if (modalH)
                cfg.height = modalH;
            this.updateSuexStatus(status, ulStatus, exStatus);
            if (showModal) {
                if (success) {
                    let proxy = this;
                    cfg.onOk = function () {
                        proxy.next();
                    };
                    if (this.stepScripts[this.currentStep].dlId) {
                        cfg.onCancel = this.downloadScriptResult;
                        cfg.cancelText = 'Download Result';
                        cfg.iconType = 'check-circle';
                        this.$confirm(cfg);
                    } else {
                        this.$success(cfg);
                    }
                } else {
                    this.$warning(cfg);
                }
            }
        },
        updateSuexStatus (status, ulStatus, exStatus) {
            if (this.stepType === 'Verify') {
                return;
            }
            this.stepScripts[this.currentStep].suexStatus = status;
            this.stepScripts[this.currentStep].currentStatus = status;
            this.stepScripts[this.currentStep].uploadStatus = ulStatus;
            this.stepScripts[this.currentStep].ulTooltip = 'Upload status';
            switch (ulStatus) {
                case null:
                    break;
                case 0:
                    this.stepScripts[this.currentStep].ulTooltip += ': in progress';
                    break;
                case 1:
                    this.stepScripts[this.currentStep].ulTooltip += ': OK';
                    break;
                case -1:
                    this.stepScripts[this.currentStep].ulTooltip += ': failed';
                    break;
            }
            this.stepScripts[this.currentStep].execStatus = exStatus;
            this.stepScripts[this.currentStep].execTooltip = 'Execution status';
            switch (exStatus) {
                case null:
                    break;
                case 0:
                    this.stepScripts[this.currentStep].execTooltip += ': in progress';
                    break;
                case 1:
                    this.stepScripts[this.currentStep].execTooltip += ': OK';
                    break;
                case -1:
                    this.stepScripts[this.currentStep].execTooltip += ': failed';
                    break;
            }
        },
        executeVerify () {
            let proxy = this;
            this.stepScripts[this.currentStep].form.validateFields((err, values) => {
                if (!err || Object.keys(err).indexOf('script-verify') === -1) {
                    proxy.doExecuteVerify();
                }
            });
        },
        doExecuteVerify () {
            this.stepVerify.disabled = true;
            this.stepVerify.onProgress = true;
            this.stepVerify.label = 'Executing...';
            this.stepScripts[this.currentStep].modalShown = false;
            let initHeaders = authHeader();
            initHeaders['Content-Type'] = 'multipart/form-data';
            let verifyParam = this.stepScripts[this.currentStep].form.getFieldValue('script-verify') || '';
            const formData = new FormData();
            formData.append('param', verifyParam);
            const options = {
                baseURL: config.backendUrl,
                timeout: 30000,
                method: 'POST',
                headers: initHeaders,
                data: formData,
                url: this.stepZero.apiBasePath + '/verify?rid=' + this.stepZero.remotingId 
                    + '&aid=' + this.stepScripts[this.currentStep].activityId
            };
            let proxy = this;
            axios(options).then(
                data => {
                    proxy.$notification['info']({
                        duration: config.notifDuration || 3,
                        message: 'Execution in progress',
                        description: 'You will be notified when it is done'
                    });
                },
                error => {
                    proxy.$notification['error']({
                        duration: config.notifDuration || 3,
                        message: 'Execution aborted',
                        description: error
                    });
                }
            );
        },
        handleVerifyFail (e) {
            e.preventDefault();
            let proxy = this;
            this.stepScripts[proxy.currentStep].form.validateFields((err, values) => {
                if (!err) {
                    proxy.stepScripts[proxy.currentStep].modalShown = false;
                    proxy.stepScripts[proxy.currentStep].modalTitle = null;
                    proxy.doJustify();
                }
            });
        },
        handleVerifySuccess (e) {
            e.preventDefault();
            this.stepScripts[this.currentStep].modalShown = false;
            this.stepScripts[this.currentStep].modalTitle = null;
            this.next();
        },
        doJustify () {
            this.stepScripts[this.currentStep].modalShown = false;
            let initHeaders = authHeader();
            initHeaders['Content-Type'] = 'multipart/form-data';
            let actual = this.stepScripts[this.currentStep].form.getFieldValue('script-ne-actual') || '';
            let reason = this.stepScripts[this.currentStep].form.getFieldValue('script-ne-reason') || '';
            const formData = new FormData();
            formData.append('actual', actual);
            formData.append('reason', reason);
            const options = {
                baseURL: config.backendUrl,
                timeout: 30000,
                method: 'POST',
                headers: initHeaders,
                data: formData,
                url: this.stepZero.apiBasePath + '/justify?rid=' + this.stepZero.remotingId 
                    + '&aid=' + this.stepScripts[this.currentStep].activityId
            };
            let proxy = this;
            axios(options).then(
                data => {
                    proxy.next();
                },
                error => {
                    proxy.$notification['error']({
                        duration: config.notifDuration || 3,
                        message: 'Error while submitting justification',
                        description: error
                    });
                }
            );
        },
        loadManualFiles () {
            let initHeaders = authHeader();
            const options = {
                baseURL: config.backendUrl,
                // timeout: 30000,
                method: 'GET',
                headers: initHeaders,
                url: this.stepZero.apiBasePath + '/manual?rid=' + this.stepZero.remotingId 
                    + '&aid=' + this.stepManuals[this.currentStep].activityId
            };
            this.stepManuals[this.currentStep].manualLoading = true;
            let proxy = this;
            axios(options).then(
                data => {
                    proxy.stepManuals[proxy.currentStep].uploads = [];
                    for (let idx = 0; idx < (data.data || []).length; idx++) {
                        proxy.stepManuals[proxy.currentStep].uploads.push({
                            uid: data.data[idx]['id'],
                            name: data.data[idx]['filename'],
                            lastModified: data.data[idx]['created'],
                            percent: 100,
                            status: 'done',
                            // url: config.backendUrl + proxy.stepZero.apiBasePath 
                            //     + '/manual/dn/' + data.data[idx]['id'] + '?rid=' + proxy.stepZero.remotingId 
                            //     + '&aid=' + proxy.stepManuals[proxy.currentStep].activityId
                        });
                    }
                },
                error => {
                    proxy.$notification['error']({
                        duration: config.notifDuration || 3,
                        message: 'Failure',
                        description: error
                    });
                })
                .finally(() => {
                    proxy.stepManuals[proxy.currentStep].columns[0].width = '125px';
                    proxy.stepManuals[proxy.currentStep].manualLoading = false;
                    proxy.stepManuals[proxy.currentStep].listKey++;
                    //console.log(document.getElementById('manual' + proxy.currentStep));
                });
        },
        onManualFileClick (file) {
            let initHeaders = authHeader();
            const options = {
                baseURL: config.backendUrl,
                // timeout: 30000,
                method: 'GET',
                headers: initHeaders,
                responseType: 'arraybuffer',
                url: this.stepZero.apiBasePath + '/manual/dn/' + file['uid']
                    + '?rid=' + this.stepZero.remotingId 
                    + '&aid=' + (file['activity'] || this.stepManuals[this.currentStep].activityId)
            };
            let proxy = this;
            axios(options).then(
                data => {
                    let source = file['activity'] ? proxy.stepManualBase :
                        proxy.stepManuals[proxy.currentStep];
                    source.preview.src = 
                        window.URL.createObjectURL(new Blob([data.data], { type: 'image/*' }));
                    source.preview.alt = file.name;
                    source.preview.name = file.name;
                    source.preview.on = true;
                },
                error => {
                    proxy.$notification['error']({
                        duration: config.notifDuration || 3,
                        message: 'Failure',
                        description: error
                    });
                });
        },
        onManualFileCancel () {
            let source = this.steps[this.currentStep].type === 'Manual' ? 
                this.stepManuals[this.currentStep] : this.stepManualBase;
            source.preview.on = false;
        },
        onManualFileDownload (file) {
            const options = {
                baseURL: config.backendUrl,
                // timeout: 30000,
                method: 'GET',
                headers: authHeader(),
                responseType: 'arraybuffer',
                url: this.stepZero.apiBasePath + '/manual/dn/' + file['uid']
                    + '?rid=' + this.stepZero.remotingId 
                    + '&aid=' + (file['activity'] || this.stepManuals[this.currentStep].activityId)
            };
            let proxy = this;
            axios(options).then(
                data => {
                    let blob = new Blob([data.data], { type: 'image/*' });
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, file.name);
                    } else {
                        var elem = window.document.createElement('a');
                        elem.href = window.URL.createObjectURL(blob);
                        elem.download = file.name;
                        document.body.appendChild(elem);
                        elem.click();
                        document.body.removeChild(elem);
                    }
                },
                error => {
                    proxy.$notification['error']({
                        duration: config.notifDuration || 3,
                        message: 'Download error',
                        description: error
                    });
                }
                );
        },
        onManualFileRemoval (file) {
            let initHeaders = authHeader();
            const formData = new FormData();
            Object.keys(file).forEach(function (k) {
                formData.append(k, file[k]);
            })
            const options = {
                baseURL: config.backendUrl,
                // timeout: 30000,
                method: 'POST',
                headers: initHeaders,
                data: formData,
                url: this.stepZero.apiBasePath + '/manual/rm'
                    + '?rid=' + this.stepZero.remotingId 
                    + '&aid=' + this.stepManuals[this.currentStep].activityId
            };
            this.stepManuals[this.currentStep].manualLoading = true;
            let proxy = this;
            axios(options).then(
                data => {
                    proxy.stepManuals[proxy.currentStep].manualLoading = false;
                    proxy.stepManuals[proxy.currentStep].uploads = 
                        proxy.stepManuals[proxy.currentStep].uploads.filter(x => x.uid !== file.uid);
                    proxy.stepManuals[proxy.currentStep].listKey++;
                    return true;
                },
                error => {
                    proxy.$notification['error']({
                        duration: config.notifDuration || 3,
                        message: 'Failure',
                        description: error
                    });
                    proxy.stepManuals[proxy.currentStep].manualLoading = false;
                    //proxy.stepManuals[proxy.currentStep].listKey++;
                    return false;
                });
        },
        onManualUploadChanged (info) {
            if (info && info['file'] && info['fileList'] && info.file['status'] === 'done' && info.file['response']) {
                var uid = info.file.uid;
                var filename = info.file.name;
                if (typeof info.file['response'] === 'object') {
                    if (info.file.response['uid']) {
                        uid = info.file.response.uid;
                    }
                    if (info.file.response['name']) {
                        filename = info.file.response.name;
                    }
                } else if (typeof info.file['response'] === 'string') {
                    uid = info.file.response;
                }
                this.stepManuals[this.currentStep].manualLoading = true;
                this.stepManuals[this.currentStep].uploads.push({
                    uid: uid,
                    name: filename,
                    lastModified: info.file['lastModified'],
                    percent: 100,
                    status: 'done',
                    // url: config.backendUrl + this.stepZero.apiBasePath 
                    //     + '/manual/dn/' + info.file['response'] + '?rid=' + this.stepZero.remotingId 
                    //     + '&aid=' + this.stepManuals[this.currentStep].activityId
                });
                this.stepManuals[this.currentStep].listKey++;
                this.stepManuals[this.currentStep].manualLoading = false;
            }
        },
        langMark (key) {
            return key === this.stepScripts[this.currentStep].lang ? '' : 'visibility: hidden';
        },
        themeMark (key) {
            return key === this.stepScripts[this.currentStep].theme ? '' : 'visibility: hidden';
        },
        closeScriptHelp () {
            this.stepScripts[this.currentStep].helpModalShown = false;
        },
        consoleOnResize () {
            let theBox = document.querySelector('.term-box');
            let bottom = this.stepConsoles[this.currentStep].fullscreen === true ? 15 : 185;
            if (theBox)
                theBox.style.height = (window.innerHeight - theBox.offsetTop - bottom) + 'px';
            this.stepConsoles[this.currentStep].fullscreen = null;
        },
        consoleOnConnect () {
        },
        consoleFullscreenOn () {
            this.stepConsoles[this.currentStep].fullscreen = true;
        },
        consoleFullscreenOff () {
            this.stepConsoles[this.currentStep].fullscreen = false;
        },
        handleRegionChange(value) {
            let prev = this.stepZero.currentRegion;
            this.stepZero.currentRegion = value;
            if (prev !== value)
                this.stepZero.form.setFieldsValue({ 'currentEnm': null })
            this.stepZero.enmFiltered = this.stepZero.enms.filter(x => x.region_id === value);
        },
        handleEnmSearch(value) {
            // TODO: disable or set limit to large number ?
            const path = "data/enm";
            const params = {};
            const search = value;
            const query = {
                ...params,
                search
            };

            //TODO: rate limiter/debounce?
            this.$store.dispatch("data/index", { path, query });
        },
        getSummary () {
            this.spinTip = 'Gathering summary...';
            this.loading = true;
            const options = {
                baseURL: config.backendUrl,
                timeout: 30000,
                method: 'GET',
                headers: authHeader(),
                url: this.stepZero.apiBasePath + '/summary?rid=' + this.stepZero.remotingId 
            };
            let proxy = this;
            axios(options).then(
                data => {
                    proxy.summaryData = data.data;
                },
                error => {
                    proxy.$notification['error']({
                        duration: config.notifDuration || 3,
                        message: 'Failed to collect summary data',
                        description: error
                    });
                }
            ).finally(() => {
                proxy.loading = false;
                proxy.spinTip = null;
                proxy.summaryKey = [ '0' ];
            });
        }
    },
    computed: {
        dataState() {
            return this.$store.state.data;
        },
        getAuthHeader() {
            return authHeader();
        },
        getScriptContainer() {
            return function () {
                return document.querySelector('#script-container') || document.body;
            };
        },
        dataKey() {
            return this.$store.state.pgwbus.dataKey;
        },
        scriptStatus () {
            return this.stepScripts[this.currentStep].currentStatus;
        },
        passwordToSend () {
            let plain = this.stepZero.form.getFieldValue('remotePass') || '';
            if (!this.stepZero.remotingKey || !this.stepZero.encryptPassword) {
                return plain;
            } else {
                try {
                    let enc = new NodeRSA();
                    enc.importKey(this.stepZero.remotingKey);
                    return enc.encrypt(plain, 'base64');
                } catch (e) {
                    console.debug(e);
                    return '';
                }
            }
        },
        manualUrl() {
            return this.stepZero.enms.find(e => e.id === this.stepZero.lastEnm).manual_url;
        },
        manualContext() {
            return this.stepZero.enms.find(e => e.id === this.stepZero.lastEnm).manual_context;
        },
        manualUploadPath (file) {
            return config.backendUrl + this.stepZero.apiBasePath 
                + '/manual/up?rid=' + this.stepZero.remotingId 
                + '&aid=' + this.stepManuals[this.currentStep].activityId
        },
        summaryContent () {
            var content = [
                {
                    key: 0,
                    name: 'Credentials',
                    description: '',
                    content: [
                        {
                            name: "Area",
                            value: this.stepZero.regions.find(e => e.id === this.stepZero.currentRegion).name,
                        },
                        {
                            name: "ENM",
                            value: this.stepZero.enms.find(e => e.id === this.stepZero.lastEnm).name,
                        },
                        {
                            name: "Username",
                            value: this.stepZero.lastUser,
                        }
                    ]
                }
            ];
            if (this.steps && this.steps.length > 2) {
                for (var idx = 1; idx < this.steps.length - 1; idx++ ) {
                    let json = [];
                    let jsonData = [];
                    json = this.summaryData.filter(x => x.activity_id === this.steps[idx].id) || [];
                    if (this.steps[idx].type === 'Manual') {
                        jsonData = json.map(item => {
                            return item['uploadData'] ? {
                                uid: item.uploadData.id,
                                name: item.uploadData.filename,
                                activity: item.activity_id
                            } : null;
                        }).filter(x => x !== null);
                        json = json.map(x => {
                            if (x['uploadData']) {
                                delete x['uploadData'];
                            }
                            return x;
                        });
                    }
                    content.push({
                        key: idx,
                        type: this.steps[idx].type,
                        name: this.steps[idx].title,
                        description: this.steps[idx].desc,
                        content: json,
                        contentData: jsonData,
                        contentHtml: prettyHtml(json)
                    });
                }
            }
            return content;
        }
    },
    watch: {
        dataState: {
            handler(v) {
                if (v.success && v.data) {
                    this.stepZero.enms = v.data.data;
                    this.preInit(this.$route.params.id);
                } else {
                }
            },
            deep: true
        },
        dataKey(key) {
            if ((this.$store.state.pgwbus.dataModule === 'EXEC' || this.$store.state.pgwbus.dataModule === 'VERIFY') 
                && this.$store.getters['pgwbus/showNotif'] !== true) {

                this.handleExecResult();
            }
        },
    }
};
</script>
<style scoped>
.a-button {
    margin-right: 8px;
}

.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
}

.steps-action {
    margin-top: 24px;
}

div.prism-editor-wrapper {
    max-height: 600px;
    overflow-y: auto;
}

.jumpable {
    cursor: pointer;
}
</style>