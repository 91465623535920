var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading, tip: _vm.spinTip } },
    [
      _c(
        "div",
        { staticClass: "steps-action" },
        [
          _vm.running
            ? _c("h4", [
                _vm._v(
                  'Currently executing NE "' +
                    _vm._s(_vm.currentNe) +
                    '" in ENM "' +
                    _vm._s(_vm.currentEnm) +
                    '" from Nodin# "' +
                    _vm._s(_vm.currentNodin) +
                    '"'
                )
              ])
            : _vm._e(),
          _vm.currentStep > 0
            ? _c(
                "a-button",
                {
                  staticStyle: { margin: "0 8px 8px 0" },
                  attrs: { disabled: _vm.currentStep === 1 },
                  on: { click: _vm.prev }
                },
                [_vm._v("\n            Prev\n        ")]
              )
            : _vm._e(),
          _vm.currentStep > 0 && _vm.currentStep < _vm.steps.length - 1
            ? _c(
                "a-button",
                {
                  staticStyle: { margin: "0 8px 8px 0" },
                  attrs: { type: "primary" },
                  on: { click: _vm.next }
                },
                [_vm._v("\n            Next\n        ")]
              )
            : _vm._e(),
          _vm.currentStep === _vm.steps.length - 1
            ? _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$store.dispatch(
                        "alert/success",
                        "Processing complete!"
                      )
                    }
                  }
                },
                [_vm._v("Done")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "a-steps",
        {
          attrs: {
            current: _vm.currentStep,
            stepType: _vm.stepType,
            size: "small"
          }
        },
        _vm._l(_vm.steps, function(step) {
          return _c(
            "a-step",
            {
              key: step.index,
              attrs: { "data-key": step.index },
              on: {
                click: function($event) {
                  return _vm.jump(step.index, true)
                }
              }
            },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _c(
                    "a-tooltip",
                    { attrs: { placement: "bottom" } },
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(_vm._s(step.desc))
                      ]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(step.title) +
                          "\n                "
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            2
          )
        }),
        1
      ),
      _c("div", { staticClass: "steps-content" }, [
        _vm.currentStep === 0
          ? _c(
              "div",
              [
                _c(
                  "a-form",
                  { attrs: { form: _vm.stepZero.form } },
                  [
                    _c(
                      "a-form-item",
                      _vm._b(
                        { attrs: { label: "Area" } },
                        "a-form-item",
                        _vm.filCommon,
                        false
                      ),
                      [
                        _c(
                          "a-select",
                          {
                            attrs: {
                              disabled: _vm.stepZero.regionDisabled,
                              showSearch: "",
                              optionFilterProp: "area",
                              value: _vm.stepZero.currentRegion,
                              defaultActiveFirstOption: false,
                              filterOption: false,
                              notFoundContent: null
                            },
                            on: { change: _vm.handleRegionChange }
                          },
                          _vm._l(_vm.stepZero.regions, function(region) {
                            return _c("a-select-option", { key: region.id }, [
                              _vm._v(_vm._s(region.name))
                            ])
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      _vm._b(
                        { attrs: { label: "ENM" } },
                        "a-form-item",
                        _vm.filCommon,
                        false
                      ),
                      [
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "currentEnm",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "ENM is required"
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                            'currentEnm',\n                            { rules: [{ required: true, message: 'ENM is required' }] }\n                        ]"
                              }
                            ],
                            attrs: {
                              disabled: _vm.stepZero.enmDisabled,
                              showSearch: "",
                              defaultActiveFirstOption: false,
                              filterOption: false,
                              notFoundContent: null
                            },
                            on: { search: _vm.handleEnmSearch }
                          },
                          _vm._l(_vm.stepZero.enmFiltered, function(ea) {
                            return _c("a-select-option", { key: ea.id }, [
                              _vm._v(_vm._s(ea.name))
                            ])
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      _vm._b(
                        { attrs: { label: "Username" } },
                        "a-form-item",
                        _vm.filCommon,
                        false
                      ),
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "remoteUser",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Username is required"
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                        'remoteUser',\n                        { rules: [{ required: true, message: 'Username is required' }] }\n                        ]"
                            }
                          ],
                          attrs: { disabled: _vm.stepZero.userDisabled }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      _vm._b(
                        { attrs: { label: "Password" } },
                        "a-form-item",
                        _vm.filCommon,
                        false
                      ),
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "remotePass",
                                {
                                  rules: [
                                    {
                                      required: !_vm.stepZero.passDisabled,
                                      message: "Password is required"
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                        'remotePass',\n                        { rules: [{ required: !stepZero.passDisabled, message: 'Password is required' }] }\n                        ]"
                            }
                          ],
                          attrs: {
                            disabled: _vm.stepZero.passDisabled,
                            placeholder: _vm.stepZero.passPlaceholder,
                            type: "password"
                          },
                          on: { pressEnter: _vm.validateRemoting }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      _vm._b(
                        { attrs: { colon: false, label: " " } },
                        "a-form-item",
                        _vm.filCommon,
                        false
                      ),
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.validateRemoting }
                          },
                          [_vm._v(_vm._s(_vm.stepZero.startLabel))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm.currentStep === _vm.steps.length - 1
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticStyle: { margin: "10px" } },
                  [
                    _c(
                      "a-radio-group",
                      {
                        staticStyle: { float: "right" },
                        attrs: { buttonStyle: "solid" },
                        model: {
                          value: _vm.summaryUseViewer,
                          callback: function($$v) {
                            _vm.summaryUseViewer = $$v
                          },
                          expression: "summaryUseViewer"
                        }
                      },
                      [
                        _c("a-radio-button", { attrs: { value: false } }, [
                          _vm._v("Raw")
                        ]),
                        _c("a-radio-button", { attrs: { value: true } }, [
                          _vm._v("Viewer")
                        ])
                      ],
                      1
                    ),
                    _c("h2", [_vm._v("Summary")]),
                    _c(
                      "a-collapse",
                      {
                        model: {
                          value: _vm.summaryKey,
                          callback: function($$v) {
                            _vm.summaryKey = $$v
                          },
                          expression: "summaryKey"
                        }
                      },
                      _vm._l(_vm.summaryContent, function(content) {
                        return _c(
                          "a-collapse-panel",
                          {
                            key: "" + content.key,
                            attrs: {
                              header: 1 + content.key + ". " + content.name
                            }
                          },
                          [
                            content.key === 0
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "a-form",
                                      { attrs: { id: "enm-summary" } },
                                      _vm._l(content.content, function(row) {
                                        return _c(
                                          "a-form-item",
                                          _vm._b(
                                            {
                                              key: row.key,
                                              attrs: { label: row.name }
                                            },
                                            "a-form-item",
                                            _vm.filCommon,
                                            false
                                          ),
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(row.value) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : content.type === "Manual"
                              ? _c(
                                  "span",
                                  [
                                    _c("strong", [_vm._v("Files:")]),
                                    _c("a-table", {
                                      key: _vm.stepManualBase.listKey,
                                      staticClass: "manual-upload-list",
                                      attrs: {
                                        size: "small",
                                        columns: _vm.stepManualBase.columns,
                                        locale: _vm.stepManualBase.locale,
                                        showHeader: false,
                                        rowKey: function(row) {
                                          return row.uid
                                        },
                                        dataSource: content.contentData,
                                        pagination: false,
                                        loading:
                                          _vm.stepManualBase.manualLoading
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "action",
                                            fn: function(record) {
                                              return [
                                                _c(
                                                  "a-row",
                                                  {
                                                    attrs: {
                                                      type: "flex",
                                                      justify: "start",
                                                      gutter: 8
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "a-col",
                                                      [
                                                        _c("a-button", {
                                                          attrs: {
                                                            size: "small",
                                                            type: "default",
                                                            title: "Preview",
                                                            icon: "eye"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.onManualFileClick(
                                                                record
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "a-col",
                                                      [
                                                        _c("a-button", {
                                                          attrs: {
                                                            size: "small",
                                                            type: "default",
                                                            title: "Download",
                                                            icon: "download"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.onManualFileDownload(
                                                                record
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c(
                                      "a-modal",
                                      {
                                        attrs: {
                                          visible:
                                            _vm.stepManualBase.preview.on,
                                          footer: null,
                                          width: "80%"
                                        },
                                        on: { cancel: _vm.onManualFileCancel }
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            alt: _vm.stepManualBase.preview.alt,
                                            download:
                                              _vm.stepManualBase.preview.name,
                                            src: _vm.stepManualBase.preview.src
                                          }
                                        })
                                      ]
                                    ),
                                    _c("br"),
                                    _c("strong", [_vm._v("Logs:")]),
                                    _vm.summaryUseViewer === true
                                      ? _c("json-viewer", {
                                          attrs: {
                                            value: content.content,
                                            "expand-depth": 0
                                          }
                                        })
                                      : _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              content.contentHtml
                                            )
                                          }
                                        })
                                  ],
                                  1
                                )
                              : _vm.summaryUseViewer === true
                              ? _c("json-viewer", {
                                  attrs: {
                                    value: content.content,
                                    "expand-depth": 2
                                  }
                                })
                              : _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(content.contentHtml)
                                  }
                                })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c("a-back-top")
              ],
              1
            )
          : _c("div", [
              _vm.stepType === "Script" ||
              _vm.stepType === "Exec" ||
              _vm.stepType === "Verify"
                ? _c(
                    "div",
                    [
                      _c(
                        "Fullscreen",
                        { ref: "scriptFs", attrs: { id: "script-container" } },
                        [
                          _c(
                            "a-form",
                            {
                              attrs: {
                                form: _vm.stepScripts[_vm.currentStep].form
                              }
                            },
                            [
                              _vm.stepType === "Script" ||
                              _vm.stepType === "Exec"
                                ? _c(
                                    "a-menu",
                                    {
                                      ref: "scriptMenu",
                                      attrs: {
                                        id: "script-menu",
                                        mode: "horizontal",
                                        selectable: false,
                                        forceSubMenuRender: true,
                                        getPopupContainer:
                                          _vm.getScriptContainer
                                      },
                                      on: { click: _vm.handleScriptMenu }
                                    },
                                    [
                                      _vm.stepType === "Script"
                                        ? _c(
                                            "a-sub-menu",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "title" },
                                                  slot: "title"
                                                },
                                                [_vm._v("File")]
                                              ),
                                              _c(
                                                "a-menu-item",
                                                { key: "script-load" },
                                                [
                                                  _vm._v(
                                                    "\n                                    Load from file\n                                "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "a-menu-item",
                                                { key: "script-save" },
                                                [
                                                  _vm._v(
                                                    "\n                                    Save to file\n                                "
                                                  )
                                                ]
                                              ),
                                              _c("a-divider"),
                                              _c(
                                                "a-menu-item",
                                                {
                                                  key: "script-cache",
                                                  attrs: {
                                                    disabled: !_vm.stepScripts[
                                                      _vm.currentStep
                                                    ].modified
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    Save to cache\n                                "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "a-menu-item",
                                                { key: "script-revert" },
                                                [
                                                  _vm._v(
                                                    "\n                                    Revert to cached contents\n                                "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "a-menu-item",
                                                { key: "script-reset" },
                                                [
                                                  _vm._v(
                                                    "\n                                    Reset to default\n                                "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.stepType === "Script"
                                        ? _c(
                                            "a-sub-menu",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "title" },
                                                  slot: "title"
                                                },
                                                [_vm._v("View")]
                                              ),
                                              _c(
                                                "a-sub-menu",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: { slot: "title" },
                                                      slot: "title"
                                                    },
                                                    [_vm._v("Language")]
                                                  ),
                                                  _vm._l(
                                                    _vm.stepScriptCommon
                                                      .availableLangs,
                                                    function(ea) {
                                                      return _c(
                                                        "a-menu-item",
                                                        {
                                                          key:
                                                            "script-lang-" +
                                                            ea.key
                                                        },
                                                        [
                                                          _c("a-icon", {
                                                            staticClass:
                                                              "script-lang-sel",
                                                            style: _vm.langMark(
                                                              ea.key
                                                            ),
                                                            attrs: {
                                                              type: "check",
                                                              id:
                                                                "script-lang-sel-" +
                                                                ea.key
                                                            }
                                                          }),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(ea.text)
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _c(
                                                "a-sub-menu",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: { slot: "title" },
                                                      slot: "title"
                                                    },
                                                    [_vm._v("Theme")]
                                                  ),
                                                  _vm._l(
                                                    _vm.stepScriptCommon
                                                      .availableThemes,
                                                    function(ea) {
                                                      return _c(
                                                        "a-menu-item",
                                                        {
                                                          key:
                                                            "script-theme-" +
                                                            ea.key
                                                        },
                                                        [
                                                          _c("a-icon", {
                                                            staticClass:
                                                              "script-theme-sel",
                                                            style: _vm.themeMark(
                                                              ea.key
                                                            ),
                                                            attrs: {
                                                              type: "check",
                                                              id:
                                                                "script-theme-sel-" +
                                                                ea.key
                                                            }
                                                          }),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(ea.text)
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "a-sub-menu",
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [_vm._v("Command")]
                                          ),
                                          _vm.stepType === "Script"
                                            ? _c(
                                                "a-menu-item",
                                                {
                                                  key: "script-placeholder-in"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    Add input placeholder\n                                "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "a-menu-item",
                                            {
                                              key: "script-placeholder-out-file"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Add output placeholder (single file)\n                                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "a-menu-item",
                                            {
                                              key: "script-placeholder-out-dir"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Add output placeholder (folder)\n                                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-sub-menu",
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [_vm._v("Action")]
                                          ),
                                          _vm.stepType === "Script"
                                            ? _c(
                                                "a-menu-item",
                                                { key: "script-upload" },
                                                [
                                                  _vm._v(
                                                    "\n                                    Upload only\n                                "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.stepType === "Script"
                                            ? _c(
                                                "a-menu-item",
                                                { key: "script-exec" },
                                                [
                                                  _vm._v(
                                                    "\n                                    Upload & Execute\n                                "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.stepType === "Exec"
                                            ? _c(
                                                "a-menu-item",
                                                { key: "script-cmd" },
                                                [
                                                  _vm._v(
                                                    "\n                                    Execute Command\n                                "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "a-menu-item",
                                            { key: "script-download" },
                                            [
                                              _vm._v(
                                                "\n                                    Download output file\n                                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.stepType === "Script"
                                        ? _c(
                                            "a-menu-item",
                                            { key: "script-fs" },
                                            [_vm._v("Toggle Fullscreen")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "a-menu-item",
                                        { key: "script-help" },
                                        [_vm._v("Help")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.stepType === "Script" ||
                              _vm.stepType === "Exec"
                                ? _c(
                                    "a-form-item",
                                    _vm._b(
                                      {
                                        staticStyle: { margin: "6px" },
                                        attrs: { label: "" }
                                      },
                                      "a-form-item",
                                      _vm.filNoLabelInModal,
                                      false
                                    ),
                                    [
                                      _vm.stepType === "Script"
                                        ? _c(
                                            "a-tooltip",
                                            {
                                              attrs: {
                                                title:
                                                  _vm.stepScripts[
                                                    _vm.currentStep
                                                  ].ulTooltip
                                              }
                                            },
                                            [
                                              _vm.stepScripts[_vm.currentStep]
                                                .uploadStatus === null
                                                ? _c("a-icon", {
                                                    staticClass:
                                                      "script-status-icon",
                                                    staticStyle: {
                                                      color: "transparent"
                                                    },
                                                    attrs: {
                                                      type: "minus-square"
                                                    }
                                                  })
                                                : _vm.stepScripts[
                                                    _vm.currentStep
                                                  ].uploadStatus === 0
                                                ? _c("a-icon", {
                                                    staticClass:
                                                      "script-status-icon",
                                                    attrs: {
                                                      type: "sync",
                                                      spin: ""
                                                    }
                                                  })
                                                : _vm.stepScripts[
                                                    _vm.currentStep
                                                  ].uploadStatus === 1
                                                ? _c("a-icon", {
                                                    staticClass:
                                                      "script-status-icon",
                                                    attrs: {
                                                      type: "file-add",
                                                      theme: "twoTone",
                                                      twoToneColor: "#52c41a"
                                                    }
                                                  })
                                                : _vm.stepScripts[
                                                    _vm.currentStep
                                                  ].uploadStatus === -1
                                                ? _c("a-icon", {
                                                    staticClass:
                                                      "script-status-icon",
                                                    attrs: {
                                                      type: "close-circle",
                                                      theme: "twoTone",
                                                      twoToneColor: "#eb2f96"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "a-tooltip",
                                        {
                                          attrs: {
                                            title:
                                              _vm.stepScripts[_vm.currentStep]
                                                .execTooltip
                                          }
                                        },
                                        [
                                          _vm.stepScripts[_vm.currentStep]
                                            .execStatus === null
                                            ? _c("a-icon", {
                                                staticClass:
                                                  "script-status-icon",
                                                staticStyle: {
                                                  color: "transparent"
                                                },
                                                attrs: { type: "minus-square" }
                                              })
                                            : _vm.stepScripts[_vm.currentStep]
                                                .execStatus === 0
                                            ? _c("a-icon", {
                                                staticClass:
                                                  "script-status-icon",
                                                attrs: {
                                                  type: "sync",
                                                  spin: ""
                                                }
                                              })
                                            : _vm.stepScripts[_vm.currentStep]
                                                .execStatus === 1
                                            ? _c("a-icon", {
                                                staticClass:
                                                  "script-status-icon",
                                                attrs: {
                                                  type: "check-circle",
                                                  theme: "twoTone",
                                                  twoToneColor: "#52c41a"
                                                }
                                              })
                                            : _vm.stepScripts[_vm.currentStep]
                                                .execStatus === -1
                                            ? _c("a-icon", {
                                                staticClass:
                                                  "script-status-icon",
                                                attrs: {
                                                  type: "close-circle",
                                                  theme: "twoTone",
                                                  twoToneColor: "#eb2f96"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "label",
                                        {
                                          staticStyle: { "margin-left": "2px" }
                                        },
                                        [_vm._v(_vm._s(_vm.scriptStatus))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.stepType === "Script" ||
                              _vm.stepType === "Exec"
                                ? _c(
                                    "a-form-item",
                                    _vm._b(
                                      {
                                        staticStyle: { margin: "6px" },
                                        attrs: { label: "" }
                                      },
                                      "a-form-item",
                                      _vm.filNoLabelInModal,
                                      false
                                    ),
                                    [
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: ["script-command"],
                                            expression: "[ 'script-command' ]"
                                          }
                                        ],
                                        staticClass: "mono",
                                        attrs: {
                                          size: "large",
                                          placeholder:
                                            "Enter command string to execute"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.stepType === "Verify"
                                ? _c(
                                    "a-form-item",
                                    _vm._b(
                                      {
                                        staticStyle: { margin: "6px" },
                                        attrs: { label: "" }
                                      },
                                      "a-form-item",
                                      _vm.filNoLabelInModal,
                                      false
                                    ),
                                    [
                                      _c("div", [
                                        _vm._v("Will execute command: ")
                                      ]),
                                      _c("span", { staticClass: "mono" }, [
                                        _vm._v("mobatch ")
                                      ]),
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "script-verify",
                                              {
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: "Cannot be blank"
                                                  }
                                                ]
                                              }
                                            ],
                                            expression:
                                              "[ \n                                    'script-verify',\n                                    { rules: [{ required: true, message: 'Cannot be blank' }] }\n                                ]"
                                          }
                                        ],
                                        staticClass: "mono",
                                        staticStyle: { width: "50%" },
                                        attrs: { size: "large" }
                                      }),
                                      _c("span", { staticClass: "mono" }, [
                                        _vm._v(" <moscript> ${OUTDIR}")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.stepType === "Verify"
                                ? _c(
                                    "a-form-item",
                                    _vm._b(
                                      {
                                        staticStyle: { margin: "6px" },
                                        attrs: { label: "" }
                                      },
                                      "a-form-item",
                                      _vm.filNoLabelInModal,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            disabled: _vm.stepVerify.disabled,
                                            loading: _vm.stepVerify.onProgress
                                          },
                                          on: { click: _vm.executeVerify }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(_vm.stepVerify.label) +
                                              "\n                            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.stepType === "Script"
                                ? _c("prism-editor", {
                                    key:
                                      _vm.stepScripts[_vm.currentStep].tracker,
                                    attrs: {
                                      language:
                                        _vm.stepScripts[_vm.currentStep].lang,
                                      lineNumbers: ""
                                    },
                                    on: { change: _vm.handleScriptChange },
                                    model: {
                                      value:
                                        _vm.stepScripts[_vm.currentStep]
                                          .currentScript,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.stepScripts[_vm.currentStep],
                                          "currentScript",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "stepScripts[currentStep].currentScript"
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "a-upload",
                                {
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    multiple: false,
                                    beforeUpload: _vm.handleScriptLoader
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    { attrs: { id: "script-loader" } },
                                    [_vm._v(".")]
                                  )
                                ],
                                1
                              ),
                              _vm.stepType !== "Verify"
                                ? _c(
                                    "a-modal",
                                    {
                                      attrs: {
                                        getContainer: _vm.getScriptContainer,
                                        title:
                                          _vm.stepScripts[_vm.currentStep]
                                            .modalTitle
                                      },
                                      on: {
                                        ok: _vm.initUlScript,
                                        cancel: _vm.cancelUlScript
                                      },
                                      model: {
                                        value:
                                          _vm.stepScripts[_vm.currentStep]
                                            .modalShown,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.stepScripts[_vm.currentStep],
                                            "modalShown",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "stepScripts[currentStep].modalShown"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { attrs: { id: "ul-script-form" } },
                                        [
                                          _c(
                                            "a-form-item",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Remote filename"
                                                }
                                              },
                                              "a-form-item",
                                              _vm.filInModal,
                                              false
                                            ),
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value: ["script-filename"],
                                                    expression:
                                                      "[ 'script-filename' ]"
                                                  }
                                                ],
                                                attrs: {
                                                  placeholder:
                                                    "Leave blank to let system auto-generate the filename on remote host"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _c(
                                    "a-modal",
                                    {
                                      attrs: {
                                        getContainer: _vm.getScriptContainer,
                                        title:
                                          _vm.stepScripts[_vm.currentStep]
                                            .modalTitle
                                      },
                                      model: {
                                        value:
                                          _vm.stepScripts[_vm.currentStep]
                                            .modalShown,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.stepScripts[_vm.currentStep],
                                            "modalShown",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "stepScripts[currentStep].modalShown"
                                      }
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "footer" },
                                        [
                                          _vm.stepVerify.needJustification ===
                                          true
                                            ? _c(
                                                "a-button",
                                                {
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: _vm.handleVerifyFail
                                                  }
                                                },
                                                [_vm._v("Submit")]
                                              )
                                            : _c(
                                                "a-button",
                                                {
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click:
                                                      _vm.handleVerifySuccess
                                                  }
                                                },
                                                [_vm._v("OK")]
                                              )
                                        ],
                                        1
                                      ),
                                      _vm.stepVerify.needJustification === true
                                        ? _c(
                                            "div",
                                            {
                                              attrs: {
                                                id: "verify-script-form"
                                              }
                                            },
                                            [
                                              _c(
                                                "a-form-item",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      label: "NE ID Actual"
                                                    }
                                                  },
                                                  "a-form-item",
                                                  _vm.filInModal,
                                                  false
                                                ),
                                                [
                                                  _c("a-input", {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value: [
                                                          "script-ne-actual"
                                                        ],
                                                        expression:
                                                          "[ 'script-ne-actual' ]"
                                                      }
                                                    ],
                                                    attrs: {
                                                      placeholder:
                                                        "NE ID being executed"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-form-item",
                                                _vm._b(
                                                  {
                                                    attrs: { label: "Reason" }
                                                  },
                                                  "a-form-item",
                                                  _vm.filInModal,
                                                  false
                                                ),
                                                [
                                                  _c("a-textarea", {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value: [
                                                          "script-ne-reason",
                                                          {
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "Reason must be stated"
                                                              }
                                                            ]
                                                          }
                                                        ],
                                                        expression:
                                                          "[ \n                                            'script-ne-reason',\n                                            { rules: [{ required: true, message: 'Reason must be stated' }] }\n                                        ]"
                                                      }
                                                    ],
                                                    staticStyle: {
                                                      "margin-top": "5px"
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        "Explanation for NE ID discrepancy between CRQ/Nodin and execution"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              attrs: {
                                                id: "verify-script-result"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.stepVerify.message
                                                  ) +
                                                  "\n                            "
                                              )
                                            ]
                                          )
                                    ],
                                    2
                                  ),
                              _c(
                                "a-modal",
                                {
                                  attrs: {
                                    getContainer: _vm.getScriptContainer,
                                    title: "Help"
                                  },
                                  on: { ok: _vm.closeScriptHelp },
                                  model: {
                                    value:
                                      _vm.stepScripts[_vm.currentStep]
                                        .helpModalShown,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.stepScripts[_vm.currentStep],
                                        "helpModalShown",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "stepScripts[currentStep].helpModalShown"
                                  }
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "footer" },
                                    [
                                      _c(
                                        "a-button",
                                        { on: { click: _vm.closeScriptHelp } },
                                        [_vm._v("Got it")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _c("h3", [_vm._v("How to SUEx")]),
                                    _c("ol", [
                                      _c("li", [
                                        _vm._v(
                                          "Ensure an ENM has been selected in previous step"
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Type (or copy-paste, sure) the script in the editor. You may also load it from your local filesystem via menu File → Load from File"
                                        )
                                      ])
                                    ])
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.stepType == "Console"
                ? _c(
                    "div",
                    [
                      _c("Console", {
                        ref: "console",
                        attrs: {
                          onResize: _vm.consoleOnResize,
                          onConnectSuccess: _vm.consoleOnConnect,
                          onFullscreenOn: _vm.consoleFullscreenOn,
                          onFullscreenOff: _vm.consoleFullscreenOff,
                          apiBasePath: _vm.stepZero.apiBasePath,
                          currentRemotingId: _vm.stepZero.remotingId,
                          activityId:
                            _vm.stepConsoles[_vm.currentStep]["activityId"]
                        }
                      })
                    ],
                    1
                  )
                : _vm.stepType == "Manual"
                ? _c(
                    "div",
                    {
                      staticStyle: { margin: "6px" },
                      attrs: { id: "manual" + _vm.currentStep }
                    },
                    [
                      _c("h3", [_vm._v("Manual Execution")]),
                      _c("ol", [
                        _c("li", [
                          _vm._v("Create "),
                          _c("b", [_vm._v(_vm._s(_vm.manualContext))]),
                          _vm._v(" in ENM, please go to "),
                          _c(
                            "a",
                            { attrs: { target: "_new", href: _vm.manualUrl } },
                            [_vm._v(_vm._s(_vm.manualUrl))]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Upload capture file "),
                          _c("b", [_vm._v(_vm._s(_vm.manualContext))])
                        ])
                      ]),
                      _c(
                        "a-spin",
                        {
                          attrs: {
                            spinning:
                              _vm.stepManuals[_vm.currentStep].manualLoading
                          }
                        },
                        [
                          _c(
                            "a-form",
                            {
                              attrs: {
                                form: _vm.stepManuals[_vm.currentStep].form
                              }
                            },
                            [
                              _c(
                                "a-form-item",
                                _vm._b(
                                  {},
                                  "a-form-item",
                                  _vm.filNoLabelInModal,
                                  false
                                ),
                                [
                                  _c(
                                    "a-upload",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: ["manual-upload"],
                                          expression: "[ 'manual-upload' ]"
                                        }
                                      ],
                                      attrs: {
                                        showUploadList: false,
                                        headers: _vm.getAuthHeader,
                                        action: _vm.manualUploadPath,
                                        accept:
                                          _vm.stepManuals[_vm.currentStep]
                                            .accept,
                                        defaultFileList:
                                          _vm.stepManuals[_vm.currentStep]
                                            .uploads
                                      },
                                      on: { change: _vm.onManualUploadChanged }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "upload" }
                                          }),
                                          _vm._v(
                                            "\n                                    Select Files...\n                                "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("a-table", {
                        key: _vm.stepManuals[_vm.currentStep].listKey,
                        staticClass: "manual-upload-list",
                        attrs: {
                          size: "small",
                          columns: _vm.stepManuals[_vm.currentStep].columns,
                          locale: _vm.stepManualBase.locale,
                          showHeader: false,
                          rowKey: function(row) {
                            return row.uid
                          },
                          dataSource: _vm.stepManuals[_vm.currentStep].uploads,
                          pagination: false,
                          loading:
                            _vm.stepManuals[_vm.currentStep].manualLoading
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "action",
                            fn: function(record) {
                              return [
                                _c(
                                  "a-row",
                                  {
                                    attrs: {
                                      type: "flex",
                                      justify: "start",
                                      gutter: 8
                                    }
                                  },
                                  [
                                    _c(
                                      "a-col",
                                      [
                                        _c("a-button", {
                                          attrs: {
                                            size: "small",
                                            type: "default",
                                            title: "Preview",
                                            icon: "eye"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onManualFileClick(
                                                record
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      [
                                        _c("a-button", {
                                          attrs: {
                                            size: "small",
                                            type: "default",
                                            title: "Download",
                                            icon: "download"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onManualFileDownload(
                                                record
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      [
                                        _c("a-button", {
                                          attrs: {
                                            size: "small",
                                            type: "danger",
                                            title: "Remove",
                                            icon: "delete"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onManualFileRemoval(
                                                record
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c(
                        "a-modal",
                        {
                          attrs: {
                            visible:
                              _vm.stepManuals[_vm.currentStep].preview.on,
                            footer: null,
                            width: "80%"
                          },
                          on: { cancel: _vm.onManualFileCancel }
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              alt: _vm.stepManuals[_vm.currentStep].preview.alt,
                              download:
                                _vm.stepManuals[_vm.currentStep].preview.name,
                              src: _vm.stepManuals[_vm.currentStep].preview.src
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _c("div")
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }