<template>
    <a-table
        :columns="columns"
        :rowKey="record => record.event_id"
        :dataSource="data"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :expandRowByClick="true"
        bordered>
        <template slot="title" v-if="title&&title.length">
            {{title}}
            <a-input-search
                placeholder="input search text"
                id="log-search"
                v-if="searchable"
                v-model="search"
                @pressEnter="onSearchEnter"
            />
        </template>
        <div slot="expandedRowRender" slot-scope="detail">
            <a-table
                :columns="detailColumns"
                :rowKey="detail => detail.key"
                :dataSource="getDetailData(detail.event_id)"
                :pagination="false"
                bordered>
            </a-table>
        </div>
        <span slot="timeRender" slot-scope="detail">{{ detail.action_tstamp_tx | moment('YYYY-MM-DD HH:mm:ss.SSS') }}</span>
    </a-table>
</template>
<style scoped src="@/assets/css/pgw.css"></style>
<style>
#log-search {
    float: right;
    width: 200px;
}
</style>

<script>
import { antdDefault } from "@/config";
import JsonViewer from 'vue-json-viewer'
import Index from "./Index";
//import moment from "vue-moment";

export default {
    components: {
        JsonViewer
    },
    props: {
        title: {
            default: "Audit Log"
        },
        table: {
            default: ""
        },
        entityId: {
            default: null
        },
        nameColumn: {
            default: "name"
        },
        searchable: {
            default: true
        },
        columns: {
            type: Array,
            default: () => [
                {
                    title: "Id",
                    dataIndex: "id",
                    sorter: true,
                    width: "30%"
                },
                {
                    title: "Time",
                    //dataIndex: "action_tstamp_tx",
                    scopedSlots: { customRender: "timeRender" },
                    sorter: true,
                    width: "30%",
                },
                {
                    title: "Action",
                    dataIndex: "action",
                    sorter: true,
                    width: "30%"
                }
            ]
        }
    },
    computed: {
        dataState() {
            return this.$store.state.data;
        },
    },
    mounted() {
        this.$eventbus.$on("index-filter", filters => {
            this.filters = filters;
        });
        this.$eventbus.$on("index-logged", cfg => {
            this.apiPath = cfg.apiPath;
            this.filters = cfg.filters;
            this.table = cfg.table;
            this.id = cfg.id;
            this.fetch(this.apiPath, {
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                sort: this.sorter.field,
                order: this.sorter.order,
                table: this.table,
                id: this.id,
                nameColumn: this.nameColumn,
                ...this.filters
            });
        });
    },
    beforeDestroy() {
        this.$eventbus.$off("index-logged");
    },
    data() {
        return {
            data: [],
            pagination: antdDefault.table.pagination,
            filters: null,
            sorter: { field: null, order: null },
            loading: false,
            search: "",
            apiPath: "",
            id: "",
            detailColumns: [
                {
                    title: "Field",
                    dataIndex: "key",
                    sorter: false,
                    width: "20%"
                },
                {
                    title: "Value",
                    dataIndex: "val",
                    sorter: false,
                    width: "40%"
                },
                {
                    title: "New Value",
                    dataIndex: "newval",
                    sorter: false,
                    width: "40%"
                }
            ],
            detailKey: [],
            detailData: []
        };
    },
    watch: {
        dataState: {
            handler(v) {
                if (v.success && v.data && v.data.success) {
                    this.initTable(v.data);
                } else {
                    this.loading = false;
                }
            },
            deep: true
        }
    },
    methods: {
        initTable(data) {
            const pagination = { ...this.pagination };
            pagination.total = data.total;
            pagination.perPage = data.perPage;
            this.data = data.data;
            this.pagination = pagination;
            if (this.data && Array.isArray(this.data)) {
                for (var idx = 0; idx < this.data.length; idx++) {
                    try {
                        this.detailData.push(JSON.parse(this.data[idx].data));
                        this.detailKey.push(this.data[idx].event_id);
                    } catch (e) {
                        console.debug(e);
                    }
                }
            }
            this.loading = false;
        },
        handleTableChange(pagination, filters, sorter) {
            this.filters = filters;
            this.sorter = sorter;
            const pager = { ...this.pagination };
            pager.current = pagination.current;
            this.pagination = pager;
            this.fetch(this.apiPath, {
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                sort: this.sorter.field,
                order: this.sorter.order,
                table: this.table,
                id: this.id,
                nameColumn: this.nameColumn,
                ...this.filters
            });
        },
        onSearchEnter( ) {
            this.pagination.current = 1;
            if (
                this.pagination === {} ||
                this.filters === null ||
                this.sorter === null
            )
                this.fetch(this.apiPath, {});
            else
                this.handleTableChange(
                    this.pagination,
                    this.filters,
                    this.sorter
                );
        },
        fetch(path, params = {}) {
            const search = this.search;
            const table = this.table;
            const id = this.id;
            const nameColumn = this.nameColumn;
            this.loading = true;
            const query = {
                ...params,
                search,
                table,
                id,
                nameColumn
            };

            this.$store.dispatch("data/index", { path, query });
        },
        getDetailData (which) {
            if (!this.detailKey || !this.detailData) {
                return [];
            }
            let idx = this.detailKey.indexOf(which);
            if (idx !== -1 && idx < this.detailData.length) {
                return this.detailData[idx];
            }
            return [];
        }
    }
};
</script>
