var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Fullscreen",
        { ref: "consoleContainer", attrs: { id: "console-container" } },
        [
          _c(
            "a-menu",
            {
              attrs: {
                id: "console-menu",
                mode: "horizontal",
                selectable: false,
                getPopupContainer: _vm.getConsoleContainer
              },
              on: { click: _vm.handleConsoleMenu }
            },
            [
              _c(
                "a-sub-menu",
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("Connection")
                  ]),
                  _c(
                    "a-menu-item",
                    {
                      key: "console-connect",
                      attrs: { disabled: _vm.isConnected }
                    },
                    [_vm._v("Open")]
                  ),
                  _c(
                    "a-menu-item",
                    {
                      key: "console-disconnect",
                      attrs: { disabled: !_vm.isConnected }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                {
                  key: "console-upload",
                  attrs: { disabled: !_vm.isConnected }
                },
                [_vm._v("Upload")]
              ),
              _c(
                "a-sub-menu",
                { attrs: { disabled: !_vm.isConnected } },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("Download")
                  ]),
                  _c("a-menu-item", { key: "console-dl-init" }, [
                    _vm._v("Initialize")
                  ]),
                  _c(
                    "a-menu-item",
                    {
                      key: "console-dl-get",
                      attrs: { disabled: !_vm.canDownload }
                    },
                    [_vm._v("Get the File")]
                  )
                ],
                1
              ),
              _c("a-menu-item", { key: "console-fs" }, [
                _vm._v("Toggle Fullscreen")
              ]),
              _c("a-menu-item", { key: "console-help" }, [_vm._v("Help")])
            ],
            1
          ),
          _c("div", { staticClass: "term-box" }, [
            _c("div", { attrs: { id: "term-header" } }),
            _c("div", {
              staticClass: "terminal",
              attrs: { id: "term-container" }
            }),
            _c("div", { attrs: { id: "term-bottomdiv" } }, [
              _c("div", { attrs: { id: "term-footer" } }),
              _c("div", { attrs: { id: "term-status" } })
            ])
          ]),
          _c(
            "a-modal",
            {
              attrs: { getContainer: _vm.getConsoleContainer, title: "Upload" },
              on: { ok: _vm.initUpload, cancel: _vm.cancelUpload },
              model: {
                value: _vm.ulModalShown,
                callback: function($$v) {
                  _vm.ulModalShown = $$v
                },
                expression: "ulModalShown"
              }
            },
            [
              _c(
                "div",
                { attrs: { id: "ul-form" } },
                [
                  _c(
                    "a-form",
                    { attrs: { form: _vm.ulForm } },
                    [
                      _c(
                        "a-form-item",
                        _vm._b({}, "a-form-item", _vm.filNoLabelInModal, false),
                        [
                          _c(
                            "a-upload",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["upload"],
                                  expression: "[ 'upload' ]"
                                }
                              ],
                              attrs: {
                                fileList: _vm.uploads,
                                beforeUpload: _vm.handleConsoleUpload
                              },
                              on: { change: _vm.consoleUploadChanged }
                            },
                            [
                              _c(
                                "a-button",
                                [
                                  _c("a-icon", { attrs: { type: "upload" } }),
                                  _vm._v("Select Files...\n              ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "a-modal",
            {
              attrs: {
                getContainer: _vm.getConsoleContainer,
                title: "Download"
              },
              on: { ok: _vm.initDownload, cancel: _vm.cancelDownload },
              model: {
                value: _vm.dlModalShown,
                callback: function($$v) {
                  _vm.dlModalShown = $$v
                },
                expression: "dlModalShown"
              }
            },
            [
              _c(
                "div",
                { attrs: { id: "dl-form" } },
                [
                  _c(
                    "a-form",
                    { attrs: { form: _vm.dlForm } },
                    [
                      _c(
                        "a-form-item",
                        _vm._b(
                          { attrs: { label: "Remote Path" } },
                          "a-form-item",
                          _vm.filNoLabelInModal,
                          false
                        ),
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "path",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "Remote path is required"
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                'path',\n                { rules: [{ required: true, message: 'Remote path is required' }] }\n              ]"
                              }
                            ]
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "a-modal",
            {
              attrs: { getContainer: _vm.getConsoleContainer, title: "Help" },
              on: { ok: _vm.closeHelp },
              model: {
                value: _vm.helpModalShown,
                callback: function($$v) {
                  _vm.helpModalShown = $$v
                },
                expression: "helpModalShown"
              }
            },
            [
              _c(
                "template",
                { slot: "footer" },
                [
                  _c("a-button", { on: { click: _vm.closeHelp } }, [
                    _vm._v("Thanks!")
                  ])
                ],
                1
              ),
              _c("div", [
                _c("h3", [_vm._v("How to Download")]),
                _c("ol", [
                  _c("li", [_vm._v("Ensure terminal session is connected")]),
                  _c("li", [
                    _vm._v("Open the dialog via menu Download → Initialize")
                  ]),
                  _c("li", [
                    _vm._v(
                      'Put the full/absolute path of the file to download, into the "Remote Path" below. Be advised, the downloader '
                    ),
                    _c("em", [_vm._v("does not")]),
                    _vm._v(" support recursive (dir/folder) download")
                  ]),
                  _c("li", [
                    _vm._v(
                      "If the full path is not yet known, determine it by locating the file in the terminal session, \n            then call"
                    ),
                    _c("br"),
                    _c("code", [
                      _vm._v("readlink -f _filename_or_its_relative_path_")
                    ]),
                    _c("br"),
                    _vm._v("and copy its output")
                  ]),
                  _c("li", [
                    _vm._v(
                      'Click "OK", wait until the file is ready (there will be a notification)'
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      "File can be downloaded via menu Download → Get the File"
                    )
                  ])
                ])
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }