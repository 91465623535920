<template>
    <a-spin :spinning="loading">
        <a-form :form="form">
            <Form :isCreateForm="createForm" @handle-submit="handleSubmit" :theForm="form" ref="userForm"/>
        </a-form>
    </a-spin>
</template>

<script>
import Form from "./UserForm";

export default {
    components: {
        Form
    },
    data() {
        return {
            loading: false,
            createForm: true
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    methods: {
        handleSubmit(e) {
            this.loading = true;
            e.preventDefault();
            this.form.validateFields(err => {
                if (!err) {
                    const data = new FormData();
                    data.append('username', this.form.getFieldValue('username'));
                    data.append('password', this.form.getFieldValue('password'));
                    data.append('fullname', this.form.getFieldValue('fullname'));
                    data.append('email', this.form.getFieldValue('email'));
                    data.append('role', this.form.getFieldValue('role'));
                    this.$store.dispatch("auth/register", data);
                } else {
                    this.loading = false;
                }
            });
        }
    },
    computed: {
        registerState() {
            return this.$store.state.auth.registerDone;
        },
    },
    watch: {
        registerState(key) {
            if (key === true) {
                this.loading = false;
                if (this.$store.state.auth.registerSuccess === true) {
                    this.$router.push(this.$refs.userForm.indexPath);
                }
            }
        },
    }
};
</script>