import { dataService } from '@/services/data';
import { store } from '@/store';

export const data = {
  namespaced: true,
  state: { success: false, data: null },
  getters: {
    success: state => {
      return state.success;
    },
    result: state => {
      return state.data;
    }
  },
  mutations: {
    success(state, data) {
      state.success = true;
      state.data = data;
    },
    failure(state) {
      state.success = false;
      state.data = null;
    },
    reset(state) {
      state.success = null;
      state.data = null;
    }
  },
  actions: {
    index({ commit }, { path, query, alertOnError }) {
      return dataService.dataIndex(path, query)
        .then(
          data => commit('success', data),
          error => {
            commit('failure');
            if (alertOnError !== false)
              store.dispatch('alert/error', error, { root: true });
          }
        );
    },
    detail({ commit }, { path, id }) {
      return dataService.dataDetail(path, id)
        .then(
          data => commit('success', data),
          error => {
            commit('failure');
            store.dispatch('alert/error', error, { root: true });
          }
        );
    },
    create({ commit }, { path, data }) {
      commit('reset');
      return dataService.dataCreate(path, data)
        .then(
          resp => {
            if (resp && resp['success'] === true) {
              commit('success', true);
              store.dispatch('alert/success', "Data successfully created");
            } else {
              commit('success', false);
              store.dispatch('alert/error', resp.message || "Create failed", { root: true });
            }
          },
          error => {
            commit('failure');
            store.dispatch('alert/error', error, { root: true });
          }
        );
    },
    update({ commit }, { path, id, data }) {
      commit('reset');
      return dataService.dataUpdate(path, id, data)
        .then(
          resp => {
            if (resp && resp['success'] === true) {
              commit('success', true);
              store.dispatch('alert/success', "Data successfully updated");
            } else {
              commit('success', false);
              store.dispatch('alert/error', resp.message || "Update failed", { root: true });
            }
          },
          error => {
            commit('failure');
            store.dispatch('alert/error', error, { root: true });
          }
        );
    },
    delete({ commit }, { path, id }) {
      return dataService.dataDelete(path, id)
        .then(
          () => {
            commit('success', null);
            store.dispatch('alert/success', "Data successfully deleted");
          },
          error => {
            commit('failure');
            store.dispatch('alert/error', error, { root: true });
          }
        );
    }
  }
}
