<template>
    <a-spin :spinning="loading">
        <a-form :form="form">
            <Form 
                :id="id"
                :theForm="form" 
                :isCreateForm="createForm" 
                @handle-submit="handleSubmit" 
                ref="enmForm"/>
        </a-form>
    </a-spin>
</template>

<script>
import Form from "./EnmForm";
import { routerPath } from "@/router";

export default {
    components: {
        Form
    },
    computed: {
        dataState() {
            return this.$store.state.data;
        },
        id() {
            return this.$route.params.id;
        },
        indexPath: () => routerPath.enmIndex,
        updateState() {
            return this.$store.state.data.success;
        },
    },
    mounted() {
        this.fetch(this.apiPath);
    },
    data() {
        return {
            apiPath: "data/enm",
            loading: false,
            createForm: false,
            submitting: false
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    watch: {
        dataState: {
            handler(v) {
                if (v.success && v.data) {
                    this.initDetail(v.data);
                } else {
                    this.loading = false;
                }
            },
            deep: true
        },
        updateState(key) {
            if (key !== null && this.submitting === true) {
                this.loading = false;
                if (this.$store.state.data.success === true) {
                    this.$router.push(this.indexPath);
                }
            }
        }
    },
    methods: {
        initDetail(data) {
            this.form.setFieldsValue({
                name: data.name,
                address: data.address,
                port: data.port,
                base_dir: data.base_dir,
                data_dir: data.data_dir,
                manual_context: data.manual_context,
                manual_url: data.manual_url
            });
            if (this.$refs.enmForm.regionList && Array.isArray(this.$refs.enmForm.regionList)) {
                this.form.setFieldsValue({
                    region: data.region_id,
                });
            }
            this.loading=false;
        },
        handleSubmit(e) {
            this.loading = true;
            e.preventDefault();
            const enmId = this.id;
            const path = this.apiPath
            this.form.validateFields((err, values) => {
                if (!err) {
                    const data = new FormData();
                    data.append("id", enmId);
                    data.append("region_id", values.region);
                    data.append("name", values.name || '');
                    data.append("address", values.address || '');
                    data.append("port", values.port || '22');
                    data.append("base_dir", values.base_dir || '');
                    data.append("data_dir", values.data_dir || '');
                    data.append("manual_url", values.manual_url || '');
                    data.append("manual_context", values.manual_context || '');
                    this.submitting = true;
                    this.$store.dispatch("data/update", { path: path, id: enmId, data: data });
                } else {
                    this.loading = false;
                }
            });
        },
        fetch(path) {
            this.loading = true;
            const id = this.id;
            this.$store.dispatch("data/detail", { path, id });
        }
    }
};

</script>
