import Vue from 'vue'

import Antd from 'ant-design-vue'
// import VueSocketIO from 'vue-socket.io';
import 'ant-design-vue/dist/antd.less'

import App from './App'

import { store } from './store';
import { router } from './router';
// import { config } from '@/config';

Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(require('vue-moment'));

Vue.prototype.$eventbus = new Vue();
const dataLake = {} //lake of data, behave just like global variable. Usage is not recommended for complex scenario such as: Alert, User Management, User Auth, and so on

new Vue({
  el: '#app',
  data: dataLake,
  router,
  store,
  render: h => h(App)
});