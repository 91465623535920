<template>
  <div class="breadcrumb">
    <ul>
      <li
        v-for="(breadcrumb, idx) in breadcrumbList"
        :key="idx"
        @click="routeTo(idx)"
        :class="{'linked': !!breadcrumb.link}">

        {{ breadcrumb.name }}

      </li>
    </ul>
  </div>
</template>

<script>

function findAllPathParams (path) {
  if (!path || !path.length) {
    return [];
  }
  let result = [];
  let start = 0;
  do {
    let found = path.indexOf('/:', start);
    if (found === -1) {
      break;
    }
    start = path.indexOf('/', found + 1);
    if (start === -1) {
      start = path.length;
    }
    result.push(path.substring(found + 1, start));
  } while (start < path.length);
  return result;
}

export default {
  name: 'Breadcrumb',
  data () {
    return {
      breadcrumbList: []
    }
  },
  mounted () {
     this.updateList() 
  },
  watch: {
     '$route' () {
        this.updateList() 
      } 
  },
  methods: {
    routeTo (pRouteTo) { 
      if (!this.breadcrumbList[pRouteTo].link) {
        return;
      }
      let pathParams = findAllPathParams(this.breadcrumbList[pRouteTo].link);
      if (!pathParams.length
        || !this.$root.$data.breadcrumbIds 
        || !Array.isArray(this.$root.$data.breadcrumbIds)
        /*|| pathParams.length !== this.$root.$data.breadcrumbIds.length*/) {
        this.$router.push(this.breadcrumbList[pRouteTo].link);
        return;
      }
      let replaced = this.breadcrumbList[pRouteTo].link;
      for (let idx = 0; idx < this.$root.$data.breadcrumbIds.length; idx ++) {
        replaced = replaced.replace(pathParams[idx], this.$root.$data.breadcrumbIds[idx]);
      }
      this.$router.push(replaced);
    },
    updateList () {
       this.breadcrumbList = this.$route.matched[0].meta.breadcrumb 
    }
  }
}
</script>

<style scoped>
  .breadcrumb {
    margin-top: 15px;
    margin-left: 15px;
    background-color: white;
  }

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul > li {
    display: flex;
    float: left;
    height: 10px;
    width: auto;
    color: default;
    font-weight: bold;
    font-size: .9em;
    cursor: default;
    align-items: center;
  }

  ul > li:not(:last-child)::after {
    content: '/';
    float: right;
    font-size: .9em;
    margin: 0 .5em;
    cursor: default;
  }


  ul>li:not(:first-child):hover{
    font-size: 1em;
  }

  .linked {
    cursor: pointer;
    font-size: .9em;
    font-weight: normal;
  }
</style>
