<template>
    <a-spin :spinning="loading">
        <a-form :form="form">
            <Form :isCreateForm="createForm" @handle-submit="handleSubmit" :theForm="form" ref="userForm"/>
        </a-form>
    </a-spin>
</template>

<script>
import Form from "./UserForm";
import { routerPath } from "@/router"

export default {
    components: {
        Form
    },
    computed: {
        dataState() {
            return this.$store.state.data;
        },
        id() {
            return this.$route.params.id;
        },
        indexPath: () => routerPath.userIndex,
        updateState() {
            return this.$store.state.data.success;
        },
    },
    mounted() {
        this.$root.$data.breadcrumbIds = [ this.id ];
        this.fetch(this.apiPath);
    },
    data() {
        return {
            apiPath: "user",
            loading: false,
            createForm: false,
            submitting: false
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    watch: {
        dataState: {
            handler(v) {
                if (v.success && v.data) {
                    this.initDetail(v.data);
                } else {
                    this.loading = false;
                }
            },
            deep: true
        },
        updateState(key) {
            if (key !== null && this.submitting === true) {
                this.loading = false;
                this.submitting = false;
                if (this.$store.state.data.success === true) {
                    this.$router.push(this.$refs.userForm.indexPath);
                }
            }
        }
    },
    methods: {
        initDetail(data) {
            this.form.setFieldsValue({
                username: data.username,
                fullname: data.fullname,
                email: data.email,
                role: data.role,
                ldap: data.ldap
            });
            this.loading = false;
        },
        handleSubmit(e) {
            this.loading = true;
            e.preventDefault();
            this.form.validateFields(err => {
                if (!err) {
                    const path = this.apiPath;
                    const id = this.id;

                    const data = new FormData();
                    data.append('username', this.form.getFieldValue('username'));
                    data.append('password', this.form.getFieldValue('newPassword'));
                    data.append('fullname', this.form.getFieldValue('fullname'));
                    data.append('email', this.form.getFieldValue('email'));
                    data.append('role', this.form.getFieldValue('role'));
                    this.submitting = true;
                    this.$store.dispatch("data/update", { path, id, data });
                } else {
                    this.loading = false;
                }
            });
        },
        fetch(path) {
            this.loading = true;
            const id = this.id;
            this.$store.dispatch("data/detail", { path, id });
        }
    }
};
</script>