import { authService } from '@/services/auth';
import { router } from '@/router';
import { config } from '@/config';
import { store } from '@/store';


export const auth = {
  namespaced: true,
  state: authService.getAuthInfo()
    ? { done: true, data: authService.getAuthInfo(), registerDone: null, registerSuccess: null }
    : { done: false, data: null, registerDone: null, registerSuccess: null },
  getters: {
    info: state => {
      return state.data;
    },
    sessionId: state => {
      var result = null;
      if (state.data && state.data.token) {
        //decode jwt
        try {
          let split = state.data.token.split('.');
          let payload = split && split.length > 2 ? JSON.parse(atob(split[1])) : null;
          result = payload && payload[config.sessionIdKey] ? payload[config.sessionIdKey] : null;
        } catch (e) {
          //omit
        }
      }
      return result;
    },
    username: state => {
      return state.data && state.data.user ? state.data.user.username : null;
    }
  },
  mutations: {
    init(state) {
      state.done = false;
      state.data = null;
    },
    success(state, data) {
      state.done = true;
      state.data = data;
    },
    error(state) {
      state.done = true;
      state.data = null;
    },
    registerStart(state) {
      state.registerDone = false;
      state.registerSuccess = null;
    },
    registerEnd(state, success) {
      state.registerDone = true;
      state.registerSuccess = success;
    }
  },
  actions: {
    login({ commit }, { username, password, target }) {
      return authService.login(username, password)
        .then(
          data => {
            if (data && data['success'] === true) {
              commit('success', data);
              store.dispatch('pgwbus/connect', store.getters['auth/sessionId']);
              router.push(target);
            } else {
              //no need, already done in authService
              // var err = data && data['message'];
              // commit('error');
              // store.dispatch('alert/error', err, { root: true });
            }
          },
          error => {
            commit('error');
            store.dispatch('alert/error', error, { root: true });
          }
        );
    },
    logout({ commit }, params) {
      let notif = params && params['notification'] ? params['notification'] : {};
      return authService.logout(notif)
        .then(() => {
          commit('success', null);
          store.dispatch('pgwbus/disconnect');
        })
        .finally(() => {
          if (params && params['redirectToLogin'] === true) {
            let p = params['page'] && params['page']['path'] ? 
              ('?p=' + params.page.path + (params.page.query || '')) : '';
            router.push('/login' + p);
          }
        });
    },
    register({ commit }, params) {
      commit('registerStart');
      return authService.register(params)
        .then(
          data => {
            if (data && data['success'] === true) {
              commit('registerEnd', true);
              store.dispatch('alert/success', "Data successfully created");
            } else {
              commit('registerEnd', false);
              store.dispatch('alert/error', data['message'] || 'Could not create user', { root: true });
            }
          },
          error => {
            commit('registerEnd', false);
            store.dispatch('alert/error', error, { root: true });
          }
        );
    }
  }
}