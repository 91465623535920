var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "breadcrumb" }, [
    _c(
      "ul",
      _vm._l(_vm.breadcrumbList, function(breadcrumb, idx) {
        return _c(
          "li",
          {
            key: idx,
            class: { linked: !!breadcrumb.link },
            on: {
              click: function($event) {
                return _vm.routeTo(idx)
              }
            }
          },
          [_vm._v("\n\n      " + _vm._s(breadcrumb.name) + "\n\n    ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }