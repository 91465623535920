/**
 * don't worry about double slashes,
 * e.g. http://localhost:5000//get/someid,
 * when calling axios(config.backendUrl, '/get/something')
 * 
 * as long as request is via axios, it has built-in 
 * url builder+cleaner (combineURL.js)
 * 
 * this is better than stating rootPath as '' (empty string)
 * for when backend is reached directly (not via proxy path)
 * 
 */
export const backend = {
    baseUrl: 'https://api.pgw.sangkuriang.co.id',
    rootPath: '/',
};

export const config = {
    backendUrl: backend.baseUrl + backend.rootPath,
    authInfo: 'authInfo',
    sessionIdKey: 'sessionid',   //'sid' is already used by ssh backend,
    pgwbusPath: 'pgwbus',
    notifDuration: 5,
    dropdown: 30
};

//this object will hold default configuration for antd
export const antdDefault = {
    table: {
        pagination: {
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500']
        }
    },
    
};
