var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.$router.push("/user/create")
            }
          }
        },
        [_vm._v("Create")]
      ),
      _c("div", { staticStyle: { "margin-bottom": "10px" } }),
      _c(
        "Index",
        _vm._b(
          { on: { "handle-delete": _vm.handleDelete } },
          "Index",
          { title: _vm.title, columns: _vm.columns, actions: _vm.actions },
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }