<template>
    <Index :title="title" :columns="columns" :actions="actions"/>
</template>

<script>
import Index from "@/components/Index";

const columns = [
    {
        title: "Name",
        dataIndex: "name",
        sorter: true,
        width: "15%"
    }
];

const actions = [
];

export default {
    components: {
        Index
    },
    data() {
        return {
            apiPath: "data/region",
            title: "Region",
            columns,
            actions
        };
    },
    mounted() {
        this.$eventbus.$emit("index-fetch", this.apiPath);
    }
};
</script>