<template>
    <div>
        <a-button type="primary" @click="$router.push('/user/create')">Create</a-button>
        <div style="margin-bottom: 10px;"></div>
        <Index v-bind="{ title, columns, actions }" @handle-delete="handleDelete"/>
    </div>
</template>

<script>
import Index from "@/components/Index";

const columns = [
    {
        title: "Username",
        dataIndex: "username",
        sorter: true,
        width: "15%"
    },
    {
        title: "Name",
        dataIndex: "fullname",
        sorter: true,
        width: "15%"
    },
    {
        title: "Email",
        dataIndex: "email",
        sorter: true,
        width: "15%"
    },
    {
        title: "Role",
        dataIndex: "role_name",
        sorter: true,
        width: "15%"
    },
    {
        title: "Action",
        key: "action",
        width: "15%",
        scopedSlots: { customRender: "action" }
    }
];

const actions = [
    {
        text: "Edit",
        route: "/user/edit/",
        type: "default",
        size: "small",
        show: () => true
    },
    {
        text: "Delete",
        emit: "handle-delete",
        type: "danger",
        size: "small",
        show: (record) => record.role !== 15
    }
];

export default {
    components: {
        Index
    },
    data() {
        return {
            apiPath: "user",
            title: "User",
            columns,
            actions
        };
    },
    mounted() {
        this.$eventbus.$emit("index-fetch", this.apiPath);
    },
    methods: {
        handleDelete(record) {
            this.$confirm({
                iconType: 'warning',
                title: 'Warning',
                content: 'Are you sure to delete this user?',
                onOk: () => this.doDelete(record.id),
                destroyOnClose: true
            });
        },
        doDelete(which) {
            this.$store.dispatch("data/delete", { path: this.apiPath, id: which })
                .then( () => {
                    this.$eventbus.$emit("index-fetch", this.apiPath);
                });
        }
    }
};
</script>