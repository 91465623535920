<template>
    <div>
        <a-row :gutter="8">
            <a-tabs defaultActiveKey="0" @change="tabCallback">
                <a-tab-pane v-for="enm in enmList" :key="enm.key" :tab="enm.title"></a-tab-pane>
            </a-tabs>
        </a-row>
        <!-- {{this.nodeSource}} -->
        <a-row :gutter="8">
            <a-col :span="12">
                <h4>2G MO + TRX</h4>
                <a-button
                    type="primary"
                    icon="download"
                    @click="downloadData(licenses.lic2gMo)"
                >Download {{currentEnm.title}} Data</a-button>
                <p></p>
                <vue-c3 :handler="handler2gMo" id="chart-container-2g-mo"></vue-c3>
            </a-col>
            <a-col :span="12">
                <h4>2G MPWR + Power</h4>
                <a-button
                    type="primary"
                    icon="download"
                    @click="downloadData(licenses.lic2gMpwr)"
                >Download {{currentEnm.title}} Data</a-button>
                <p></p>
                <vue-c3 :handler="handler2gMpwr" id="chart-container-2g-mpwr"></vue-c3>
            </a-col>
        </a-row>
        <hr />
        <a-row :gutter="8">
            <a-col :span="12">
                <h4>3G Cell Carrier</h4>
                <a-button
                    type="primary"
                    icon="download"
                    @click="downloadData(licenses.lic3gCc)"
                >Download {{currentEnm.title}} Data</a-button>
                <p></p>
                <vue-c3 :handler="handler3gCc" id="chart-container-3g-cc"></vue-c3>
            </a-col>
            <a-col :span="12">
                <h4>3G Power (Watt)</h4>
                <a-button
                    type="primary"
                    icon="download"
                    @click="downloadData(licenses.lic3gPwr)"
                >Download {{currentEnm.title}} Data</a-button>
                <p></p>
                <vue-c3 :handler="handler3gPwr" id="chart-container-3g-pwr"></vue-c3>
            </a-col>
        </a-row>
        <hr />
        <a-row :gutter="8">
            <a-col :span="12">
                <h4>4G FDD MO</h4>
                <a-button
                    type="primary"
                    icon="download"
                    @click="downloadData(licenses.lic4gFddMo)"
                >Download {{currentEnm.title}} Data</a-button>
                <p></p>
                <vue-c3 :handler="handler4gFddMo" id="chart-container-4g-fdd-mo"></vue-c3>
            </a-col>
            <a-col :span="12">
                <h4>4G TDD MO</h4>
                <a-button
                    type="primary"
                    icon="download"
                    @click="downloadData(licenses.lic4gTddMo)"
                >Download {{currentEnm.title}} Data</a-button>
                <p></p>
                <vue-c3 :handler="handler4gTddMo" id="chart-container-4g-tdd-mo"></vue-c3>
            </a-col>
        </a-row>
        <hr />
        <a-row :gutter="8">
            <a-col :span="12">
                <h4>4G FDD BW (per 5Mhz)</h4>
                <a-button
                    type="primary"
                    icon="download"
                    @click="downloadData(licenses.lic4gFddBw)"
                >Download {{currentEnm.title}} Data</a-button>
                <p></p>
                <vue-c3 :handler="handler4gFddBw" id="chart-container-4g-fdd-bw"></vue-c3>
            </a-col>
            <a-col :span="12">
                <h4>4G TDD BW (per 5Mhz)</h4>
                <a-button
                    type="primary"
                    icon="download"
                    @click="downloadData(licenses.lic4gTddBw)"
                >Download {{currentEnm.title}} Data</a-button>
                <p></p>
                <vue-c3 :handler="handler4gTddBw" id="chart-container-4g-tdd-bw"></vue-c3>
            </a-col>
        </a-row>
        <hr />
        <a-row :gutter="8">
            <a-col :span="12">
                <h4>4G FDD TDD Power (Watt)</h4>
                <a-button
                    type="primary"
                    icon="download"
                    @click="downloadData(licenses.lic4gFddPwr)"
                >Download {{currentEnm.title}} Data</a-button>
                <p></p>
                <vue-c3 :handler="handler4gFddPwr" id="chart-container-4g-fdd-pwr"></vue-c3>
            </a-col>
            <!-- a-col :span="12">
                <h4>4G TDD Power (Watt)</h4>
                <vue-c3 :handler="handler4gTddPwr" id="chart-container-4g-tdd-pwr"></vue-c3>
            </a-col-->
        </a-row>
        <!--
        <div id="app" class="container mt-5">
            <h2 class="border-bottom pb-2 mb-4">Pivot <small>(drag & drop UI + PivotTable)</small></h2>

            <div class="mb-5">
                <pivot
                    :data="data"
                    :fields="fields"
                    :row-fields="rowFields"
                    :col-fields="colFields" 
                    :reducer="reducer"
                    :default-show-settings="defaultShowSettings"
                >
                    <template slot="value" slot-scope="{ value }">
                        {{ value.toLocaleString() }}
                    </template>
                </pivot>
            </div>
        </div>
        -->
    </div>
</template>
 
<script>
import Vue from "vue";
import VueC3 from "vue-c3";
// import Pivot from "@marketconnect/vue-pivot-table";
import "../../assets/css/c3.min.css";
import "../../assets/css/bootstrap.min.css";
import axios from "axios";
import { saveAs } from "file-saver";
import { config } from "@/config";
import { authHeader } from "@/services/index";
import contentDisposition from "content-disposition";
// import d3 from 'd3'

const data = [];
export default {
    name: "Dashboard",
    // components: { VueC3, Pivot },
    components: { VueC3 },
    data() {
        return {
            enmList: [],
            currentEnm: {},
            handler2gMo: new Vue(),
            handler2gMpwr: new Vue(),
            handler3gCc: new Vue(),
            handler3gPwr: new Vue(),
            handler4gTddMo: new Vue(),
            handler4gFddMo: new Vue(),
            handler4gTddBw: new Vue(),
            handler4gFddBw: new Vue(),
            handler4gTddPwr: new Vue(),
            handler4gFddPwr: new Vue(),
            licenses: {
                lic2gMo: "2G_Number_Trx",
                lic2gMpwr: "2G_Power_Watt",
                lic3gCc: "3G_Cell_Carrier",
                lic3gPwr: "3G_Power_Watt",
                lic4gFddMo: "4G_FDD",
                lic4gTddMo: "4G_TDD",
                lic4gFddBw: "4G_FDD_Bandwidth_5MHz",
                lic4gTddBw: "4G_TDD_Bandwidth_5MHz",
                lic4gFddPwr: "4G_FDD_Power_Watt",
                lic4gTddPwr: "4G_TDD_Power_Watt"
            },
            width: 100,
            nodeSource: [],
            data: data,
            asyncData: [],
            fields: [],
            rowFields: [
                {
                    getter: item => item.featCtrlCpc,
                    label: "featCtrlCpc"
                },
                {
                    getter: item => item.bsctPnTransStatus,
                    label: "bsctPnTransStatus"
                },
                {
                    getter: item => item.NodeId,
                    label: "NodeId"
                }
            ],
            colFields: [
                {
                    getter: item => item.administrativeState,
                    label: "administrativeState"
                },
                {
                    getter: item => item.airRateTypeSelector,
                    label: "airRateTypeSelector"
                },
                {
                    getter: item => item.cpcCapability,
                    label: "cpcCapability"
                },
                {
                    getter: item => item.hsCoreId,
                    label: "hsCoreId"
                }
            ],
            reducer: (sum, item) => sum + item.id.length - (item.id.length - 1),
            defaultShowSettings: true,
            isDataLoading: false
        };
    },
    created: function() {
        this.isDataLoading = true;
        setTimeout(() => {
            this.asyncData = data;
            this.isDataLoading = false;
        }, 3000);
    },
    methods: {
        initChart(handler, data1, data2) {
            const options = {
                data: {
                    columns: [data1, data2],
                    type: "spline"
                },
                color: {
                    pattern: [
                        "#2f54eb",
                        "#13c2c2",
                        "#722ed1",
                        "#eb2f96",
                        "#fa541c",
                        "#f5222d"
                    ]
                }
            };
            handler.$emit("init", options);
            handler.$emit("dispatch", options =>
                options.resize({ height: 250 })
            );
        },
        initChartSummary(handler, data1, data2) {
            const options = {
                data: {
                    columns: [data1]
                },
                axis: {
                    x: {
                        type: "category",
                        categories: data2
                    }
                },
                color: {
                    pattern: [
                        "#2f54eb",
                        "#13c2c2",
                        "#722ed1",
                        "#eb2f96",
                        "#fa541c",
                        "#f5222d"
                    ]
                }
            };
            handler.$emit("init", options);
            handler.$emit("dispatch", options =>
                options.resize({ height: 250 })
            );
        },
        initChartMultiple(handler, datas) {
            const options = {
                data: {
                    columns: datas,
                    type: "spline"
                },
                color: {
                    pattern: [
                        "#2f54eb",
                        "#13c2c2",
                        "#722ed1",
                        "#eb2f96",
                        "#fa541c",
                        "#f5222d"
                    ]
                }
            };
            handler.$emit("init", options);
            handler.$emit("dispatch", options =>
                options.resize({ height: 250 })
            );
        },
        downloadData(lic) {
            var params = new FormData();
            params.append("enm", this.currentEnm.name);
            params.append("state", lic);
            axios
                .post(
                    `${config.backendUrl}/data/licensesummary/download`,
                    params,
                    {
                        responseType: "arraybuffer",
                        headers: authHeader()
                    }
                )
                .then(response => {
                    var ctype = response.headers["content-type"];
                    if (ctype === "application/octet-stream") {
                        var cdisp = contentDisposition.parse(
                            response.headers["content-disposition"]
                        );
                        saveAs(
                            new Blob([response.data], {
                                type: response.headers["content-type"]
                            }),
                            cdisp.parameters["filename"]
                        );
                    } else {
                        this.$store.dispatch(
                            "alert/error",
                            response.data.message
                        );
                    }
                });
        },
        tabCallback(key) {
            this.fetchEnmData(this.enmList[key]);
        },
        fetchEnmData(enmDetail) {
            this.currentEnm = enmDetail;
            var enmName = enmDetail.name;

            //get 2G MO
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=${this.licenses.lic2gMo}`,
                    {
                        headers: authHeader()
                    }
                )
                .then(cellResponse => {
                    var usage = ["MO + TRX"];
                    var usageRecorded = [];
                    for (var j = 0; j < cellResponse.data.data.length; j++) {
                        usage.push(cellResponse.data.data[j].usage);
                        usageRecorded.push(
                            cellResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(
                        this.handler2gMo,
                        usage,
                        usageRecorded
                    );
                });

            //get 2G MPWR
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=${this.licenses.lic2gMpwr}`,
                    {
                        headers: authHeader()
                    }
                )
                .then(cellResponse => {
                    var usage = ["MPRW + Power"];
                    var usageRecorded = [];
                    for (var j = 0; j < cellResponse.data.data.length; j++) {
                        usage.push(cellResponse.data.data[j].usage);
                        usageRecorded.push(
                            cellResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(
                        this.handler2gMpwr,
                        usage,
                        usageRecorded
                    );
                });

            //get 3G Cell Carrier
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=${this.licenses.lic3gCc}`,
                    {
                        headers: authHeader()
                    }
                )
                .then(cellResponse => {
                    var usage = ["CellCarrier"];
                    var usageRecorded = [];
                    for (var j = 0; j < cellResponse.data.data.length; j++) {
                        usage.push(cellResponse.data.data[j].usage);
                        usageRecorded.push(
                            cellResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(
                        this.handler3gCc,
                        usage,
                        usageRecorded
                    );
                });

            //get 3G Power
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=${this.licenses.lic3gPwr}`,
                    {
                        headers: authHeader()
                    }
                )
                .then(cellResponse => {
                    var usage = ["maxDlPowerCapability"];
                    var usageRecorded = [];
                    for (var j = 0; j < cellResponse.data.data.length; j++) {
                        usage.push(cellResponse.data.data[j].usage);
                        usageRecorded.push(
                            cellResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(
                        this.handler3gPwr,
                        usage,
                        usageRecorded
                    );
                });

            //get 4G FDD MO
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=${this.licenses.lic4gFddMo}`,
                    {
                        headers: authHeader()
                    }
                )
                .then(cellResponse => {
                    var usage = ["MO"];
                    var usageRecorded = [];
                    for (var j = 0; j < cellResponse.data.data.length; j++) {
                        usage.push(cellResponse.data.data[j].usage);
                        usageRecorded.push(
                            cellResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(
                        this.handler4gFddMo,
                        usage,
                        usageRecorded
                    );
                });

            //get 4G TDD MO
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=${this.licenses.lic4gTddMo}`,
                    {
                        headers: authHeader()
                    }
                )
                .then(cellResponse => {
                    var usage = ["MO"];
                    var usageRecorded = [];
                    for (var j = 0; j < cellResponse.data.data.length; j++) {
                        usage.push(cellResponse.data.data[j].usage);
                        usageRecorded.push(
                            cellResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(
                        this.handler4gTddMo,
                        usage,
                        usageRecorded
                    );
                });

            //get 4G FDD Bandwidth
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=${this.licenses.lic4gFddBw}`,
                    {
                        headers: authHeader()
                    }
                )
                .then(cellResponse => {
                    var usage = ["dlChannelBandwidth"];
                    var usageRecorded = [];
                    for (var j = 0; j < cellResponse.data.data.length; j++) {
                        usage.push(cellResponse.data.data[j].usage);
                        usageRecorded.push(
                            cellResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(
                        this.handler4gFddBw,
                        usage,
                        usageRecorded
                    );
                });

            //get 4G TDD Bandwidth
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=${this.licenses.lic4gTddBw}`,
                    {
                        headers: authHeader()
                    }
                )
                .then(cellResponse => {
                    var usage = ["ChannelBandwidth"];
                    var usageRecorded = [];
                    for (var j = 0; j < cellResponse.data.data.length; j++) {
                        usage.push(cellResponse.data.data[j].usage);
                        usageRecorded.push(
                            cellResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(
                        this.handler4gTddBw,
                        usage,
                        usageRecorded
                    );
                });

            //get 4G FDD TDD Power
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=${this.licenses.lic4gFddPwr}`,
                    {
                        headers: authHeader()
                    }
                )
                .then(cellResponse => {
                    var usage = ["ConfiguredMaxTxPower"];
                    var usageRecorded = [];
                    for (var j = 0; j < cellResponse.data.data.length; j++) {
                        usage.push(cellResponse.data.data[j].usage);
                        usageRecorded.push(
                            cellResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(
                        this.handler4gFddPwr,
                        usage,
                        usageRecorded
                    );
                });

            //get 4G TDD Power
            //deprecated
            /*
            axios
                .get(
                    `${config.backendUrl}/data/licensesummary?enm=${enmName}&state=${this.licenses.lic4gTddPwr}`,
                    {
                        headers: authHeader()
                    }
                )
                .then(cellResponse => {
                    var usage = ["ConfiguredMaxTxPower"];
                    var usageRecorded = [];
                    for (var j = 0; j < cellResponse.data.data.length; j++) {
                        usage.push(cellResponse.data.data[j].usage);
                        usageRecorded.push(
                            cellResponse.data.data[j].usage_recorded
                        );
                    }
                    this.initChartSummary(
                        this.handler4gTddPwr,
                        usage,
                        usageRecorded
                    );
                });
            */

            /*
            axios.get(`${config.backendUrl}/data/inventory`,{
                  headers : authHeader()
              })
              .then(response =>{
                  this.nodeSource = response.data.data
                  this.data = this.nodeSource
              });
            */
        }
    },
    mounted() {
        axios
            .get(`${config.backendUrl}/data/enm/datadirs`, {
                headers: authHeader()
            })
            .then(response => {
                for (var j = 0; j < response.data.data.length; j++) {
                    this.enmList.push({
                        key: `${j}`,
                        name: response.data.data[j].enm,
                        title: response.data.data[j].enm.toUpperCase()
                    });
                }
                if (this.enmList.length > 0) {
                    this.fetchEnmData(this.enmList[0]);
                }
            });
    }
};
</script>
