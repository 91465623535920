<template>
    <a-spin :spinning="loading">
        <a-form :form="form">
            <Form 
                :id="id"
                :theForm="form" 
                :isCreateForm="createForm" 
                @lockSow="lockSow" 
                @handle-submit="handleSubmit" 
                @gotoIndex="gotoIndex" 
                ref="actForm"/>
        </a-form>
    </a-spin>
</template>

<script>
import Form from "./ActivityForm";
import { routerPath } from "@/router";

export default {
    components: {
        Form
    },
    computed: {
        dataState() {
            return this.$store.state.data;
        },
        id() {
            return this.$route.params.id;
        },
        updateState() {
            return this.$store.state.data.success;
        },
    },
    mounted() {
        this.fetch(this.apiPath);
    },
    data() {
        return {
            apiPath: "data/activity",
            loading: false,
            createForm: false,
            submitting: false,
            fromSow: null
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    watch: {
        dataState: {
            handler(v) {
                if (v.success && v.data) {
                    this.initDetail(v.data);
                } else {
                    this.loading = false;
                }
            },
            deep: true
        },
        updateState(key) {
            if (key !== null && this.submitting === true) {
                this.loading = false;
                if (this.$store.state.data.success === true) {
                    this.$router.push(this.indexPath());
                }
            }
        }
    },
    methods: {
        initDetail(data) {
            this.form.setFieldsValue({
                number: data.number,
                name: data.name,
                keterangan: data.keterangan,
                jenis: data.jenis,
            });
            this.$refs.actForm.enabled = data.state === 0;
            this.fromSow = this.$route.params.sowid;
            if (this.fromSow) {
                this.$root.$data.breadcrumbIds = [ this.fromSow ];
            } else {
                this.$root.$data.breadcrumbIds = [ this.id ];
            }
            if (this.$refs.actForm.sowList && Array.isArray(this.$refs.actForm.sowList)) {
                this.$refs.actForm.sowId = this.fromSow || data.sow_id || '_all';
                this.form.setFieldsValue({
                    sow: this.fromSow || data.sow_id || '_all',
                });
            }
            this.loading = false;
        },
        handleSubmit(e) {
            this.loading = true;
            e.preventDefault();
            const path = this.apiPath
            this.form.validateFields((err, values) => {
                if (!err) {
                    const data = new FormData();
                    data.append("sow_id", values.sow === '_all' ? null : values.sow);
                    data.append("number", values.number);
                    data.append("name", values.name);
                    data.append("keterangan", values.keterangan);
                    data.append("jenis", values.jenis);
                    data.append("state", this.$refs.actForm.enabled === true ? 0 : 1);
                    this.submitting = true;
                    this.$store.dispatch("data/update", { path: path, id: this.id, data: data });
                } else {
                    this.loading = false;
                }
            });
        },
        fetch(path) {
            this.loading = true;
            const id = this.id;
            this.$store.dispatch("data/detail", { path, id });
        },
        lockSow () {
            // if (this.$route.params.sowid) {
            //     this.$refs.actForm.sowId = this.$route.params.sowid;
            //     this.form.setFieldsValue({
            //         sow: this.$route.params.sowid,
            //     });
            // }
        },
        indexPath () {
            return this.$route.params.sowid ? 
                '/sow/detail/' + this.$route.params.sowid : 
                routerPath.activityIndex;
        },
        gotoIndex () {
            this.$router.push(this.indexPath());
        }
    }
};

</script>
