<template>
    <div>
        <AuditLog 
            ref="log"
            v-bind="{ title, table, columns, nameColumn }"
        />
    </div>
</template>

<script>

import AuditLog from "@/components/AuditLog";
import { authService } from '@/services/auth';

const columns = [
    {
        title: "Name",
        dataIndex: "name",
        sorter: true,
        width: "30%"
    },
    {
        title: "Action",
        dataIndex: "action",
        sorter: true,
        width: "30%"
    },
    {
        title: "Time",
        //dataIndex: "action_tstamp_tx",
        scopedSlots: { customRender: "timeRender" },
        sorter: true,
        width: "30%"
    }
];

export default {
    components: {
        AuditLog
    },
    computed: {
    },
    data() {
        return {
            apiPath: "auditlog/deletions",
            title: "Activity Deletion Log",
            table: "activity",
            nameColumn: "name",
            columns
        };
    },
    mounted() {
        this.$eventbus.$emit("index-logged", { apiPath: this.apiPath, table: this.table });
    },
    methods:{
    }
};
</script>