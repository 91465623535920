<template>
    <router-view></router-view>
</template>

<script>
import { config } from '@/config'
import { store } from '@/store';

export default {
    name: "app",
    data() {
        return {
            collapsed: false
        };
    },
    computed: {
        alertType() {
            return this.$store.state.alert.type;
        },
        alertMessage() {
            return this.$store.state.alert.message;
        },
        alertState() {
            return this.$store.state.alert;
        },
        loggingIn() {
            return this.$store.state.auth.status.loggingIn;
        },
        notifConnected() {
            return this.$store.getters.pgwbus.connected;
        },
        notifKey() {
            return this.$store.state.pgwbus.notifKey;
        },
        notifType() {
            return this.$store.state.pgwbus.notifType;
        },
        notifMessage() {
            return this.$store.state.pgwbus.notifMessage;
        },
        notifDescription() {
            return this.$store.state.pgwbus.notifDescription;
        },
    },
    watch: {
        $route() {
            this.$store.dispatch("alert/clear");
        },
        alertType(type) {
            if (type === "alert-success") {
                this.$message.success(this.alertMessage, 2, function() { store.dispatch("alert/clear"); });
            } else if (type === "alert-error") {
                this.$message.error(this.alertMessage, 2, function() { store.dispatch("alert/clear"); });
            } else if (type === null) {
                this.$store.dispatch("alert/clear");
            }
        },
        notifKey(key) {
            if (this.$store.getters['pgwbus/showNotif']) {
                this.$notification[this.notifType]({
                    duration: config.notifDuration || 3,
                    key: this.notifKey,
                    message: this.notifMessage || ' ',
                    description: this.notifDescription || ' '
                });
            }
        }
    },
    methods: {
        success(message) {
            this.$message.success(message);
        },
        error(message) {
            this.$message.error(message);
        },
        warning(message) {
            this.$message.warning(message);
        }
    },
    mounted () {
        //handle browser refresh/F5, reestablish bus
        let sid = this.$store.getters['auth/sessionId'];
        //let bus = this.$store.getters['pgwbus/connected'];
        let bus = this.$store.state['pgwbus/instance'];
        if (sid && !bus) {
            console.debug('reconnecting bus');
            this.$store.dispatch('pgwbus/connect', sid)
        }
    }
};
</script>
