import Vue from 'vue';
import Router from 'vue-router';

import { authService } from '@/services/auth';

import Home from '@/components/Home';
import Sidebar from '@/components/Sidebar';
import LoginPage from '@/views/LoginPage';
import LogoutPage from '@/views/LogoutPage';

import UserCreate from '@/views/user/UserCreate';
import UserEdit from '@/views/user/UserEdit';
import UserIndex from '@/views/user/UserIndex';

import NodeDetail from '@/views/node/NodeDetail';
import NodeIndex from '@/views/node/NodeIndex';
import NodeDetailLog from '@/views/node/NodeDetailLog';

import RegionIndex from '@/views/region/RegionIndex';

import SiteIndex from '@/views/site/SiteIndex';

import EnmDetail from '@/views/enm/EnmDetail';
import EnmCreate from '@/views/enm/EnmCreate';
import EnmIndex from '@/views/enm/EnmIndex';
import EnmPage from '@/views/enm/EnmPage';
import EnmDeletionLog from '@/views/enm/EnmDeletionLog';
import EnmDetailLog from '@/views/enm/EnmDetailLog';

import SowCreate from '@/views/sow/SowCreate';
import SowDetail from '@/views/sow/SowDetail';
import SowIndex from '@/views/sow/SowIndex';
import SowDeletionLog from '@/views/sow/SowDeletionLog';
import SowDetailLog from '@/views/sow/SowDetailLog';

import ActivityCreate from '@/views/activity/ActivityCreate';
import ActivityDetail from '@/views/activity/ActivityDetail';
import ActivityIndex from '@/views/activity/ActivityIndex';
import ActivityDeletionLog from '@/views/activity/ActivityDeletionLog';
import ActivityDetailLog from '@/views/activity/ActivityDetailLog';

import ProvNodinIndex from '@/views/provisioning/NodinIndex';
import ProvCrqIndex from '@/views/provisioning/CrqIndex';
import ProvEnmIndex from '@/views/provisioning/EnmIndex';

import License from '@/views/dashboard/License';
import Inventory from '@/views/dashboard/Inventory';
import Power from '@/views/dashboard/Power';
import SerialNumber from '@/views/dashboard/SerialNumber';
import SplineChart from '@/views/dashboard/SplineChart';

import ConsolePgw from '@/views/console/Pgw';

Vue.use(Router);

export const routerPath = {
    login: '/login',
    logout: '/logout',
    userCreate: '/user/create',
    userEdit: '/user/edit/:id',
    userIndex: '/user/index',

    nodeDetail: '/node/detail/:id',
    nodeDetailLog: '/node/log/:id',
    nodeIndex: '/node/index',
    regionDetail: '/region/detail/:id',
    regionIndex: '/region/index',
    siteDetail: '/site/detail/:id',
    siteIndex: '/site/index',
    enmCreate: '/enm/create',
    enmDetail: '/enm/detail/:id',
    enmIndex: '/enm/index',
    enmPage: '/enm/page/:id',
    enmDeletion: '/enm/deletion',
    enmDetailLog: '/enm/log/:id',
    sowCreate: '/sow/create',
    sowDetail: '/sow/detail/:id',
    sowDetailLog: '/sow/log/:id',
    sowActivityCreate: '/sow/:sowid/createactivity',
    sowActivityDetail: '/sow/:sowid/activity/:id',
    sowActivityDetailLog: '/sow/:sowid/activity/log/:id',
    sowIndex: '/sow/index',
    sowDeletion: '/sow/deletion',
    activityIndex: '/activity/index',
    activityDeletion: '/activity/deletion',
    activityCreate: '/activity/Create',
    activityDetail: '/activity/detail/:id',
    activityDetailLog: '/activity/log/:id',
    nodinIndex: '/provisioning/nodin/index',
    crqIndex: '/provisioning/crq/index',
    provNodinIndex: '/provisioning/nodin',
    provCrqIndex: '/provisioning/crq',
    provEnmIndex: '/provisioning/enm', 
    consolePgw: '/console/pgw',
    inventory: '/dashboard/inventory',
    license: '/dashboard/license',
    power: '/dashboard/power',
    serialNumber: '/dashboard/serial_number',
    splineChart: '/chart/spline',
}

export const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/', component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Home' }
                ]
            },
            children: [{ path: '', component: Home }]
        },
        // LOGIN
        { path: routerPath.login, component: LoginPage },
        { path: routerPath.logout, component: LogoutPage },
        // USER
        {
            path: routerPath.userCreate, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Administration' },
                    { name: 'Users', link: routerPath.userIndex },
                    { name: 'Users Create' }
                ],
                minRole: 15
            },
            children: [{ path: '', component: UserCreate }]
        },
        {
            path: routerPath.userEdit, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Administration' },
                    { name: 'Users', link: routerPath.userIndex },
                    { name: 'User Edit' }
                ],
                minRole: 15
            },
            children: [{ path: '', component: UserEdit }]
        },
        {
            path: routerPath.userIndex, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Administration' },
                    { name: 'Users' }
                ],
                minRole: 15
            },
            children: [{ path: '', component: UserIndex }]
        },
        // MASTER DATA: NODE
        {
            path: routerPath.nodeDetail, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Node' },
                    { name: 'Provisioning', link: routerPath.provNodinIndex },
                    { name: 'Node Detail' }
                ]
            },
            children: [{ path: '', name: 'nodeDetail', component: NodeDetail }]
        },
        {
            path: routerPath.nodeDetailLog, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Node' },
                    { name: 'Provisioning', link: routerPath.provNodinIndex },
                    { name: 'Node Detail', link: routerPath.nodeDetail },
                    { name: 'Node Detail Log' }
                ]
            },
            children: [{ path: '', name: 'nodeDetail', component: NodeDetailLog }]
        },
        {
            path: routerPath.nodeIndex, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Node' },
                    { name: 'Node Index' }
                ]
            },
            children: [{ path: '', component: NodeIndex }]
        },
        // MASTER DATA: REGION
        {
            path: routerPath.regionDetail, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Region' },
                    { name: 'Region Index', link: routerPath.regionIndex },
                    { name: 'Region Detail' }
                ]
            },
            children: []
        },
        {
            path: routerPath.regionIndex, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Region' },
                    { name: 'Region Index' }
                ]
            },
            children: [{ path: '', component: RegionIndex }]
        },
        // MASTER DATA: SITE
        {
            path: routerPath.siteDetail, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Site' },
                    { name: 'Site Index', link: routerPath.siteIndex },
                    { name: 'Site Detail' }
                ]
            },
            children: []
        },
        {
            path: routerPath.siteIndex, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Site' },
                    { name: 'Site Index' }
                ]
            },
            children: [{ path: '', component: SiteIndex }]
        },
        // MASTER DATA: ENM
        {
            path: routerPath.enmIndex, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'ENM' },
                    { name: 'ENM Index' }
                ]
            },
            children: [{ path: '', component: EnmIndex }]
        },
        {
            path: routerPath.enmDeletion, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'ENM' },
                    { name: 'ENM Index', link: routerPath.enmIndex },
                    { name: 'ENM Deletion Log' }
                ]
            },
            children: [{ path: '', component: EnmDeletionLog }]
        },
        {
            path: routerPath.enmDetail, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'ENM' },
                    { name: 'ENM Index', link: routerPath.enmIndex },
                    { name: 'ENM Detail' }
                ]
            },
            children: [
                {
                    path: '',
                    name: 'enmDetail',
                    component: EnmDetail
                }
            ]
        },
        {
            path: routerPath.enmDetailLog, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'ENM' },
                    { name: 'ENM Index', link: routerPath.enmIndex },
                    { name: 'ENM Detail', link: routerPath.enmDetail },
                    { name: 'ENM Audit Log' }
                ]
            },
            children: [
                {
                    path: '',
                    name: 'enmDetailLog',
                    component: EnmDetailLog
                }
            ]
        },
        {
            path: routerPath.enmCreate, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'ENM' },
                    { name: 'ENM Index', link: routerPath.enmIndex },
                    { name: 'ENM Create' }
                ]
            },
            children: [
                {
                    path: '',
                    name: 'enmCreate',
                    component: EnmCreate
                }
            ]
        },
        {
            path: routerPath.enmPage, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Provisioning' },
                    { name: 'Pending ENM', link: routerPath.provEnmIndex },
                    { name: 'Process ENM' },
                ]
            },
            children: [{ path: '', name: 'enmPage', component: EnmPage }]
        },
        // MASTER DATA: SOW
        {
            path: routerPath.sowCreate, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'SoW' },
                    { name: 'SoW Index', link: routerPath.sowIndex },
                    { name: 'SoW Create' }
                ]
            },
            children: [{ path: '', component: SowCreate }]
        },
        {
            path: routerPath.sowDetail, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'SoW' },
                    { name: 'SoW Index', link: routerPath.sowIndex },
                    { name: 'SoW Detail' }
                ]
            },
            children: [{ path: '',name: 'sowDetail', component: SowDetail }]
        },
        {
            path: routerPath.sowDetailLog, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'SoW' },
                    { name: 'SoW Index', link: routerPath.sowIndex },
                    { name: 'SoW Detail', link: routerPath.sowDetail },
                    { name: 'SoW Audit Log' }
                ]
            },
            children: [
                {
                    path: '',
                    name: 'SowDetailLog',
                    component: SowDetailLog
                }
            ]
        },
        {
            path: routerPath.sowIndex, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'SoW' },
                    { name: 'SoW Index' }
                ]
            },
            children: [{ path: '', component: SowIndex }]
        },
        {
            path: routerPath.sowDeletion, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'SoW' },
                    { name: 'SoW Index', link: routerPath.sowIndex },
                    { name: 'SoW Deletion Log' }
                ]
            },
            children: [{ path: '', component: SowDeletionLog }]
        },        
        // Master data ACTIVITY
        {
            path: routerPath.activityCreate, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Activity' },
                    { name: 'Activity Index', link: routerPath.activityIndex },
                    { name: 'Activity Create' }
                ]
            },
            children: [{
                path: '', 
                name: 'ActivityCreate',
                component: ActivityCreate 
            }]
        },
        {
            path: routerPath.activityDetail, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Activity' },
                    { name: 'Activity Index', link: routerPath.activityIndex },
                    { name: 'Activity Detail' }
                ]
            },
            children: [{ path: '', name: 'ActivityDetail', component: ActivityDetail }]
        },
        {
            path: routerPath.sowActivityCreate, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'SoW' },
                    { name: 'SoW Index', link: routerPath.sowIndex },
                    { name: 'SoW Detail', link: routerPath.sowDetail },
                    { name: 'Create Activity' }
                ]
            },
            children: [{ path: '', name: 'SowActivityCreate', component: ActivityCreate }]
        },
        {
            path: routerPath.activityDetailLog, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Activity' },
                    { name: 'Activity Index', link: routerPath.activityIndex },
                    { name: 'Activity Detail', link: routerPath.activityDetail },
                    { name: 'Activity Audit Log' }
                ]
            },
            children: [
                {
                    path: '',
                    name: 'ActivityDetailLog',
                    component: ActivityDetailLog
                }
            ]
        },
        {
            path: routerPath.sowActivityDetail, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'SoW' },
                    { name: 'SoW Index', link: routerPath.sowIndex },
                    { name: 'SoW Detail', link: routerPath.sowDetail },
                    { name: 'Activity Detail' }
                ]
            },
            children: [{ path: '', name: 'SowActivityDetail', component: ActivityDetail }]
        },
        {
            path: routerPath.sowActivityDetailLog, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'SoW' },
                    { name: 'SoW Index', link: routerPath.sowIndex },
                    { name: 'SoW Detail', link: routerPath.sowDetail },
                    { name: 'Activity Detail', link: routerPath.sowActivityDetail },
                    { name: 'Activity Audit Log' }
                ]
            },
            children: [
                {
                    path: '',
                    name: 'SowActivityDetailLog',
                    component: ActivityDetailLog
                }
            ]
        },
        {
            path: routerPath.activityIndex, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Activity' },
                    { name: 'Activity Index' }
                ]
            },
            children: [{ path: '', component: ActivityIndex }]
        },
        {
            path: routerPath.activityDeletion, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Activity' },
                    { name: 'Activity Index', link: routerPath.activityIndex },
                    { name: 'Activity Deletion Log' }
                ]
            },
            children: [{ path: '', component: ActivityDeletionLog }]
        },
        // PROVISIONING: NODIN
        {
            path: routerPath.provNodinIndex, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Provisioning' },
                    { name: 'Pending Nodin' }
                ]
            },
            children: [{ path: '', component: ProvNodinIndex }]
        },
        // PROVISIONING: CRQ
        {
            path: routerPath.provCrqIndex, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Provisioning' },
                    { name: 'Pending CRQ' }
                ]
            },
            children: [{ path: '', component: ProvCrqIndex }]
        },
        // PROVISIONING: ENM
        {
            path: routerPath.provEnmIndex, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Provisioning' },
                    { name: 'Pending ENM' }
                ]
            },
            children: [{ path: '', component: ProvEnmIndex }]
        },
        // CONSOLES
        {
            path: routerPath.consolePgw, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Console' }
                ],
                minRole: 5
            },
            children: [{ 
                path: '', 
                component: ConsolePgw,
            }]
        },
        // Dashborad
        {
            path: routerPath.inventory, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Dashboard' },
                    { name: 'Inventory' }
                ]
            },
            children: [{ path: '', component: Inventory }]
        },
        {
            path: routerPath.license, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Dashboard' },
                    { name: 'License' }
                ]
            },
            children: [{ path: '', component: License }]
        },
        {
            path: routerPath.power, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Dashboard' },
                    { name: 'Power' }
                ]
            },
            children: [{ path: '', component: Power }]
        },
        {
            path: routerPath.serialNumber, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Dashboard' },
                    { name: 'Serial Number' }
                ]
            },
            children: [{ path: '', component: SerialNumber }]
        },
        {
            path: routerPath.splineChart, component: Sidebar,
            meta: {
                breadcrumb: [
                    { name: 'Dashboard' },
                    { name: 'Inventory' }
                ]
            },
            children: [{ path: '', component: SplineChart }]
        },
        // otherwise redirect to home
        { path: '*', redirect: '/' }
    ]
});

router.beforeEach((to, _from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const toPublic = publicPages.includes(to.path);
    //const authInfo = sessionStorage.getItem(config.authInfo);
    const authInfo = authService.getAuthInfo();
    if (!toPublic && !authInfo) {
        next(routerPath.login + (to.path === routerPath.logout ? '' : '?p=' + to.path));
    } else {
        let minRole = to['matched'] && Array.isArray(to['matched']) ?
            (to.matched.find(match => match && match['meta'] && match.meta['minRole']) || { meta: { minRole: 0 } }).meta.minRole :
            0;
        if (authInfo && authInfo['user'] && authInfo.user['role'] && authInfo.user.role < minRole) {
            alert('Insufficient privilege to view page ' + to.path);
            if (_from && _from['path'] === '/login') {
                next('/');
            } else {
                //next(false);
                //next({ path: _from.path, message: 'Insufficient privilege to view page ' + to.path });
                //next(new Error('Insufficient privilege to view page ' + to.path));
                next({ path: _from.path });
            }
        } else if (authInfo && to.path === routerPath.login && to.query && to.query['p']) {
            next(to.query['p']);
        } else {
            next();
        }
    }
})

// router.onError(e => {
//     store.dispatch('alert/error', e, { root: true });
// });

// router.afterEach((to, _from) => {
//     console.log(to);
//     console.log(_from);
// });
