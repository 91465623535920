var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading } },
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form } },
        [
          _c("Form", {
            ref: "actForm",
            attrs: {
              id: _vm.id,
              theForm: _vm.form,
              isCreateForm: _vm.createForm
            },
            on: {
              lockSow: _vm.lockSow,
              "handle-submit": _vm.handleSubmit,
              gotoIndex: _vm.gotoIndex
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }