var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-table",
    {
      attrs: {
        columns: _vm.columns,
        rowKey: function(record) {
          return record.id
        },
        dataSource: _vm.data,
        pagination: _vm.pagination,
        loading: _vm.loading,
        bordered: ""
      },
      on: { change: _vm.handleTableChange },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function(record) {
            return _c(
              "span",
              {},
              [
                _c(
                  "a-row",
                  { attrs: { type: "flex", justify: "start", gutter: 8 } },
                  _vm._l(_vm.actions, function(a) {
                    return _c("a-col", { key: a.text }, [
                      a.show(record)
                        ? _c(
                            "span",
                            [
                              a.emit
                                ? _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: a.type,
                                        size: a.size,
                                        block: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(a.emit, record)
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v(_vm._s(a.text))])]
                                  )
                                : _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: a.type,
                                        size: a.size,
                                        block: ""
                                      }
                                    },
                                    [
                                      a.route
                                        ? _c(
                                            "router-link",
                                            {
                                              attrs: { to: a.route + record.id }
                                            },
                                            [_vm._v(_vm._s(a.text))]
                                          )
                                        : _c("span", [_vm._v(_vm._s(a.text))])
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  }),
                  1
                )
              ],
              1
            )
          }
        },
        {
          key: "jsonviewer",
          fn: function(record) {
            return _c(
              "span",
              {},
              [
                _c("json-viewer", {
                  attrs: { value: record || [], "expand-depth": 1 }
                })
              ],
              1
            )
          }
        }
      ])
    },
    [
      _vm.title && _vm.title.length
        ? _c(
            "template",
            { slot: "title" },
            [
              _vm._v("\n        " + _vm._s(_vm.title) + "\n        "),
              _vm.searchable
                ? _c("a-input-search", {
                    attrs: {
                      placeholder: "input search text",
                      id: "global-search"
                    },
                    on: { pressEnter: _vm.onSearchEnter },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }