<template>
    <a-spin :spinning="loading">
        <a-form :form="form">
            <Form 
                :theForm="form" 
                :isCreateForm="createForm" 
                @lockSow="lockSow" 
                @handle-submit="handleSubmit" 
                @gotoIndex="gotoIndex" 
                ref="actForm"/>
        </a-form>
    </a-spin>
</template>

<script>
import Form from "./ActivityForm";
import { routerPath } from "@/router";

export default {
    components: {
        Form
    },
    computed: {
        createState() {
            return this.$store.state.data.success;
        },
    },
    watch: {
        createState(key) {
            if (key !== null && this.submitting === true) {
                this.loading = false;
                if (this.$store.state.data.success === true) {
                    this.$router.push(this.indexPath());
                }
            }
        },
    },
    data() {
        return {
            apiPath: "data/activity",
            loading: false,
            createForm: true,
            submitting: false,
            fromSow: null
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    mounted () {
        this.fromSow = this.$route.params.sowid;
        this.$root.$data.breadcrumbIds = [ this.fromSow ];
    },
    methods: {
        lockSow () {
            if (this.$route.params.sowid) {
                this.$refs.actForm.sowId = this.$route.params.sowid;
                this.form.setFieldsValue({
                    sow: this.$route.params.sowid,
                });
            }
        },
        handleSubmit(e) {
            this.loading = true;
            e.preventDefault();
            const path = this.apiPath
            this.form.validateFields((err, values) => {
                if (!err) {
                    const data = new FormData();
                    data.append("sow_id", values.sow === '_all' ? null : values.sow);
                    data.append("number", values.number);
                    data.append("name", values.name);
                    data.append("keterangan", values.keterangan);
                    data.append("jenis", values.jenis);
                    data.append("state", this.$refs.actForm.enabled === true ? 0 : 1);
                    this.submitting = true;
                    this.$store.dispatch("data/create", { path: path, data: data });
                } else {
                    this.loading = false;
                }
            });
        },
        indexPath () {
            return this.$route.params.sowid ? 
                '/sow/detail/' + this.$route.params.sowid : 
                routerPath.activityIndex;
        },
        gotoIndex () {
            this.$router.push(this.indexPath());
        }
    }
};

</script>
