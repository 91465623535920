<template>
    <Index 
        :title="title" 
        :columns="columns" 
        :actions="actions"
        @process-enm="processEnm"
        @delete-record="deleteRecord"
    />
</template>

<script>
import Index from "@/components/Index";

const columns = [
    {
        title: "Number",
        dataIndex: "number",
        sorter: true,
        width: "15%"
    },
    {
        title: "Name",
        dataIndex: "name",
        sorter: true,
        width: "15%"
    },
    {
        title: "Site Name",
        dataIndex: "sitename",
        sorter: true,
        width: "15%"
    },
    {
        title: "SoW Name",
        dataIndex: "sowname",
        sorter: true,
        width: "15%"
    },
    {
        title: "PO Number",
        dataIndex: "ponumber",
        sorter: true,
        width: "15%"
    },
    {
        title: "Action",
        key: "action",
        width: "15%",
        scopedSlots: { customRender: "action" }
    }
];

function canEdit() {
    //return authService.canEdit();
    let info = authService.getAuthInfo();
    return info && info['user'] && info.user['role'] >= 5;
}


const actions = [
    {
        text: "Open",
        route: "/node/detail/",
        type: "default",
        size: "small",
        show: () => canEdit
    },
    {
        text: "Process ENM",
        emit: "process-enm",
        type: "primary",
        size: "small",
        show: () => true
    },
    {
        text: "Delete",
        // route: "/sow/detail/",
        emit: "delete-record",
        type: "default",
        size: "small",
        show: () => authService.canDelete()
    }
];

export default {
    components: {
        Index
    },
    data() {
        return {
            apiPath: "data/node",
            title: "Node",
            columns,
            actions,
            sowList: [],
            sowSource: [],
            sowname: null,
            sow_id: null
        };
    },
    mounted() {
        this.$eventbus.$emit("index-fetch", this.apiPath);
        this.$store.subscribe((mutation) => {
            // listen to delete, refresh table when the deletion is done
            if(mutation != null && mutation.type == "alert/success" && mutation.payload == "Data successfully deleted") {
               this.$eventbus.$emit("index-fetch", this.apiPath);
            }
        });
    },
    methods:{
        deleteRecord(record) {
            var result = confirm(`Are you sure want to delete Node ${record.name}?`); // ini bug undefined            
            if (result) {
                this.$store.dispatch("data/delete", { path: this.apiPath , id: record.id })
                .then( () => {
                this.$eventbus.$emit("index-fetch", this.apiPath);
            });
            } 
        },
        processEnm(record) {
            this.$router.push( { name: 'enmPage', params: { id: record.id }} );
        }
    }
};
</script>