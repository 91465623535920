var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.canCreate
        ? _c(
            "a-button",
            {
              staticClass: "lateral-button",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/sow/Create")
                }
              }
            },
            [_vm._v("Create")]
          )
        : _vm._e(),
      _c(
        "a-button",
        { staticClass: "lateral-button", attrs: { type: "primary" } },
        [
          _c("router-link", { attrs: { to: "/sow/deletion" } }, [
            _vm._v("View Deletion Logs")
          ])
        ],
        1
      ),
      _c("div", { staticStyle: { "margin-bottom": "10px" } }),
      _c("Index", {
        attrs: { title: _vm.title, columns: _vm.columns, actions: _vm.actions },
        on: { "delete-record": _vm.deleteRecord, "edit-record": _vm.edit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }