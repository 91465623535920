<template>
    <div>
        <a-form-item label="Region" v-bind="filCommon">
            <a-select
                :disabled="!canEdit"
                showSearch
                :defaultActiveFirstOption="false"
                :filterOption="false"
                @change="onRegionSelect"
                :notFoundContent="null"
                v-decorator="[
                    'region',
                    {rules: [{ required: true, message: 'ENM must have region' }]}
                ]"
            >
                <a-select-option v-for="region in regionList" :key="region.id">{{region.name}}</a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item
            label="Name"
            v-bind="filCommon"
        >
        <a-input
            :disabled="!canEdit"
            v-decorator="[
                'name',
                { rules: [{ required: true, message: 'ENM must have name' }]}
            ]"
            placeholder="ENM Name">
        </a-input>
    </a-form-item>
        <a-form-item
        label="IP Address"
        v-bind="filCommon">
        <a-input
            :disabled="!canEdit"
            v-decorator="[
                'address',
                {rules: [
                    { required: true, message: 'ENM must have address' },
                    { validator: validateAddress }
                ]}
            ]"
            placeholder="ENM Address"
        ></a-input>
    </a-form-item>
        <a-form-item label="Port" v-bind="filCommon">
            <a-input-number
                :disabled="!canEdit"
                :min="1"
                :max="65535"
                v-decorator="[
                    'port',
                    {
                        rules: [{ required: true, message: 'ENM must have port number' }],
                        initialValue: 22
                    }
                ]"
                placeholder="port"
            ></a-input-number>
        </a-form-item>
        <a-form-item label="Base Directory" v-bind="filCommon">
            <a-input
                :disabled="!canEdit"
                v-decorator="[
                    'base_dir',
                    {
                        rules: [{ required: true, message: 'ENM must have base directory' }],
                        initialValue: '/home/shared/'
                    }
                ]"
                placeholder="base_dir"
            ></a-input>
        </a-form-item>
        <a-form-item label="Data Directory" v-bind="filCommon">
            <a-input
                v-decorator="[
                    'data_dir'
                ]"
                placeholder="data_dir"
            ></a-input>
        </a-form-item>
        <a-form-item label="Manual Url Text" v-bind="filCommon">
            <a-input
                :disabled="!canEdit"
                v-decorator="[
                    'manual_url'
                ]"
                placeholder="manual_url"
            ></a-input>
        </a-form-item>
        <a-form-item label="Manual Context Text" v-bind="filCommon">
            <a-input
                :disabled="!canEdit"
                v-decorator="[
                    'manual_context'
                ]"
                placeholder="manual_context"
            ></a-input>
        </a-form-item>
        <a-form-item class="faux-label" v-bind="filCommon" label=" ">
            <a-button
                v-if="canEdit"
                type="primary"
                html-type="submit"
                v-on:click="$emit(submit, $event)"
                class="lateral-button">Save</a-button>
            <router-link v-bind:to="'/enm/index/'">
                <a-button type="primary" class="lateral-button">{{ canEdit ? 'Cancel' : 'Back to Index' }}</a-button>
            </router-link>
            <router-link v-if="isCreateForm!==true" v-bind:to="'/enm/log/'+id">
                <a-button type="primary" class="lateral-button">Audit Log</a-button>
            </router-link>
        </a-form-item>
    </div>
</template>

<script>
import { routerPath } from "@/router";
import axios from 'axios';
import { config } from '@/config';
import { authHeader } from '../../services/index';
import _extends from 'babel-runtime/helpers/extends';
import { formLayout } from "@/components/common";
import { helpers, ipAddress, url } from 'vuelidate/lib/validators'
import { authService } from '@/services/auth';

export default {
    props: {
        id: {
            default: null
        },
        submit: {
            default: "handle-submit"
        },
        isCreateForm: {
            default: true
        },
        theForm: {
            default: null
        }
    },
    data() {
        return _extends({
            regionList: [],
            region: null
        }, formLayout);
    },
    mounted() {
        axios.get(`${config.backendUrl}/data/region?search=`, {
                headers: authHeader()
            })
            .then(response => {
                this.regionList = response.data.data;
            });
    },
    methods: {
        onRegionSelect(value) {
            this.region = value
        },
        validateAddress (rule, value, callback) {
            if (!value || value.length === 0) {
                callback();
            } else {
                let err = 'ENM must have valid address (IPv4 or name; without protocol, port, path, query or fragment parts)';
                if (ipAddress(value) === true) {
                    callback();
                } else if (url(value) === true) {
                    callback(err);
                } else if (value.search(/[\:\/#\?]/g) > -1) {
                    callback(err);
                } else {
                    let modified = 'http://' + value;
                    if (url(modified) === true) {
                        callback();
                    } else {
                        callback(err);
                    }
                }
            }
        }
    },
    computed: {
        indexPath: () => routerPath.enmIndex,
        canEdit () {
            return authService.canEdit();
        }
    },
    watch: {}
};
</script>