var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading } },
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form }, on: { submit: _vm.handleSubmit } },
        [
          _c(
            "a-form-item",
            _vm._b(
              { attrs: { label: "PO" } },
              "a-form-item",
              _vm.filCommon,
              false
            ),
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["po"],
                    expression: "['po']"
                  }
                ],
                attrs: { disabled: !_vm.canEdit }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            _vm._b(
              { attrs: { label: "NE" } },
              "a-form-item",
              _vm.filCommon,
              false
            ),
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "ne",
                      {
                        rules: [
                          { required: true, message: "node must have ne" }
                        ]
                      }
                    ],
                    expression:
                      "[\n                    'ne',\n                    { rules: [{ required: true, message: 'node must have ne' }]}\n                ]"
                  }
                ],
                attrs: { disabled: !_vm.canEdit }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            _vm._b(
              { attrs: { label: "SoW Name" } },
              "a-form-item",
              _vm.filCommon,
              false
            ),
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: ["sow"],
                      expression:
                        "[\n                    'sow'\n                ]"
                    }
                  ],
                  attrs: {
                    disabled: !_vm.canEdit,
                    placeholder: "SoW Name",
                    showSearch: "",
                    defaultActiveFirstOption: false,
                    optionFilterProp: "title",
                    notFoundContent: null,
                    options: _vm.sowOpts
                  }
                },
                [_vm._v("\n            >\n            ")]
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            _vm._b(
              { staticClass: "faux-label", attrs: { label: " " } },
              "a-form-item",
              _vm.filCommon,
              false
            ),
            [
              _vm.canEdit
                ? _c(
                    "a-button",
                    {
                      staticClass: "lateral-button",
                      attrs: { type: "primary", "html-type": "submit" },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v("Save")]
                  )
                : _vm._e(),
              _c(
                "router-link",
                { attrs: { to: _vm.indexPath } },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "lateral-button",
                      attrs: { type: "primary" }
                    },
                    [_vm._v(_vm._s(_vm.canEdit ? "Cancel" : "Back to Index"))]
                  )
                ],
                1
              ),
              _c(
                "router-link",
                { attrs: { to: "/node/log/" + _vm.id } },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "lateral-button",
                      attrs: { type: "primary" }
                    },
                    [_vm._v("Audit Log")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }