import EventBus from 'vertx3-eventbus-client'
import { backend, config } from '@/config';
import { store } from '@/store';

const onReceive = function (err, msg) {
  if (msg) {
    store.dispatch('pgwbus/receive', msg);
  } else if (err) {
  }
};

export const pgwbus = {
  namespaced: true,
  state: {
    baseAddress: null,
    sid: null,
    instance: null,
    dataKey: 0,
    dataModule: null,
    notifKey: 0,
    notifType: null,
    notifMessage: null,
    notifDescription: null
  },
  getters: {
    connected: state => {
      return state.sid !== null && state.instance !== undefined 
        && state.instance !== null && state.instance.state === EventBus.OPEN;
    },
    currentAddress: state => {
      return [
        state.baseAddress,
        state.baseAddress + '.' + state.sid
      ];
    },
    currentKey: state => {
      return state.notifKey;
    },
    currentDataKey: state => {
      return state.dataKey;
    },
    currentDataModule: state => {
      return state.dataModule;
    },
    showNotif: state => {
      return [ 'success'
        , 'warn'
        , 'warning'
        , 'error'
        , 'info' ].indexOf(state.notifType) !== -1;
    },
  },
  actions: {
    connect({ commit }, sid) {
      if (store.getters['pgwbus/connected'] !== true) {
        commit('connect', sid);
      }
    },
    //this impl. is one-way (receiving) only
    // send({ commit }, { type, message }) {
    //   commit('send', type, message);
    // },
    receive({ commit }, raw) {
      let addr = store.getters['pgwbus/currentAddress'];
      let conn = store.getters['pgwbus/connected'];
      if (conn !== true || addr.indexOf(raw.address) === -1)
        return;
      commit('receive', raw.body);
    },
    disconnect({ commit }) {
      if (store.getters['pgwbus/connected'] === true) {
        commit('disconnect');
      }
    },
  },
  mutations: {
    connect(state, sid) {
      const instance = new EventBus(config.backendUrl + config.pgwbusPath);
      state.baseAddress = 'pgwbus.sub';
      state.sid = sid;
      instance.onopen = function () {
        let addr = store.getters['pgwbus/currentAddress'];
        let auth = { sid: sid };
        for (var idx = 0; idx < addr.length; idx++) {
          try {
            instance.registerHandler(addr[idx], auth, onReceive);
          } catch (omit) {
          }
        }
        instance.enableReconnect(true);
      };
      instance.onerror = function (msg) {
        console.debug(msg ? (msg.body || JSON.stringify(msg)) : 'Socket error');
        if (msg && msg.code === 401) {
          instance.enableReconnect(false);
        }
      };
      instance.onclose = function (e) {
        console.debug(e ? JSON.stringify(e) : 'Socket closed');
      };
      state.instance = instance;
    },
    // send(type, message) {
    // },
    receive(state, msg) {
      let obj = null;
      if (Object.prototype.toString.call(msg) === '[object String]') {
        try {
          obj = JSON.parse(msg);
        } catch (omit) {}
      } else if (Object.prototype.toString.call(msg) === '[object Object]') {
        obj = msg;
      }
      state.notifType = obj.type || 'none';
      state.dataModule = obj.module || null;
      state.notifMessage = obj.message;
      state.notifDescription = obj.description;
      if (store.getters['pgwbus/showNotif']) {
        state.notifKey = 1 + store.getters['pgwbus/currentKey'];
      } else {
        state.dataKey = 1 + store.getters['pgwbus/currentDataKey'];
      }
    },
    disconnect(state) {
      let conn = store.getters['pgwbus/connected'];
      if (conn !== true)
        return;
      let addr = store.getters['pgwbus/currentAddress'];
      for (var idx = 0; idx < addr.length; idx++) {
        try {
          state.instance.unregisterHandler(addr[idx], onReceive);
        } catch (omit) {
        }
      }
      state.instance.enableReconnect(false);
      state.instance.close();
      state.baseAddress = null;
      state.sid = null;
    },
  }
}
