import { render, staticRenderFns } from "./EnmDeletionLog.vue?vue&type=template&id=674c1a52&"
import script from "./EnmDeletionLog.vue?vue&type=script&lang=js&"
export * from "./EnmDeletionLog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/pgw/pgw/ericsson-provisioning/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('674c1a52')) {
      api.createRecord('674c1a52', component.options)
    } else {
      api.reload('674c1a52', component.options)
    }
    module.hot.accept("./EnmDeletionLog.vue?vue&type=template&id=674c1a52&", function () {
      api.rerender('674c1a52', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/enm/EnmDeletionLog.vue"
export default component.exports