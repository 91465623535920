<template>
    <a-table
        :columns="columns"
        :rowKey="record => record.id"
        :dataSource="data"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        bordered
    >
        <template slot="title" v-if="title&&title.length">
            {{title}}
            <a-input-search
                placeholder="input search text"
                id="global-search"
                v-if="searchable"
                v-model="search"
                @pressEnter="onSearchEnter"
            />
        </template>
        <span slot="action" slot-scope="record">
            <a-row type="flex" justify="start" :gutter="8">
                <a-col v-for="a in actions" :key="a.text">
                    <span v-if="a.show(record)">
                        <a-button
                            :type="a.type"
                            :size="a.size"
                            v-if="a.emit"
                            @click="$emit(a.emit, record)"
                            block
                        >
                            <span>{{a.text}}</span>
                        </a-button>
                        <a-button :type="a.type" :size="a.size" v-else block>
                            <router-link v-if="a.route" v-bind:to="a.route + record.id">{{a.text}}</router-link>
                            <span v-else>{{a.text}}</span>
                        </a-button>
                    </span>
                </a-col>
            </a-row>
        </span>
        <span slot="jsonviewer" slot-scope="record">
            <json-viewer
                :value="record || []"
                :expand-depth=1
                >
            </json-viewer>
        </span>
    </a-table>
</template>
<style scoped src="@/assets/css/pgw.css"></style>
<style>
#global-search {
    float: right;
    width: 200px;
}
</style>

<script>
import { antdDefault } from "@/config";
import JsonViewer from 'vue-json-viewer'

export default {
    components: {
        JsonViewer
    },
    props: {
        title: {
            default: "Index"
        },
        searchable: {
            default: true
        },
        queryParams: {
            default: true
        },
        columns: {
            type: Array,
            default: () => [
                {
                    title: "Id",
                    dataIndex: "id",
                    sorter: true,
                    width: "15%"
                },
                {
                    title: "Action",
                    key: "action",
                    width: "15%",
                    scopedSlots: { customRender: "action" }
                }
            ]
        },
        actions: {
            type: Array,
            default: () => [
                {
                    text: "Open",
                    route: "/",
                    type: "default",
                    size: "small",
                    show: () => true
                }
            ]
        }
    },
    computed: {
        dataState() {
            return this.$store.state.data;
        }
    },
    mounted() {
        this.$eventbus.$on("index-filter", filters => {
            this.filters = filters;
        });
        this.$eventbus.$on("index-fetch", (path, filters) => {
            this.apiPath = path;
            this.filters = filters;
            this.fetch(this.apiPath, {
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                sort: this.sorter.field,
                order: this.sorter.order,
                ...this.filters
            });
        });
    },
    beforeDestroy() {
        this.$eventbus.$off("index-fetch");
    },
    data() {
        return {
            data: [],
            pagination: antdDefault.table.pagination,
            filters: null,
            sorter: { field: null, order: null },
            loading: false,
            search: "",
            table:"",
            apiPath: "",
            id: "",
        };
    },
    watch: {
        dataState: {
            handler(v) {
                if (v.success && v.data && v.data.success) {
                    this.initTable(v.data);
                } else {
                    this.loading = false;
                }
            },
            deep: true
        }
    },
    methods: {
        initTable(data) {
            const pagination = { ...this.pagination };
            pagination.total = data.total;
            pagination.perPage = data.perPage;
            this.data = data.data;
            this.pagination = pagination;
            this.loading = false;
        },
        handleTableChange(pagination, filters, sorter) {
            this.filters = filters;
            this.sorter = sorter;
            const pager = { ...this.pagination };
            pager.current = pagination.current;
            this.pagination = pager;
            this.fetch(this.apiPath, {
                limit: pagination.pageSize,
                page: pagination.current,
                sort: sorter.field,
                order: sorter.order,
                ...filters
            });
        },
        onSearchEnter( ) {
            this.pagination.current = 1;
            if (
                this.pagination === {} ||
                this.filters === null ||
                this.sorter === null
            )
                this.fetch(this.apiPath, {});
            else
                this.handleTableChange(
                    this.pagination,
                    this.filters,
                    this.sorter
                );
        },
        fetch(path, params = {}) {
            // this.data = [];
            const search = this.search;
            const table = this.table;
            this.loading = true;
            const query = this.queryParams ? {
                ...params,
                search,
                table
            } : null;

            this.$store.dispatch("data/index", { path, query });
        }
    }
};
</script>
